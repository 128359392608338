export default {
    'demographic_info': 'ข้อมูลส่วนตัว',
    'provide_demographic': 'กรุณาบันทึกข้อมูลส่วนตัว',
    'gender': 'เพศ',
    'date_of_birth': 'วันเกิด',
    'marital_status': 'สถานภาพ',
    'country': 'ประเทศ',
    'by_clicking': 'กด',
    'continue': 'ต่อไป',
    'read_and_agree': 'คุณได้อ่านและยอมรับเงื่อนไขของ InsightzClub',
    'privacy_policy': 'นโยบายส่วนบุคคล',
    'login_information': 'ข้อมูลการเข้าระบบ',
    'provide_login_info': 'กรุณากรอกข้อมูลสำหรับการเข้าระบบที่นี่',
    'name': 'ชื่อ',
    'email': 'อีเมล',
    'country_code': 'รหัสประเทศ',
    'mobile_number': 'หมายเลขโทรศัพท์มือถือ',
    'password': 'รหัสผ่าน',
    'confirm_password': 'ยืนยันรหัสผ่าน',
    'referral_code': 'รหัสอ้างอิง',
    'previous': 'ก่อนหน้า',
    'verification': 'ตรวจสอบ',
    'provide_otp': 'กรุณาไส่ระหัส OTPที่ได้มีการส่งไปยังเลขหมายโทรศัพท์มือถือและอีเมลของท่าน',
    'otp': 'ระหัส OTP',
    'confirmation': 'ยืนยัน',
    'successful_registration': 'ท่านได้ทำการลงทะเบียนสำเร็จแล้ว กรุณากดปุ่มย้อนกลับด้านบน',
    'email_valid': 'อีเมลจะต้องสามารถใช้งานได้',
    'confirmation_password': 'คุณต้องทำการยืนยันรหัสผ่าน',
    'confirmation_password_mismatch': 'การยืนยันรหัสผผ่านของคุณไม่ตรงกับรหัสที่กำหนดไว้',
    'six_characters': 'ระบุ 6 ตัวอักษร',
    'male': 'ชาย',
    'female': 'หญิง',
    'single': 'โสด',
    'married': 'สมรส',
    'divorced': 'หย่าร้าง',
    'min': 'ต่ำสุด',
    'max': 'สูงสุด',
    'characters': ' ตัวอักษร',
    'mobile_number_cannot': 'หมายเลขโทรศัพท์มือถือไม่สามารถขึ้นต้นด้วย',
    'otp_not_match': 'รหัส OTP ไม่ตรง',
    'enter_valid_email': 'กรุณากรอกอีเมลที่ใช้งานได้',
    'account_details': 'รายละเอียดบัญชีผู้ใช้',
    'provide_account_details': 'กรุณากรอกรายละเอียดบัญชีผู้ใช้',
    'new_password': 'รหัสผ่านใหม่',
    'provide_password': 'กรุณายืนยันรหัสผ่านใหม่พร้อมกับรหัส OTP',
    'confirm_new_password': 'ยืนยันรหัสผ่านใหม่',
    'successful_password_change': 'คุณได้ทำการเปลี่ยนรหัสผ่านสำเร็จแล้ว กรุณากดปุ่มย้อนกลับเพื่อเข้าสู่ระบบและรับรองตัวตนใหม่',
    'validate_details_failed': 'เราไม่สามารถตรวจสอบข้อมูลของคุณ',
    'full_name': 'ชื่อ นามสกุล',
    'full_name_bank_account_hint': 'กรุณากรอกชื่อ นามสกุล ให้เหมือนกับชื่อผู้ถือบัญชีธนาคาร',
    'bank_name': 'ชื่อธนาคาร',
    'account_number': 'เลขที่บัญชี',
    'update': 'อัปเดต',
    'frequently_asked_questions': 'คำถามที่พบบ่อย',
    'terms_conditions': 'ข้อตกลงและเงื่อนไข',
    'min_req_redemption': 'เงื่อนไขขั้นต่ำสำหรับการแลกของคือเท่าไหร่',
    'min_req_redemption_ans': 'คะแนนขั้นต่ำของการแลกของคือ',
    'points_currency_ratio': 'อะไรคือการคำนวนคะแนนต่ออัตราแลกเปลี่ยนเงินตราของแต่ละประเทศ',
    'points_currency_ratio_ans': ' คะแนน = ',
    'reward_expect': 'เมื่อไหร่ที่ฉันจะคาดหวังการได้รับรางวัล',
    'reward_expect_ans': 'จะมีการกำหนดระยะเวลาสิ้นสุดทุกๆสิ้นเดือนของแต่ละเดือน ข้อมูลของคุณจะถูกดำเนินการในทุกวันที่ 15 ของเดือนถัดไป ในกรณีที่วันที่ 15เป็นวันเสาร์ อาทิตย์ หรือวันหยุดราชการ รางวัลจะดำเนินการในวันทำการถัดไป',
    'contact_payout': 'ฉันจะติดต่อทีมงานที่ทำการจ่ายอย่างไร ถ้าต้องการสอบถามข้อมูล',
    'contact_payout_ans': 'คุณสามารถติดต่อทีมที่ดูแลการจ่ายเงินของอินไซท์คลับได้ที่ที่อยู่อีเมล askus@insightzclub.com',
    'close': 'ปิด',
    'contact_payout_no_country': 'เราไม่รองรับการจ่ายเงินสำหรับประเทศของคุณ คุณสามารถติดต่อทีมที่ดูแลการจ่ายเงินของInsightzClub จากที่อยู่อีเมล askus@insightclub.com',
    'bank_account_details': 'รายละเอียดบัญชีธนาคาร',
    'before_processing': 'ก่อนที่จะดำเนินการ กรุณาตรวจสอบ InsightzClub\'s การจ่ายเงิน',
    'no_cashout_info': 'เสียใจด้วย ไม่มีข้อมูลการจ่ายเงินในประเทศที่คุณลงทะเบียน กรุณาตรวจสอบภายหลัง',
    'points_to_redeem_min': 'เสียใจด้วย คุณต้องมีคะแนนขั้นต่ำ {0} สำหรับการแลก คุณจะสามารถแลกคะแนนได้ก็ต่อเมื่อคุณมีคะแนนขั้นต่ำในการแลก',
    'provide_bank_account_details': 'กรุณาระบุรายละเอียดบัญชีธนาคารที่คุณต้องการจะรับเงิน',
    'choose_cashout_amount': 'เลือกจำนวนการจ่าย',
    'select_amount': 'กรุณาเลือกจำนวนเงินที่ต้องการในการจ่าย โดยใช้ปุ่ม + หรือ - เพื่อเพิ่มและลดจำนวน',
    'total_available_points': 'คะแนนทั้งหมดที่สามารถใช้ได้',
    'points': 'คะแนน',
    'equivalent_to': 'เทียบเท่า',
    'back': "ย้อนกลับ",
    'confirm_selections': 'ยืนยันที่คุณได้เลือก',
    'confirm_details': 'กรุณายืนยันรายละเอียด',
    'points_to_redeem': 'คะแนนที่จะแลก',
    'cashout_amount': 'จำนวนเงิน',
    'you_have_read': ' คุณได้อ่านและตกลงของ InsightzClub\'s การจ่ายเงิน',
    'confirm': 'ยืนยัน',
    'thank_you': 'ขอบคุณ',
    'points_confirmation_email': 'คุณได้ทำการแลกจำนวน {0} คะแนนสำเร็จแล้ว ระบบจะส่งการยืนยันไปที่อีเมลของคุณโดยเร็ว',
    'min_points_not_met': 'คะแนนขั้นต่ำไม่ถึงที่กำหนด',
    'exceeded_points': 'เกินจำนวนคะแนนที่มี',
    'earn_points_by_watching_videos': 'คุณสามารถรับคะแนนจากการเข้าชมวีดีโอและตอบคำถามให้ถูกต้องจากการชมวีดีโอ',
    'provide_task_id': 'ระบุรหัสของงาน',
    'add_task': 'ในการเพิ่มงาน กรุณาระบุรหัสของงานที่ได้รับ รหัสของงานจะผสมกันของตัวอักษรและตัวเลข',
    'successfully_added_task': 'คุณได้เพิ่มงานสำเร็จแล้ว ถ้างานนั้นยังไม่เริ่ม จะไม่สามารถมองเห็นงานนั้นๆได้ในขณะนี้',
    'no_video_available': 'ขอโทษด้วย ขณะนี้ยังไม่มีวีดีโอให้คุณรับชม กรุณาตรวจสอบภายหลัง',
    'watch': 'รับชม',
    'for_video_watched': 'สำหรับวีดีโอที่คุณเพิ่งได้รับชม',
    'you_have_earned': 'คุณได้รับแล้ว',
    'account_already_activated': 'ดูเหมือนว่าบัญชีของคุณเปิดใช้งานแล้ว',
    'successful_activated': 'คุณได้ทำการเปิดบัญชีเพื่อใช้งานเรียบร้อยแล้ว กรุณากดปุ่มย้อนกลับด้านบน',
    'trying_to': 'กำลังทำอยู่',
    'here': 'ที่นี่',
    'activate_account': 'การเปิดบัญชีเพื่อเข้าใช้งาน',
    'forgot_password': 'ลืมรหัส',
    'click': 'กด',
    'successfully_updated': 'การอัปเดตสำเร็จแล้ว',
    'additional_demographic': 'ข้อมูลส่วนตัวอื่นๆ',
    'no_info': 'ไม่พบข้อมูลที่ใช้งานได้ขณะนี้',
    'loading': 'กำลังทำการโหลด',
    'topArtists': 'ศิลปินที่ได้รับความนิยมสูงสุด',
    'topTracks': 'อัลบั้มที่ได้รับความนิยมสูงสุด',
    'take_again': 'ทำอีกครั้ง',
    'start': 'เริ่ม',
    'completed': 'เสร็จสมบูรณ์',
    'required': 'จำเป็นต้องระบุ',
    'have_referral_code': 'ฉันมีรหัสอ้างอิงหรือรหัสของงาน',
    "deactivated_account": "บัญชีนี้ถูกระงับ กรุณาติดต่อเราเพื่อความช่วยเหลือ",
    "inactive_account": "บัญชียังไม่สามารถเข้าใช้งานได้ กรุณาเข้าบัญชีเพื่อใช้งาน",
    "user_not_found": "ไม่พบข้อมูลผู้ใช้งาน กรุณาลงชื่อ",
    "user_details_mismatch": "รายละเอียดที่กรอกไม่ตรง กรุณาตรวจสอบ",
    "account_pending_reset_password": "บัญชีรอดำเนินการเพื่อตั้งรหัสผ่านใหม่ กรุณาตั้งรหัสผ่านใหม่",
    "brands_purchased_not_completed": "เราไม่สามารถค้นหากลุ่มยี่ห้อที่คุณได้เคยซื้อไว้ เนื่องจากคุณไม่ได้คัดกรองงานให้เสร็จสมบูรณ์ <br><br> กรุณาคัดกรองให้เสร็จสมบูรณ์",
    "submit_brands_purchased": "ส่งยี่ห้อที่เคยซื้อ",
    "what_is_data_collected": "ข้อมูลอะไรบ้างที่จะถูกจัดเก็บ",
    "what_is_data_collected_answer": "ข้อมูลที่จะถูกจัดเก็บ มี ข้อมูลตำแหน่ง GPS, เทคโนโลยี (ยี่ห้อ รุ่นมือถือ, ชื่อมือถือ, ระบบปฏิบัติการ, อื่นๆ) และแอพที่อยู่ในอุปกรณ์นั้น ซึ่งข้อมูลอื่นๆที่ได้รับจากคุณในการสำรวจและอื่นๆที่ใช้ได้",
    "purpose_of_data": "อะไรคือวัตถุประสงค์ในการเก็บข้อมูลนี้",
    "purpose_of_data_answer": "เราเก็บข้อมูลเพื่อวัตถุประสงค์สำหรับงานวิจัยตลาดเท่านั้น งานวิจัยจะช่วยให้ยี่ห้อและการบริการที่คุณเคยใช้ จะได้เข้าใจถึงประสบการณ์และการใช้งานเพื่อเกิดการพัฒนาสินค้าและการบริการที่เสนอให้คุณ โดยข้อมูลของคุณจะถูกรายงานในระดับภาพรวมเท่านั้น ซึ่งหมายความว่าข้อมูลที่ได้รับจากคุณและคนอื่นๆจะนำมาผสมผสานกัน ดังนั้นข้อมูลต่างๆที่ได้รับจะถูกรายงานในขั้นพื้นฐานระดับสูงและจะไม่อยู่ในเฉพาะบุคคลใดบุคคลหนึ่ง",
    "what_are_rewards": "รางวัลมีอะไรบ้าง",
    "what_are_rewards_answer": "คุณจะได้รับรางวัล (เงินสดและ/หรือไม่ไช่เงินสด) ตราบเท่าที่คุณยังมีแอพไว้ที่อุปกรณ์ของคุณ คุณสามารถได้รับรางวัลพิเศษโดยการมีส่วนร่วมในการสำรวจ กรุณาเข้าไปที่การเลือกของรางวัลเพื่ออ่านรายละเอียดของของรางวัล",
    "can_see_own_data": "ฉันสามารถที่จะเห็นข้อมูลของตัวเองมั้ย",
    "can_see_own_data_answer": "ได้ ดูได้จากกระดานผู้ใช้งาน คุณสามารถเห็นข้อมูลในด้านต่างๆ ที่คุณรู้อยู่แล้วและเข้าถึงได้",
    "privacy_taken_care": "ความเป็นส่วนตัวแบบออนไลน์ของฉันจะถูกปกป้องอย่างไร",
    "privacy_taken_care_answer": "ข้อมูลที่ถูกรวบรวมจะไม่มีการระบุชื่อ หมายความว่าข้อมูลส่วนตัว หรือข้อมูลอะไรก็ตามที่ชี้นำไปถึงตัวสมาชิกนั้นจะถูกลบออก โดยข้อมูลจะทำการผสมผสานกันกับข้อมูลของผู้อื่น และรายงานที่จะส่งไปเป็นแค่ในภาพรวม อีเมลและหมายเลขโทรศัพท์นั้นจะนำไปรวมกับข้อมูลในรายงาน แต่จะใช้เพื่อการของการติดตั้งแอพและการส่งรางวัลเท่านั้น",
    "data_protection_taken_care": "การปกป้องและการรักษาความปลอดภัยของข้อมูลจะทำได้อย่างไร",
    "data_protection_taken_care_answer": "ข้อมูลทั้งหมดที่ถูกรวบรวมจะเป็นไปตาม พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล (PDAP) รวมไปถึงข้อบังคับในกฎหมายของสหภาพยุโรปว่าด้วยการคุ้มครองข้อมูล ความเป็นส่วนตัว และเขตเศรษฐกิจในยุโรป (GDPR)",
    "who_to_contact": "ฉันสามารถติดต่อใครได้ หากเกิดคำถาม",
    "who_to_contact_answer": "คุณสามารถส่งอีเมลมาที่ <a href='mailto: askus@insightzclub.com'>askus@insightzclub.com</a> หรือพูดคุยกับเราที่ <ahref='https://wa.me/60320359220'>Whatsapp (+603-2035-9220)</a> ถ้ามีคำถามสำหรับงานในโครงการนี้",
    "refer_and_earn": 'อ้างอิงและรับ',
    "refer_and_earn_point_1": "1. ส่งข้อความที่อยู่ใต้ที่อ้างอิงตอนนี้ถึงเครือข่ายของคุณ (ผ่าน Whatsapp, SMS, PM, Facebook, อื่นๆ)",
    "refer_and_earn_point_2": "2. ตอนที่ใครก็ตามในเครื่อข่ายของคุณติดตั้งในนามการอ้างอิงจากคุณ คุณจะได้รับ 50คะแนนต่อคน สำหรับคนที่ทำการติดตั้ง",
    "share_and_earn": "ส่งต่อและรับรางวัล",
    "share_and_earn_point_1": "1. ส่งต่อข้อความภายใต้ส่งต่อตอนนี้ไปที่สังคมออนไลน์หรือเครือข่ายสำหรับการทำงาน (Facebook, Instagram, Linkedin, Twitter)",
    "share_and_earn_point_2": "2. ทำให้โพสเป็นสาธารณะ",
    "share_and_earn_point_3": "3. ทำการพิสูจน์การส่งต่อโดยการอัพโหลดในยอมรับการพิสูจน์ แล้วคุณจะได้รับรางวัล",
    "share_and_earn_point_4": "4. คุณจะได้รับ 25คะแนนจากการส่งต่อในแต่ละแพลตฟอร์ม",
    "engage_and_earn": "เข้ามีส่วนร่วมและรับ",
    "engage_and_earn_point_1": "1. ส่งต่อข้อความภายใต้ส่งต่อตอนนี้ไปที่สังคมออนไลน์หรือเครือข่ายสำหรับการทำงาน (Facebook, Instagram, Linkedin, Twitter)",
    "engage_and_earn_point_2": "2. ทำให้โพสเป็นสาธารณะ",
    "engage_and_earn_point_3": "3.ยิ่งมีส่วนร่วมมากเท่าไหร่ในการส่งต่อ (likes, shares, comments) ยิ่งได้รับมากขึ้น",
    "engage_and_earn_point_4": "4. คุณจะได้รับ 1คะแนนสำหรับแต่ละการมีส่วนร่วมในการโพสต์ นอกเหนือจาก 25คะแนนสำหรับการส่งต่อไปยังแต่ละแพลตฟอร์ม",
    "engage_and_earn_point_5": "5. ทำการพิสูจน์การส่งต่อโดยการอัพโหลดในยอมรับการพิสูจน์ และรับรางวัล",
    "user_profile": "ข้อมูลผู้ใช้งาน",
    "logout": "ออกจากระบบ",
    "task_id": "หมายเลขของงาน",
    "min_characters": "ขั้นต่ำ {1} ตัวอักษร",
    "max_characters": "ขั้นต่ำ {1} ตัวอักษร",
    "available_points": "คะแนนที่ใช้ได้",
    "trees_planted": "ต้นไม้ที่ได้ปลูก",
    "plant_tree": "ปลูกต้นไม้",
    "cashout_to_bank": "เงินออกสู่ธนาคาร",
    "evertreen_intro": "คุณสามารถแลกคะแนนในการปลูกต้นไม้กับพันธมิตรของเรา Evertreen",
    "select_a_tree": "เลือกต้นไม้",
    "read_more": "อ่านเพิ่มเติม",
    "plant_this": "ปลูกต้นนี้",
    "language": "ภาษา",
    "login": "เข้าสู่ระบบ",
    "login_with_qr": "เข้าสู่ระบบด้วย QR",
    "login_with_otp": "เข้าสู่ระบบด้วย OTP",
    "scan_to_login_insightzclub": "สแกนเพื่อเข้าสู่ระบบ InsightzClub",
    "scan_to_login_insightzclub_description": "กรุณาสแกน QR Code จากแอพ InsightzClub ในมือถือของคุณ",
    "unable_to_scan_qr": "ไม่สามารถสแกน QR code ได้",
    "upload_file": "อัพโหลดไฟล์",
    "uploaded_and_task_completed": "คุณได้ทำการอัพโหลดและทำงานเสร็จสมบูรณ์",
    "file_exceeded_limit": "การอัพโหลดไฟล์มีขนาดใหญ่เกินกว่าที่รับได้สูงสุด {0} MB. ไฟล์ของคุณขนาด {1} MB.",
    "browser_history": "การค้นหาในอดีต",
    "url": "URL",
    "visited_at": "เข้าไปที่",
    "actions": "กระทำ",
    "title": "หัวข้อ",
    "delete": "ลบ",
    "whatsApp": "Whatsapp",
    "top_5_most_recent_ads_and_show_watched": "5อันดับสูงสุดของโฆษณาปัจจุบันที่แสดงถึงการเข้าชม",
    "top_5_most_recent_ads_seen": "5อันดับสูงสุดของโฆษณาปัจจุบันที่มีการเห็น",
    "top_5_most_recent_ads_interacted": "5อันดับสูงสุดของโฆษณาปัจจุบันที่มีการโต้ตอบ",
    "top_5_most_recent_page_visits": "5อันดับสูงสุดของหน้าที่มีการเข้าเยี่ยม",
    "password_policy": "ของรหัส",
    "password_policy_description": "รหัสของคุณต้องทีค่ามากกว่าหรือเท่ากันกับเลข 8",
    "current_password": "รหัสที่ใช้ปัจจุบัน",
    "new_password_not_match": "ช่องสำหรับรหัสใหม่และยืนยันรหัสใหม่",
    "save_successfully": "การเปลี่ยนแปลงได้รับการบันทึกสำเร็จแล้ว",
    "code_invalid": "Code ไม่ถูกต้อง",
    "before_we_begin": "ก่อนที่เราจะเริ่ม",
    "before_we_begin_description": "บัญชีของฉันอาจจะถูกระงับการใช้งานจาก InsightzClub จากการที่ฉันได้ละเมิดกฏต่างๆด้านล่าง:<br><ul><li>การสร้างบัญชี InsightzClub มากกว่าหนึ่งบัญชี</li><li>ให้ข้อมูลที่ไม่ไช่ข้อมูลตัวเอง</li><li>ให้ข้อมูลที่ไม่เป็นจริง</li><li>ส่งต่อบัญชีไปให้กับผู้อื่น</li></ul>",
    "rules_mentioned": "และกฏที่กล่าวถึงด้านบน",
    "cashout_bank_not_available_reasons": "การส่งเงินของคุณไปยังธนาคารยังไม่สามารถทำได้ในขณะนี้ อาจจะเกิดขึ้นจากกรณีต่างๆดังนี้<br><ul><li>คุณเป็นผู้ใช้งานใหม่</li><li>คุณไม่ไช่ผู้ที่จะได้รับ</li><li>มีผู้ที่กำลังใช้งานเป็นจำนวนมากในขณะนี้</li></ul><br>กรุณาตรวจสอบภายหลัง",
    "available_on_mobile_app_only": "สามารถใช้ได้งานได้ที่ InsightzClub แอพ ในมือถือเท่านั้น",
    "app_only": "แอพเท่านั้น",
    "view_data": "ดูข้อมูล",
    "link_data": "เชื่อมต่อข้อมูล",
    "timestamp": "ประทับเวลา",
    "cashout_to_paypal": "เบิกเงินทาง PayPal",
    'paypal_account_details': 'รายละเอียดบัญชี PayPal',
    'provide_paypal_account_details': 'กรุณาแจ้งรายละเอียดบัญชี PayPal ที่คุณต้องการใช้ในการเบิกเงิน',
    'full_name_paypal_hint': 'กรุณาแจ้งชื่อของคุณ ที่ใช้ในบัญชี PayPal',
    'paypal_email': 'อีเมลที่ใช้ใน PayPal',
    'paypal_email_hint': 'กรุณาแจ้งอีเมลของคุณ ที่ใช้ในบัญชี PayPal',
    'paypal_payout_charges': 'มีค่าใช้จ่ายเพิ่มเติมหรือไม่?',
    'paypal_payout_charges_ans': 'ไม่ InsightzClub จะไม่เก็บค่าใช้จ่ายเพิ่มเติมสำหรับการเบิกเงิน คุณจะได้รับเงินเต็มจำนวนที่เบิก',
    'paypal_payout_currency': 'ฉันจะได้รับเงินสกุลใดจาก PayPal?',
    'paypal_payout_currency_ans': 'USD (ดอลล่าสหรัฐ). เป็นเงินสกุลเดียวที่รองรับในเวลานี้.',
    'paypal_countries_availability': 'PayPal รองรับประเทศ หรือ ภูมิภาคของฉันหรือไม่?',
    'paypal_countries_availability_ans': "เพื่อให้แน่ใจในการรองรับ กรุณาตรวจสอบที่  <a href='https://www.paypal.com/webapps/mpp/country-worldwide?locale.x=en_US' target='_blank'>PayPal Countries/Region Availability</a>",
    'cashout_paypal_not_available_reasons': "การเบิกเงินผ่าน PayPal ไม่สามารถทำได้ในขณะนี้ อาจจะเป็นเพราะสาเหตุต่างๆเหล่านี้ <br><ul><li>คุณเป็นผู้ใช้รายใหม่</li><li>คุณไม่ใช่ผู้ใช้ที่กลับมาอีกครั้ง</li><li>มีผู้ทำการเบิกเงินเป็นจำนวนมากในขณะนี้</li><li>การถอนเงินอาจไม่สามารถใช้ได้ในประเทศของคุณ</li></ul><br>กรุณาทำรายการใหม่ อีกครั้งหลังจากนี้",
    'cashout_everteen_not_available_reasons': "ฟีเจอร์นี้ยังไม่พร้อมให้บริการในประเทศของคุณ <br>โปรดกลับมาใหม่ในภายหลัง",
    'confirm_account_details': "ยืนยันรายละเอียดบัญชี",
    'confirm_account_details_info': "โปรดยืนยันข้อมูลที่คุณป้อน หากมีข้อผิดพลาด ให้กลับไปที่ขั้นตอนก่อนหน้าเพื่อแก้ไขข้อมูล",
    'cancel': "ยกเลิก",
    'receipt_invalid_reasons': "ขออภัย ระบบไม่สามารถตรวจสอบใบเสร็จที่คุณอัปโหลดได้ อาจเกิดจากสาเหตุต่อไปนี้ด้านล่าง<br><ul><li>ใบเสร็จไม่ถูกต้อง</li><li>ภาพที่อัปโหลดไม่ชัดเจน</li></ul><br > โปรดติดต่อฝ่ายสนับสนุนลูกค้าของเราสำหรับข้อมูลเพิ่มเติม",
    'ireview_no_data': 'ขออภัย ไม่มีข้อมูลสำหรับหมวดหมู่ที่เลือก',
    'next': 'ถัดไป',
    'survey_submitted': 'ขอขอบคุณที่ตอบแบบสำรวจนี้! บันทึกคำตอบของคุณแล้ว ตอนนี้คุณสามารถออกจากหน้า'
}