import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import router from './router'
import store from "./app.store.js";
import mixin from "./app.mixin.js";
import axios from 'axios'
import VueCookies from 'vue-cookies'

import Default from './layouts/Default';
import NoScroll from './layouts/NoScroll';

Vue.component('default-layout', Default);
Vue.component('no-scroll-layout', NoScroll);

Vue.mixin(mixin);
Vue.use(VueCookies)

VueCookies.config('1h')

Vue.config.productionTip = false

axios.interceptors.response.use(undefined, function (error) {
  store.state.loader = false

  if (error.response && (error.response.status === 409 || error.response.status === 401)) {
    if (error.response.status === 401) {
      store.state.dialogMessageTitle = "Duplicate Login Detected"
      store.state.dialogMessageText = "We have detected a duplicate login under the same account. You have been logged out automatically."
    } else if (error.response.status === 409) {
      store.state.dialogMessageTitle = "Session Expired"
      store.state.dialogMessageText = "Your session has expired. Please re-login to renew your session."
    }
    
    store.state.showDialogMessage = true

    window.$cookies.remove("userId")
    window.$cookies.remove("email")
    window.$cookies.remove("token")

    router.replace({ name: 'Login' });
  }

  if (error.response && error.response.status === 400) {
    store.state.dialogMessageTitle = null

    if (error.response.data && error.response.data.message) {
      store.state.dialogMessageText = Vue.prototype._i18n.t(error.response.data.message)
    } else if (error.response.message) {
      store.state.dialogMessageText = Vue.prototype._i18n.t(error.response.message)
    } else {
      store.state.dialogMessageText = "There is an error. Please reach out to our team for support."
    }

    store.state.showDialogMessage = true
  }

  return Promise.reject(error)
});

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store,
}).$mount('#app')