export default {
    'demographic_info': 'Thông tin nhân khẩu học',
    'provide_demographic': 'Vui lòng điền thông tin nhân khẩu học cơ bản ở đây.',
    'gender': 'Giới tính',
    'date_of_birth': 'Ngày tháng năm sinh',
    'marital_status': 'Tình trạng hôn nhân',
    'country': 'Quốc tịch',
    'by_clicking': 'Bằng cách nhấn vào',
    'continue': 'Tiếp tục',
    'read_and_agree': 'tôi đã đọc và đồng ý với InsightzClub',
    'privacy_policy': 'Chính sách quyền riêng tư',
    'login_information': 'Thông tin đăng nhập',
    'provide_login_info': 'Vui lòng nhập thông tin đăng nhập ở đây.',
    'name': 'Tên',
    'email': 'Email',
    'country_code': 'Mã Quốc gia',
    'mobile_number': 'Số điện thoại',
    'password': 'Mật khẩu',
    'confirm_password': 'Xác nhận mật khẩu',
    'referral_code': 'Mã giới thiệu',
    'previous': 'Trở về',
    'verification': 'Xác thực',
    'provide_otp': 'Vui lòng nhập mã OTP đã được gửi tới số điện thoại hoặc email được đăng ký.',
    'otp': 'Mã OTP',
    'confirmation': 'Xác nhận',
    'successful_registration': 'Bạn đã đăng ký thành công. Vui lòng nhấn phím QUAY LẠI ở phía trên.',
    'email_valid': 'E-mail phải khả dụng',
    'confirmation_password': 'Yêu cầu xác nhận mật khẩu.',
    'confirmation_password_mismatch': 'Mật khẩu xác nhận không khớp với mật khẩu.',
    'six_characters': 'Mã 6 ký tự',
    'male': 'Nam',
    'female': 'Nữ',
    'single': 'Độc thân',
    'married': 'Đã kết hôn',
    'divorced': 'Đã ly dị',
    'min': 'Tối thiểu ',
    'max': 'Tối đa ',
    'characters': ' ký tự',
    'mobile_number_cannot': 'Số điện thoại di động không được bắt đầu bằng',
    'otp_not_match': 'Mã OTP không khớp.',
    'enter_valid_email': 'Vui lòng nhập một địa chỉ email khả dụng',
    'account_details': 'Chi tiết tài khoản',
    'provide_account_details': 'Vui lòng cung cấp chi tiết tài khoản tại đây.',
    'new_password': 'Mật khẩu mới',
    'provide_password': 'Vui lòng cung cấp mật khẩu mới của bạn cùng với mã OTP đã được tạo.',
    'confirm_new_password': 'Xác nhận mật khẩu mới',
    'successful_password_change': 'Bạn đã thay đổi mật khẩu thành công. Vui lòng nhấn vào nút Quay lại để đăng nhập bằng chứng chỉ đăng nhập mới.',
    'validate_details_failed': 'Chúng tôi không thể xác thực thông tin của bạn.',
    'full_name': 'Họ tên đầy đủ',
    'full_name_bank_account_hint': 'Vui lòng điền vào họ tên đầy đủ chính xác vào phần chủ tài khoản ngân hàng',
    'bank_name': 'Tên ngân hàng',
    'account_number': 'Số tài khoản',
    'update': 'Cập nhật',
    'frequently_asked_questions': 'Các câu hỏi thường gặp (FAQ) ',
    'terms_conditions': 'Điều khoản & Điều kiện',
    'min_req_redemption': 'Yêu cầu tối thiểu để đổi phần thưởng?',
    'min_req_redemption_ans': 'Điểm tối thiểu để thực hiện đổi phần thưởng là ',
    'points_currency_ratio': 'Tỷ lệ chuyển đổi từ điểm thành tiền mặt là bao nhiêu?',
    'points_currency_ratio_ans': ' Điểm = ',
    'reward_expect': 'Khi nào thì tôi sẽ nhận được thưởng?',
    'reward_expect_ans': 'Hạn chót sẽ là cuối mỗi tháng. Khoản được trả ghi nhận cho bạn sẽ được xử lý vào ngày 15 của tháng tiếp theo. Nếu ngày 15 rơi vào ngày lễ hay cuối tuần thì sẽ được xử lý vào ngày làm việc tiếp theo.',
    'contact_payout': 'Tôi có thể liên hệ  với người chịu trách nhiệm đổi phần thưởng như thế nào nếu có câu hỏi?',
    'contact_payout_ans': 'Bạn có thể liên hệ người chịu trách nhiệm đổi phần thưởng của InsightzClub qua địa chỉ email sau đây: askus@insightzclub.com.',
    'close': 'Đóng',
    'contact_payout_no_country': 'Chúng tôi không hỗ trợ tiền mặt cho quốc gia của bạn. Bạn có thể liên lạc với  liên hệ người chịu trách nhiệm đổi phần thưởng của InsightzClub qua địa chỉ email sau đây: askus@insightzclub.com.',
    'bank_account_details': 'Chi tiết tài khoản ngân hàng',
    'before_processing': 'Trước khi xử lý, vui lòng kiểm tra phần trả tiền mặt của InsightzClub',
    'no_cashout_info': 'Xin lỗi, không có thông tin về hỗ trợ trả thưởng bằng tiền mặt nào cho quốc gia đã đăng ký của bạn. Vui lòng kiểm tra lại sau.',
    'points_to_redeem_min': 'Xin lỗi, bạn cần ít nhất {0} điểm để đổi thưởng. Bạn sẽ có thể đổi thưởng điểm của bạn khi bạn đạt được yêu cầu tối thiểu.',
    'provide_bank_account_details': 'Vui lòng cung cấp thông tin tài khoản ngân hàng của bạn mà bạn muốn nhận khoản thanh toán của mình.',
    'choose_cashout_amount': 'Chọn số tiền mặt',
    'select_amount': 'Vui lòng chọn số tiền bạn muốn rút. Sử dụng nút + hoặc - để tăng hoặc giảm số tiền.',
    'total_available_points': 'Tổng số điểm khả dụng của bạn là',
    'points': 'Điểm',
    'equivalent_to': 'Tương đương với ',
    'back': "Trở lại",
    'confirm_selections': 'Xác nhận lựa chọn của bạn',
    'confirm_details': 'Vui lòng xác nhận chi tiết.',
    'point_to_redeem': 'Điểm để đổi',
    'cashout_amount': 'Số lượng rút tiền',
    'you_have_read': 'Bạn đã đọc và đồng ý với InsightzClub \' s chính sách đổi tiền mặt ',
    'confirm': 'Xác nhận',
    'thank_you': 'Xin cám ơn!',
    'points_confirmation_email': 'Bạn đã đổi thành công {0} điểm. Email xác nhận sẽ sớm được gửi đi',
    'min_points_not_met': 'Không đạt điểm tối thiểu',
    'exceeded_points': 'Đã vượt quá số điểm khả dụng',
    'earn_points_by_watching_videos': 'Bạn có thể nhận điểm thưởng bằng cách xem video và trả lời đúng một số câu hỏi liên quan đến video.',
    'provide_task_id': 'Cung cấp ID nhiệm vụ',
    'add_task': 'Để thêm một nhiệm vụ, vui lòng chèn ID nhiệm vụ được cung cấp. ID là sự kết hợp của các ký tự chữ cái và số. ',
    'successfully_added_task': 'Bạn đã thêm một nhiệm vụ thành công. Nếu nhiệm vụ chưa bắt đầu, có thể không hiển thị vào lúc này.',
    'no_video_available': 'Xin lỗi, hiện tại không có video nào để bạn xem. Vui lòng kiểm tra sau.',
    'watch': 'Xem',
    'for_video_watched': 'dành cho video bạn vừa xem.',
    'you_have_earned': 'Bạn vừa nhận được',
    'account_already_activated': 'Có vẻ như tài khoản của bạn đã được kích hoạt',
    'successful_activated': 'Bạn đã kích hoạt thành công tài khoản của mình. Vui lòng nhấn vào nút QUAY LẠI ở trên cùng. ',
    'trying_to': 'Đang làm',
    'here': 'Tại đây',
    'activate_account': 'Kích hoạt tài khoản',
    'forgot_password': 'Quên mật khẩu',
    'click': 'Click',
    'successfully_updated': 'Cập nhật thành công',
    'additional_demographic': 'Thêm đặc điểm nhân khẩu học',
    'no_info': 'Không có thông tin khả dụng vào lúc này.',
    'loading': 'Đang tải...',
    'topArtists': 'Những nghệ sĩ hàng đầu',
    'topTracks': 'Những bản nhạc hàng đầu',
    'take_again': 'Chụp lại',
    'start': 'Bắt đầu',
    'completed': 'Hoàn thành',
    'required': 'Yêu cầu',
    'have_referral_code': 'Tôi có mã giới thiệu hoặc mã dự án',
    "deactivated_account": "Tài khoản đã bị vô hiệu hóa. Vui lòng liên hệ với chúng tôi để được hỗ trợ",
    "inactive_account": "Tài khoản chưa được kích hoạt. Vui lòng kích hoạt tài khoản của bạn",
    "user_not_found": "Không tìm thấy người dùng. Vui lòng đăng ký",
    "user_details_mismatch": "Các chi tiết bạn nhập không trùng khớp. Vui lòng xác minh!",
    "account_pending_reset_password": "Mật khẩu đặt lại tài khoản đang chờ xử lý. Vui lòng đặt lại mật khẩu của bạn",
    "brands_purchased_not_completed": "Chúng tôi không thể truy xuất danh mục các thương hiệu đã mua của bạn vì bạn chưa hoàn thành nhiệm vụ sàng lọc. <br> <br> Vui lòng hoàn thành nhiệm vụ sàng lọc ngay bây giờ.",
    "submit_brands_purchased": "Gửi thông tin các thương hiệu đã mua",
    "what_is_data_collected": "Những dữ liệu nào sẽ được thu thập?",
    "what_is_data_collected_answer": "Thông tin được thu thập thụ động là dữ liệu vị trí của bạn, dữ liệu công nghệ (thiết bị của bạn, tên thiết bị, hệ điều hành, v.v.) và các ứng dụng được cài đặt trên thiết bị của bạn. Mọi thông tin khác được thu thập đều do bạn cung cấp thông qua khảo sát hoặc các phương tiện hoạt động khác.",
    "purpose_of_data": "Mục đích của việc thu thập dữ liệu này là gì?",
    "purpose_of_data_answer": "Chúng tôi chỉ thu thập dữ liệu cho mục đích nghiên cứu thị trường. Nghiên cứu này giúp các thương hiệu và dịch vụ mà bạn đã sử dụng hiểu được trải nghiệm và cách sử dụng của bạn, từ đó cải thiện sản phẩm và/hoặc dịch vụ cung cấp cho bạn. Dữ liệu của bạn sẽ chỉ được báo cáo ở cấp độ tổng hợp. Điều này có nghĩa là dữ liệu của bạn và dữ liệu của những người tham gia khác sẽ được kết hợp với nhau. Do đó, dữ liệu sẽ được báo cáo ở cấp độ tổng hợp chung chứ không phải ở cấp độ cá nhân.",
    "what_are_rewards": "Phần thưởng nhận được là gì?",
    "what_are_rewards_answer": "Bạn sẽ nhận được phần thưởng (tiền mặt và/hoặc không bằng tiền mặt) miễn là bạn vẫn tiếp tục cài đặt ứng dụng trên thiết bị của mình. Bạn có thể kiếm thêm phần thưởng bằng cách tham gia các nhiệm vụ hoặc khảo sát. Vui lòng truy cập mục phần thưởng để xem thêm chi tiết về phần thưởng.",
    "can_see_own_data": "Tôi có thể xem dữ liệu của riêng mình không?",
    "can_see_own_data_answer": "Có thể, tại bảng điều khiển người dùng, bạn có thể thấy tất cả các thông số dữ liệu về bạn hiện đang được biết đến hoặc được truy cập.",
    "privacy_taken_care": "Quyền riêng tư trực tuyến của tôi được bảo vệ như thế nào?",
    "privacy_taken_care_answer": "Tất cả thông tin thu thập được đều là thông tin ẩn danh. Điều này có nghĩa là mọi thông tin cá nhân hoặc phương tiện xác định người tham gia đều sẽ bị xóa. Dữ liệu được kết hợp với những người tham gia khác và chỉ được báo cáo tổng hợp. Email và số điện thoại không được liên kết với dữ liệu được báo cáo và chỉ được sử dụng cho mục đích cài đặt ứng dụng ban đầu và để trao phần thưởng.",
    "data_protection_taken_care": "Bảo mật và bảo vệ dữ liệu được thực hiện như thế nào?",
    "data_protection_taken_care_answer": "Tất cả dữ liệu được thu thập đều tuân thủ Đạo luật bảo vệ dữ liệu cá nhân (PDPA) và cũng phù hợp với các quy định chung về bảo vệ dữ liệu (GDPR).",
    "who_to_contact": "Tôi có thể liên hệ với ai khi tôi có bất kì câu hỏi nào?",
    "who_to_contact_answer": "Bạn có thể email đến <a href='mailto: askus@insightzclub.com'>askus@insightzclub.com</a> hoặc trò chuyện với chúng tôi qua <a href='https://wa.me/60320359220'>WhatsApp (+603-2035-9220)</a> nếu bạn có bất kì câu hỏi nào liên quan đến dự án.",
    "refer_and_earn": 'Giới thiệu và kiếm ưu đãi',
    "refer_and_earn_point_1": "1. Chia sẻ văn bản với mạng của bạn ngay bây giờ (thông qua WhatsApp, SMS, PM, Facebook, v.v.).",
    "refer_and_earn_point_2": "2. Khi bất kỳ người nào trong mạng của bạn cài đặt thông qua sự giới thiệu của bạn, bạn sẽ kiếm được 50 điểm cho mỗi người cài đặt.",
    "Share and Earn": "Chia sẻ và nhận thưởng",
    "share_and_earn_point_1": "1. Chia sẻ văn bản trong mục Chia sẻ ngay lên mạng xã hội hoặc các trang thông tin chuyên nghiệp của bạn (Facebook, Instagram, LinkedIn, Twitter).",
    "share_and_earn_point_2": "2. Đặt bài đăng ở chế độ công khai.",
    "share_and_earn_point_3": "3. Cung cấp bằng chứng về việc đã chia sẻ bằng cách tải lên trong mục Gửi Bằng chứng và bạn sẽ nhận được phần thưởng.",
    "share_and_earn_point_4": "4. Bạn sẽ kiếm được 25 điểm cho mỗi lượt chia sẻ trên mỗi nền tảng.",
    "engage_and_earn": "Tham gia và nhận thưởng",
    "engage_and_earn_point_1": "1. Chia sẻ văn bản trong mục Chia sẻ ngay lên mạng xã hội hoặc các trang mạng chuyên nghiệp của bạn (Facebook, Instagram, LinkedIn, Twitter).",
    "engage_and_earn_point_2": "2. Đặt bài đăng ở chế độ công khai.",
    "engage_and_earn_point_3": "3. Bạn càng có nhiều tương tác trên bài đăng của mình (lượt thích, chia sẻ, bình luận) thì càng kiếm được nhiều tiền.",
    "engage_and_earn_point_4": "4. Bạn sẽ kiếm được 1 điểm cho mỗi lượt tương tác trên bài đăng của mình, khác với 25 điểm cho lượt chia sẻ trên mỗi nền tảng.",
    "engage_and_earn_point_5": "3. Cung cấp bằng chứng về việc đã chia sẻ bằng cách tải lên trong mục Gửi Bằng chứng và nhận thưởng.",
    "user_profile": "Hồ sơ người dùng",
    "logout": "Đăng xuất",
    "task_id": "Mã nhiệm vụ",
    "min_characters": "Ít nhất {1} ký tự",
    "max_characters": "Tối đa {1} ký tự",
    "available_points": "Điểm khả dụng",
    "trees_planted": "Cây đã trồng",
    "plant_tree": "Trồng cây",
    "cashout_to_bank": "Rút tiền về thẻ ngân hàng",
    "evertreen_intro": "Bạn có thể đổi điểm của mình để trồng cây thông qua đối tác Evertreen của chúng tôi.",
    "select_a_tree": "Chọn một cây",
    "read_more": "Xem thêm",
    "plant_this": "Trồng cây này",
    "language": "Ngôn ngữ",
    "login": "Đăng nhập",
    "login_with_qr": "Đăng nhập bằng mã QR",
    "login_with_otp": "Đăng nhập bằng mã OTP",
    "scan_to_login_insightzclub": "Quét mã để đăng nhập vào InsightzClub",
    "scan_to_login_insightzclub_description": "Vui lòng quét mã QR từ ứng dụng di động InsightzClub của bạn.",
    "unable_to_scan_qr": "Không thể quét mã QR?",
    "upload_file": "Tải dữ liệu lên",
    "uploaded_and_task_completed": "Bạn đã tải lên và hoàn thành nhiệm vụ thành công.",
    "file_exceeded_limit": "Tệp được tải lên vượt quá kích thước tối đa cho phép là {0} MB. Kích thước tệp của bạn là {1} MB.",
    "browser_history": "Lịch sử trình duyệt",
    "url": "URL",
    "visited_at": "Đã ghé thăm lúc",
    "actions": "Hoạt động",
    "title": "Tiêu đề",
    "delete": "Xóa",
    "whatsApp": "WhatsApp",
    "top_5_most_recent_ads_and_show_watched": "5 quảng cáo và chương trình đã xem gần đây nhất",
    "top_5_most_recent_ads_seen": "5 quảng cáo xem gần đây nhất",
    "top_5_most_recent_ads_interacted": "5 quảng cáo được tương tác gần đây nhất",
    "top_5_most_recent_page_visits": "Top 5 trang truy cập gần đây nhất",
    "password_policy": "Chính sách mật khẩu",
    "password_policy_description": "Mật khẩu của bạn phải nhiều hơn hoặc bằng 8 ký tự.",
    "current_password": "Mật khẩu hiện tại",
    "new_password_not_match": "Mật khẩu mới và xác nhận mật khẩu không trùng khớp",
    "save_successfully": "Các thay đổi đã được lưu thành công.",
    "code_invalid": "Mã không hợp lệ",
    "before_we_begin": "Trước khi chúng ta bắt đầu",
    "before_we_begin_description": "Tài khoản của tôi có thể bị InsightzClub cấm vĩnh viễn nếu tôi vi phạm bất kỳ quy tắc nào dưới đây: <br><ul><li> Tạo nhiều tài khoản InsightzClub </li><li> Gửi dữ liệu không thuộc về tôi </ li><li> Gửi dữ liệu giả mạo </li><li> Chia sẻ tài khoản với người khác </li></ul>",
    "rules_mentioned": "và các quy tắc được đề cập ở trên",
    "cashout_bank_not_available_reasons": "Tính năng rút tiền về ngân hàng hiện không khả dụng cho bạn. Có thể do bất kỳ lý do nào dưới đây. <br><ul><li> Bạn là người dùng mới </li><li> Bạn không phải là người dùng cũ </li><li> Hiện có quá nhiều người dùng đang rút tiền mặt </li></ul><br> Vui lòng kiểm tra lại sau.",
    "available_on_mobile_app_only": "Chỉ có sẵn trên ứng dụng điện thoại InsightzClub",
    "app_only": "Chỉ ứng dụng",
    "view_data": "Xem dữ liệu",
    "link_data": "Liên kết dữ liệu",
    "timestamp": "Dấu thời gian",
    "cashout_to_paypal": "Chuyển tiền mặt tới PayPal",
    'paypal_account_details': 'Thông tin tài khoản Paypal',
    'provide_paypal_account_details': 'Vui lòng cung cấp thông tin tài khoản PayPal mà bạn muốn nhận tiền mặt.',
    'full_name_paypal_hint': 'Vui lòng cung cấp tên của bạn trên tài khoản PayPal',
    'paypal_email': 'Email của tài khoản PayPal',
    'paypal_email_hint': 'Vui lòng cung cấp địa chỉ email tài khoản PayPal của bạn',
    'paypal_payout_charges': 'Có bất kỳ khoản phí nào không?',
    'paypal_payout_charges_ans': 'Không. InsightzClub không tính phí người dùng bất kỳ khoản phí rút tiền nào. Bạn sẽ nhận được số tiền chính xác mà bạn rút ra.',
    'paypal_payout_currency': 'Loại tiền tôi sẽ nhận từ PayPal là gì?',
    'paypal_payout_currency_ans': 'USD(Đô la Mỹ).Đây là đơn vị tiền tệ duy nhất mà chúng tôi hỗ trợ hiện nay.',
    'paypal_countries_availability': 'PayPal có khả dụng tại quốc gia hoặc khu vực của tôi không?',
    'paypal_countries_availability_ans': "Để đảm bảo rằng quốc gia hoặc khu vực của bạn được hỗ trợ, vui lòng tìm hiểu thêm thêm tại  <a href = 'https: //www.paypal.com/webapps/mpp/country-worldwide? locale.x = en_US' target = '_ blank'> Tính khả dụng tại các quốc gia / khu vực của PayPal </a> ",
    'cashout_paypal_not_available_reasons': "Tính năng chuyển tiền sang PayPal hiện không khả dụng với bạn tại thời điểm này. Có thể là vì một trong số lý do sau đây. <br><ul><li> Bạn là người dùng mới </li><li> Bạn không phải là người dùng cũ </li><li> Hiện có quá nhiều người dùng đang rút tiền mặt </li><li>Rút tiền có thể không khả dụng ở quốc gia của bạn.</li></ul><br> Vui lòng kiểm tra lại sau.",
    'cashout_everteen_not_available_reasons': "Tính năng này chưa có sẵn ở quốc gia của bạn. <br> Vui lòng kiểm tra lại sau.",
    'confirm_account_details': "Xác nhận chi tiết tài khoản",
    'confirm_account_details_info': "Vui lòng xác nhận thông tin mà bạn đã nhập. Nếu có sai sót, hãy điều hướng trở lại bước trước đó để sửa lại thông tin.",
    'cancel': "Hủy bỏ",
    'receipt_invalid_reasons': "Rất tiếc, hệ thống không thể xác nhận biên nhận bạn tải lên. Có thể do bất kỳ lý do nào dưới đây. <br> <ul> <li> Biên lai không phải là biên lai hợp lệ </li> <li> Hình ảnh tải lên bị mờ </li> </ul> <br > Vui lòng liên hệ với bộ phận hỗ trợ khách hàng của chúng tôi để biết thêm thông tin.",
    'ireview_no_data': 'Xin lỗi, không có dữ liệu nào có sẵn cho hạng mục đã chọn',
    'next': 'Tiếp theo',
    'survey_submitted': 'Cảm ơn bạn đã hoàn thành khảo sát này! Phản hồi của bạn đã được lưu. Bây giờ bạn có thể rời khỏi trang'
}