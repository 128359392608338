export default [
  {
    path: '*',
    redirect: {
      path: '/404',
      meta: { layout: "no-scroll", guest: true },
    }
  },
  {  
    path: '/',
    meta: { layout: "no-scroll" },
    name: 'Root',
    redirect: {
      name: 'PassiveData'
    }
  },
  {
    path: '/Login',
    name: 'Login',
    meta: { layout: "no-scroll", guest: true },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/LinkData',
    name: 'PassiveData',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    component: () => import('@/views/PassiveData/index.vue')
  },
  {
    path: '/LinkData/Show',
    name: 'ShowPassiveData',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    component: () => import('@/views/PassiveData/show.vue')
  },
  {
    path: '/LinkData/Show/Data',
    name: 'ShowPassiveDataData',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    component: () => import('@/views/PassiveData/data.vue')
  },
  {

    path: '/UserProfile/Show/Data',
    name: 'ShowUserProfileData',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    component: () => import('@/views/UserProfile/data.vue'),
  },
  {
    path: '/UserProfile',
    name: 'UserProfile',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    component: () => import('@/views/UserProfile/index.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: { layout: "no-scroll", guest: true },
    component: () => import('@/views/NotFound.vue')
  },
  {
    path: '/403',
    name: 'AccessDenied',
    meta: { layout: "no-scroll", guest: true },
    component: () => import('@/views/Deny.vue')
  },
  {
    path: '/500',
    name: 'ServerError',
    meta: { layout: "no-scroll", guest: true },
    component: () => import('@/views/Error.vue')
  },
  {
    path: '/redirect',
    name: 'Redirect',
    meta: { layout: "no-scroll", guest: true },
    component: () => import('@/views/Redirect/index.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    meta: { layout: "default", mobile: true, guest: true },
    component: () => import('@/views/Registration.vue')
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    meta: { layout: "default", mobile: true, guest: true },
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/ChangePassword.vue')
  },
  {
    path: '/ActivateAccount',
    name: 'ActivateAccount',
    meta: { layout: "default", mobile: true, guest: true },
    component: () => import('@/views/ActivateAccount.vue')
  },
  {
    path: '/BrandsPurchased',
    name: 'BrandsPurchased',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/BrandsPurchased/index.vue')
  },
  {
    path: '/Redeem',
    name: 'Redeem',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Redeem/index.vue')
  },
  {
    path: '/Redeem/Bank',
    name: 'RedeemBank',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Redeem/bank.vue')
  },
  {
    path: '/Redeem/Paypal',
    name: 'RedeemPaypal',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Redeem/paypal.vue')
  },
  {
    path: '/PlantTree',
    name: 'PlantTree',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Redeem/plantTree.vue')
  },
  {
    path: '/BankAccount',
    name: 'BankAccount',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/BankAccount/index.vue')
  },
  {
    path: '/Multimedia',
    name: 'Multimedia',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Multimedia/index.vue')
  },
  {
    path: '/Multimedia/View/:id',
    name: 'MultimediaView',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Multimedia/view.vue')
  },
  {
    path: '/AddTask',
    name: 'AddTask',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Task/add.vue')
  },
  {
    path: '/Demographics',
    name: 'Demographics',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Demographics/index.vue')
  },
  {
    path: '/Demographics/Additional',
    name: 'AdditionalDemographics',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Demographics/additionalDemographics.vue')
  },
  {
    path: '/DS/Demographics/',
    name: 'DsDemographics',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/Demographics/dataSharing.vue')
  },
  {
    path: '/PD/Connect/',
    name: 'PdConnect',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/PassiveData/connect.vue')
  },
  {
    path: '/PD/Spotify/',
    name: 'PdSpotify',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/PassiveData/spotify.vue')
  },
  {
    path: '/PD/Facebook/', // TODO: To rename the path when we use this
    name: 'PdFacebookLikes',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/PassiveData/facebookLikes.vue')
  },
  {
    path: '/PD/Facebook/Upload',
    name: 'PdFacebookUpload',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/PassiveData/facebookUpload.vue')
  },
  // {
  //   path: '/Dev',
  //   name: 'Dev',
  //   meta: { layout: "default", mobile: true },
  //   component: () => import('@/views/Dev.vue')
  // },
  {
    path: '/PD/Youtube/',
    name: 'PdYoutube',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/PassiveData/youtube.vue')
  },
  {
    path: '/PD/Twitch/',
    name: 'PdTwitch',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/PassiveData/twitch.vue')
  },
  {
    path: '/faq/general',
    name: 'Faqs',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/FAQ/generalFaq.vue')
  },
  {
    path: '/faq/mobileReferral',
    name: 'FaqMobileReferral',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/FAQ/mobileReferralFaq.vue')
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    meta: { layout: "default", mobile: true, guest: true },
    component: () => import('@/views/ContactUs.vue')
  },
  {
    path: '/ireview/index',
    name: 'ireviewIndex',
    meta: { layout: "no-scroll", mobile: true },
    component: () => import('@/views/iReview/index.vue')
  },

  {
    path: '/ireview/category',
    name: 'ireviewCategory',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/iReview/category.vue')
  },

  {
    path: '/ireview/search',
    name: 'ireviewSearch',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/iReview/search.vue')
  },

  {
    path: '/ireview/categories',
    name: 'ireviewCategories',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/iReview/categories.vue')
  },

  {
    path: '/ireview/brands',
    name: 'ireviewBrands',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/iReview/brandOption.vue')
  },

  {
    path: '/ireview/categoryOption',
    name: 'ireviewCategoryOption',
    meta: { layout: "default", mobile: true },
    component: () => import('@/views/iReview/categoryOption.vue')
  },


  {
    path: '/ireview/reviewInfo',
    name: 'ireviewInfo',
    meta: { layout: "no-scroll", mobile: true },
    component: () => import('@/views/iReview/reviewInfo.vue')
  },

  {
    path: '/ireview/startReview',
    name: 'startReview',
    meta: { layout: "default", mobile: true },   
    component: () => import('@/views/iReview/startReview.vue')
  },

  {
    path: '/ireview/success',
    name: 'reviewSuccess',
    meta: { layout: "default", mobile: true },   
    component: () => import('@/views/iReview/success.vue')
  },

  {
    path: '/ireview/reviews',
    name: 'reviews',
    meta: { layout: "default", mobile: true },   
    component: () => import('@/views/iReview/reviews.vue')

  },

  {
    path: '/ireview/review',
    name: 'review',
    meta: { layout: "default", mobile: true },   
    component: () => import('@/views/iReview/review.vue')
  },
  {
    path: "/userprofile/:id/questions",
    name: "userProfileQuestions",
    meta: { layout: "default", mobile: true },
    component: () => import("@/views/UserProfile/questions.vue"),
  },

  {
    path: '/ireview/landing',
    name: 'iReviewLanding',
    meta: { layout: "default", mobile: true },   
    component: () => import('@/views/iReview/landing.vue')
  },

  {
    path: '/ireview/upload',
    name: 'iReviewUpload',
    meta: { layout: "default", mobile: true },   
    component: () => import('@/views/iReview/upload.vue')
  },

  {
    path: '/survey/:id',
    name: 'SurveyIndex',
    meta: { layout: "default", mobile: true, guest: true },
    component: () => import('@/views/Survey/index.vue')
  },

  {
    path: '/registerWithGoogleOrFacebook',
    name: 'registerWithGoogleOrFacebook',
    meta: { layout: "default", mobile: true, guest: true },
    component: () => import('@/views/registerWithGoogleOrFacebook.vue')
  },

  // {
  //   path: '/community/whyJoin',
  //   name: 'communityWhyJoin',
  //   meta: { layout: "default", mobile: true },
  //   component: () => import('@/views/community/whyJoin.vue')
  // },

]