<template>
  <v-app-bar color="white" app fixed clipped-left>
    <v-app-bar-nav-icon v-if="!$store.state.showSideBar"
      @click="$store.state.showSideBar = !$store.state.showSideBar"
    />
    <v-toolbar-title>
      <img src="./../assets/images/logo.png" class="logo" @click="$router.push({ name: 'Root' })">
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-tranisition"
      v-if="!$store.state.showSideBar"
    >
      <template v-slot:activator="{ on }">
        <v-avatar size="48">
          <v-btn fab small dark v-on="on">
            <span class="white--text headline">{{ nameToInitials }}</span>
          </v-btn>
        </v-avatar>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in items"
          :to="!item.href ? { name: item.name } : item.href"
          :href="item.href"
          @click="item.click"
          ripple="ripple"
          rel="noopener"
          :key="index"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <img :src="this.$store.state.profilePictureUrl" class="logo" v-if="this.$store.state.profilePictureUrl">
  </v-app-bar>
</template>

<script>
export default {
  props: {
    renderSideBarToggle: Boolean,
    renderSearchBar: Boolean,
  },
  data() {
    return {
      items: [
        {
          icon: "fullscreen_exit",
          href: "#",
          title: "Logout",
          click: () => {
            window.getApp.$emit("APP_LOGOUT");
          }
        }
      ]
    }
  },
  computed: {
    nameToInitials() {
      let name = this.$store.state.userProfile.name ? this.$store.state.userProfile.name : "";
      return name.split(" ").map(x => x.charAt(0)).join("").substr(0, 2).toUpperCase();
    }
  }
};
</script>
