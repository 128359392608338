export default {
    'demographic_info': '个人资料',
    'provide_demographic': '请在此处提供您的个人资料。',
    'gender': '性别',
    'date_of_birth': '出生日期',
    'marital_status': '婚姻状况',
    'country': '国家',
    'by_clicking': '通过单击',
    'continue': '继续',
    'read_and_agree': '您已经阅读并同意 InsightzClub ',
    'privacy_policy': '隐私政策',
    'login_information': '登录信息',
    'provide_login_info': '请在此处提供您的登录信息。',
    'name': '名称',
    'email': '电子邮件',
    'country_code': '国家/地区代码',
    'mobile_number': '手机号码',
    'password': 'Password',
    'confirm_password': '确认密码',
    'referral_code': '推荐代码',
    'previous': '上一个',
    'verification': '验证',
    'provide_otp': '请提供已经发送到您注册的手机号码和电子邮件的OTP。',
    'otp': 'OTP',
    'confirmation': '确认',
    'successful_registration': '您已经成功注册。请单击顶部的“返回”按钮。',
    'email_valid': '电子邮件必须有效',
    'confirmation_password': '需要确认密码。',
    'confirmation_password_mismatch': '确认密码与密码不匹配。',
    'six_characters': '六个字符的代码',
    'male': '男',
    'female': '女',
    'single': '单身',
    'married': '已婚',
    'divorced': '离婚',
    'min': '最低',
    'max': '最多',
    'characters': '个字符',
    'mobile_number_cannot': '手机号码不能以',
    'otp_not_match': 'OTP不匹配。',
    'enter_valid_email': '请输入一个有效的电子邮件',
    'account_details': '帐户资料',
    'provide_account_details': '请在此处提供您的帐户资料。',
    'new_password': '新密码',
    'provide_password': '请提供您的新密码以及生成的OTP。',
    'confirm_new_password': '确认新密码',
    'successful_password_change': '您已成功更改密码。请单击“返回”按钮以使用新凭据登录。',
    'validate_details_failed': '我们无法验证您的帐户资料。',
    'full_name': '全名',
    'full_name_bank_account_hint': '请输入银行帐户持有人的确切全名',
    'bank_name': '银行名称',
    'account_number': '帐号',
    'update': '更新',
    'frequently_asked_questions': '常见问题(FAQ)',
    'terms_conditions': '条款和条件',
    'min_req_redemption': '兑换要求的最低限额是多少？',
    'min_req_redemption_ans': '执行兑换所需的最低积分是',
    'points_currency_ratio': '货币兑换率是多少点？',
    'points_currency_ratio_ans': '点数 =',
    'reward_expect': '我什么时候可以期望得到回报？',
    'reward_expect_ans': '截止时间是每个月的月底。您的归档付款将在每月的下一个15日进行处理。如果每月的15号是周末或公共假日,则奖励将在下一个工作日处理。',
    'contact_payout': '如果有任何疑问,我应如何联系付款团队？',
    'contact_payout_ans': '您可以通过以下电子邮件地址与 InsightzClub 支出团队联系: ask@insightzclub.com。',
    'contact_payout_no_country': '我们不支持在您的国家/地区兑现. 您可以通过以下电子邮件地址与 InsightzClub 支出团队联系: ask@insightzclub.com。',
    'close': '关闭',
    'bank_account_details': '银行帐户资料',
    'before_processing': '在进行处理之前, 请先检查 InsightzClub 的 Cashout',
    'no_cashout_info': '抱歉, 您注册的国家/地区没有提款信息。请稍后再检查',
    'points_to_redeem_min': '对不起，您至少需要 {0} 兑换的积分。达到最低要求后,您就可以兑换积分。',
    'provide_bank_account_details': '请提供您希望接收付款的银行帐户资料。',
    'choose_cashout_amount': '选择提款金额',
    'select_amount': '请选择您要兑现的金额。使用 + 或 - 按钮增加或减少金额。',
    'total_available_points': '您的总可用积分为',
    'points': '点数',
    'equivalent_to': '相等于',
    'back': '返回',
    'confirm_selections': '确认您的选择',
    'confirm_details': '请确认资料',
    'points_to_redeem': '积分兑换',
    'cashout_amount': '现金余额',
    'you_have_read': '您已阅读并同意 InsightzClub 的 Cashout',
    'confirm': '确认',
    'thank_you': '谢谢！',
    'points_confirmation_email': '您已成功兑换 {0} 点数。确认电子邮件将很快发送出去',
    'min_points_not_met': '未达到最低分数',
    'exceeded_points': '超出可用积分',
    'earn_points_by_watching_videos': '您可以通过观看视频来赚取积分，并回答一些与视频有关的正确问题。',
    'provide_task_id': '提供任务ID',
    'add_task': '要添加任务，请插入提供的任务 ID。任务 ID 是字母和数字字符的组合。',
    'successfully_added_task': '您已成功添加任务。如果任务尚未开始，则此任务不可见。',
    'no_video_available': '抱歉，当前没有可供您观看的视频。 请稍后检查。',
    'watch': '观看',
    'for_video_watched': '对于您刚刚观看的视频。',
    'you_have_earned': '您已经获得了',
    'account_already_activated': '看来您的帐户已被激活',
    'successful_activated': '您已成功激活您的帐户。 请点击顶部的“返回”按钮。',
    'trying_to': '正在尝试',
    'here': '这儿',
    'activate_account': '激活帐户',
    'forgot_password': '忘记密码',
    'click': '请点击',
    'successfully_updated': '成功更新',
    'additional_demographic': '详细个人资料',
    'no_info': '目前没有资料或信息可提供。',
    'loading': '正在加载...',
    'topArtists': '听得最多的艺术家',
    'topTracks': '听得最多的单曲',
    'take_again': '再來一次',
    'start': '開始',
    'completed': '已完成',
    'required': '必要',
    'have_referral_code': '我有推荐代码或项目代码',
    "deactivated_account": "帐户已被停用。 请与我们联系",
    "inactive_account": "帐户尚未激活。 请激活您的帐户",
    "user_not_found": "找不到用户。 请注册",
    "user_details_mismatch": "您传递的信息不匹配。 请验证！",
    "account_pending_reset_password": "帐户待处理的重设密码。请重设密码",
    "brands_purchased_not_completed": "您尚未完成任务，因此无法检索您购买的品牌的商品。<br><br>请立即完成任务。",
    "submit_brands_purchased": "提交购买的品牌",
    "what_is_data_collected" : "什么是数据收集?",
    "what_is_data_collected_answer": "被动收集是您的位置数据，技术数据 (如：您的设备，设备名称，操作系统等) 和设备上安装的应用程序。 任何其他数据都是您通过填写调查表格或其他的参加活动所得来的数据。",
    "purpose_of_data": "收集这些数据的目的是什么?",
    "purpose_of_data_answer": "我们收集这些数据仅为了用于市场研究目的。那些您经常使用的各种品牌通过该研究将会更了解您使用该产品后的体验和使用模式，并将改进他们的产品和/或服务。我们仅汇报您的数据，这意味着你的数据将和其他参与者的数据将被合并在一起。因此，单个人的结论是不可能发生的或汇报的。",
    "what_are_rewards": "有什么奖励?",
    "what_are_rewards_answer": "只要您将这应用程序安装在您的设备上，您就会收到奖励 (现金和/或非现金)。您将可以通过参与任务或填调查表格获得额外奖励。更多有关奖励的详细信息可以到首页按'奖励历史记录'。",
    "can_see_own_data": "我能看到自己的数据吗?",
    "can_see_own_data_answer": "是的，从用户仪表板中，您可以看到所有关于您的数据。",
    "privacy_taken_care": "我的在线隐私如何得到照顾?",
    "privacy_taken_care_answer": "所有收集到的信息都是匿名的。这意味着任何个人信息或识别单个小组成员的方式都将被删除。这些数据与其他小组成员合并，只汇总报告。电子邮件和电话号码没有关联报告的数据，仅用于初始应用程序安装和提供奖励。",
    "data_protection_taken_care": "如何处理数据保护和安全问题?",
    "data_protection_taken_care_answer": "收集的所有数据均符合个人数据保护法 (PDPA)，并符合GDPR。",
    "who_to_contact": "如果我有任何疑问，可以联系谁?",
    "who_to_contact_answer": "如果您对该任务有任何疑问，可以发送电子邮件至 <a href='mailto: askus@insightzclub.com'>askus@insightzclub.com</a>或通过<a href='https://wa.me/60320359220'> WhatsApp（+ 603-2035-9220）</a>与我们聊天。",
    "refer_and_earn": '推荐并赚取点数',
    "refer_and_earn_point_1": "1. 立即共享下引用文本到您的网络（通过WhatsApp，SMS，PM，Facebook等）。",
    "refer_and_earn_point_2": "2. 根据您的推荐，如果您安装了任何网络，则每安装一个人，您将获得50点。",
    "share_and_earn": "分享和赚取点数",
    "share_and_earn_point_1": "1. 将'立即共享'下的文本共享到您的社交媒体或专业网络（Facebook，Instagram，LinkedIn，Twitter）。 ",
    "share_and_earn_point_2": "2. 将帖子的公开程度设置为公开。 ",
    "share_and_earn_point_3": "3. 通过在“提交证明”中上传来提供分享的证明，您将获得点数。 ",
    "share_and_earn_point_4": "4. 您将在每个平台上的每次共享赚取25点。 ",
    "engage_and_earn": "参与并赚取点数",
    "engage_and_earn_point_1": "1. 将'立即共享'下的文本共享到您的社交媒体或专业网络（Facebook，Instagram，LinkedIn，Twitter）。 ",
    "engage_and_earn_point_2": "2. 将帖子公开。 ",
    "engage_and_earn_point_3": "3. 您对帖子的参与度（喜欢，分享，评论）越多，您将获得越多的点数。",
    "engage_and_earn_point_4": "4. 您在每個帖子上的參與度將獲得1點，而在每個平台上的分享將獲得25點。 ",
    "engage_and_earn_point_5": "5. 通过上传'提交证明'来提供分享的证明，并获得点数。 ",
    "user_profile": "用户个人资料",
    "logout": "退出",
    "task_id": "Task ID",
    "min_characters": "至少 {1} 字符",
    "max_characters": "最多 {1} 字符",
    "available_points": "可用积分",
    "trees_planted": "你种的树",
    "plant_tree": "种树",
    "cashout_to_bank": "兑现到银行",
    "evertreen_intro": "您可以通过我们的合作伙伴Evertreen兑换积分以种植树木。",
    "select_a_tree": "选择一棵树",
    "read_more": "阅读更多",
    "plant_this": "种植这个",
    "language": "多语言",
    "login": "登录",
    "login_with_qr": "使用QR登录",
    "login_with_otp": "使用OTP登录",
    "scan_to_login_insightzclub": "扫描以登录到InsightzClub",
    "scan_to_login_insightzclub_description": "请从您的InsightzClub移动应用中扫描QR码。",
    "unable_to_scan_qr": "无法扫描QR码? ",
    "upload_file": "上傳文件",
    "uploaded_and_task_completed": "您已成功上传并完成任务。",
    "file_exceeded_limit": "上载的文件超过了最大文件大小{0} MB。您的文件大小为{1} MB。",
    "browser_history": "浏览历史记录",
    "url": "網址",
    "visited_at": "访问",
    "actions": "行动",
    "title": "标题",
    "delete": "刪除",
    "whatsApp": "WhatsApp",
    "top_5_most_recent_ads_and_show_watched": "最近观看的5个广告和节目",
    "top_5_most_recent_ads_seen": "最近观看的5个广告",
    "top_5_most_recent_ads_interacted": "最近有互动的5个广告",
    "top_5_most_recent_page_visits": "最近访问的5个页面",
    "password_policy": "密码策略",
    "password_policy_description": "您的密码必须大于或等于8个字符。",
    "current_password": "当前密码",
    "new_password_not_match": "新密码和确认密码字段不匹配",
    "save_successfully": "已成功保存",
    "code_invalid": "代码无效",
    "before_we_begin": "开始之前",
    "before_we_begin_description": "如果我违反以下任何规则，我的帐户可能会被 InsightzClub 永久封禁：<br><ul><li>创建多个 InsightClub 帐户</li><li>提交不属于我的数据</li><li>提交虚假数据</li><li>与个人共享账户</li></ul>",
    "rules_mentioned": "以及上面所说的规则",
    "cashout_bank_not_available_reasons": "您目前无法向银行提现。可能是由于以下任何原因。<br><ul><li>您是新用户</li><li>您不是回访用户</li><li>目前提现的用户太多</li></ul><br>请稍后再回来查看。",
    "available_on_mobile_app_only": "仅适用于 InsightzClub 移动应用程序",
    "app_only": "仅限应用程序",
    "view_data": "查看数据",
    "link_data": "链接数据",
    "timestamp": "时间戳",
    "cashout_to_paypal": "提现到贝宝",
    "paypal_account_details": "贝宝账户详情",
    "provide_paypal_account_details": "请提供您希望收到付款的 PayPal 帐户详细信息。",
    "full_name_paypal_hint": "请在您的 PayPal 帐户中提供您的姓名",
    "paypal_email": "贝宝电子邮件",
    "paypal_email_hint": "请提供您的 PayPal 帐户的电子邮件地址",
    "paypal_payout_charges": "是否有任何收费？",
    "paypal_payout_charges_ans": "不。InsightzClub 不会向用户收取任何提现费用。 您收到的确切金额是您提现的金额。",
    "paypal_payout_currency": "我从 PayPal 收到的货币是什么？",
    "paypal_payout_currency_ans": "USD（美元）。 这是我们目前唯一支持的货币。",
    "paypal_countries_availability": "PayPal 在我的国家或地区可用吗？",
    "paypal_countries_availability_ans": "为确保您的国家或地区得到支持，请了解更多关于<a href='https://www.paypal.com/webapps/mpp/country-worldwide?locale.x=en_US' target='_blank'>PayPal 国家/地区可用性</a>",
    "cashout_paypal_not_available_reasons": "您目前無法使用 PayPal 提現。 這可能是由於以下任一原因造成的。<br><ul><li>您是新用戶</li><li>您不是回訪用戶</li><li>用戶太多了 目前正在兌現</li><li>您所在的国家/地区可能不支持提现。</li></ul><br>請稍後再回來查看。",
    'cashout_everteen_not_available_reasons': "您所在的国家/地区尚不提供此功能。 <br>请稍后再回来查看。",
    "confirm_account_details": "确认账户详情",
    "confirm_account_details_info": "请确认您输入的信息。 如果有错误，请返回上一步以更正信息。",
    "cancel": "取消",
    'receipt_invalid_reasons': "抱歉，系统无法验证您上传的收据。可能是以下任一原因造成的。<br><ul><li>收据无效</li><li>上传的图片模糊</li></ul><br >请联系我们的客户支持以获取更多信息。",
    'ireview_no_data': '抱歉，没有可用于所选类别的数据',
    'next': '下一个',
    'survey_submitted': '感谢您完成本次调查！您的回复已保存。您现在可以离开页面'
}