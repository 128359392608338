import Vue from 'vue'
import Router from 'vue-router'
import paths from './paths'
import { DataService } from "@/services/DataService";
import store from "./../app.store.js"
import _i18n from "../language/config"

const dataService = new DataService();

// Ignore navigating to same path
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)

const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths
});

router.beforeEach((to, from, next) => {
  store.state.loader = true

  if (window.$cookies.get('platform') == "web" && to.matched.some(record => record.meta.mobile) && to.name != 'SurveyIndex') {
    next({ name: 'NotFound' })
  }

  if (to.query.language) {
    store.state.language.selected = to.query.language
    _i18n.locale = to.query.language
  }

  let email = to.query.email ? to.query.email.replace(' ', '+') : window.$cookies.get('email')
  let userId = to.query.userId ? to.query.userId : window.$cookies.get('userId')
  let token = null

  if (to.query.mtid && to.query.mfid && to.query.msid) {
    token = `Bearer ${to.query.mtid}.${to.query.mfid}.${to.query.msid}`
  } else if (window.$cookies.get('token')) {
    token = window.$cookies.get('token')
  } else if (to.query.token) {
    token = to.query.token
  }

  // adding a new logic for the SurveyIndex route, as it allows both guest and logged in users
  if (to.matched.some(record => record.meta.guest) && to.name == 'SurveyIndex') {
    if(token && email && userId && Object.keys(store.state.userProfile).length == 0) {
      dataService.whoAmI(token, { email, userId }).then(response => {
        store.state.loggedIn = true
        store.state.token = token
        store.state.userProfile = response.userProfile
        store.state.language.selected = response.userProfile.language
        _i18n.locale = response.userProfile.language
        window.$cookies.set('token', token)
        window.$cookies.set('userId', response.userProfile._id)
        window.$cookies.set('email', response.userProfile.email)

      next()
      }).catch(() => {
        next({ name: 'SurveyIndex' })
      })
    }
    else
      next();
  }
    else{

  if (to.matched.some(record => record.meta.guest)) {
    next();
  } else if (token && email && userId && Object.keys(store.state.userProfile).length == 0) {
    dataService.whoAmI(token, { email, userId }).then(response => {
      store.state.loggedIn = true
      store.state.token = token
      store.state.userProfile = response.userProfile
      store.state.language.selected = response.userProfile.language
      _i18n.locale = response.userProfile.language

      window.$cookies.set('token', token)
      window.$cookies.set('userId', response.userProfile._id)
      window.$cookies.set('email', response.userProfile.email)

      next()
    }).catch(() => {
      next({ name: 'NotFound' })
    })
  } else if (token && email && userId && Object.keys(store.state.userProfile).length > 0) {
    next()
  } else if (token == null && email == null && userId == null && !to.meta.mobile){
    next({ name: 'Login' })
  } else {
    next({ name: 'NotFound' })
  }
}
})

router.afterEach(() => {
  store.state.loader = false
});

export default router;