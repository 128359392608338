import Vue from 'vue'

export default {
  translate(stringOrObject) {
    let store = Vue.prototype.$store
    let selectedLanguage = store.state.language.selected ? store.state.language.selected : "en"
    let systemLanguage = store.state.language.system ? store.state.language.system : "en"
    if (stringOrObject[selectedLanguage])
      return stringOrObject[selectedLanguage]
    else if (stringOrObject[selectedLanguage.substring(0, 2)])
      return stringOrObject[selectedLanguage.substring(0, 2)]
    else if (stringOrObject[systemLanguage])
      return stringOrObject[systemLanguage]
    else if (stringOrObject[systemLanguage.substring(0, 2)])
      return stringOrObject[systemLanguage.substring(0, 2)]
    else if (stringOrObject["en"])
      return stringOrObject["en"]
    else if (stringOrObject)
      return stringOrObject
  },
  getEnText(stringOrObject) {
    if (stringOrObject)
      return (stringOrObject["en"]) ? stringOrObject["en"] : stringOrObject
    else
      return stringOrObject
  },
  setRadioButton: function (question, question_options, selected_option) {
    for (let i = 0; i < question_options.length; i++) {
      if (question_options[i].title == selected_option.title) {
        question.title = selected_option.title
        question.model = selected_option.key
        question_options[i].selected = true
      } else {
        question_options[i].selected = false
      }
    }
  },
}