import axios from 'axios'

var API_URL = process.env.VUE_APP_BACKEND_URL
let Util = require('./Util')

export class DataService {
  submitRegistration (registrationRequest) {
    const url = `${API_URL}/auth/v1/register`
    return axios.post(url, registrationRequest).then(response => response.data)
  }

  submitRegistrationToken (registrationRequest) {
    const url = `${API_URL}/auth/submitRegistrationToken`
    return axios.post(url, registrationRequest).then(response => response.data)
  }

  submitResetPasswordRequest (resetPasswordRequest) {
    const url = `${API_URL}/auth/submitForgetPasswordRequest`
    return axios.post(url, resetPasswordRequest).then(response => response.data)
  }

  resetPassword (resetPasswordRequest) {
    const url = `${API_URL}/auth/resetPassword`
    return axios.post(url, resetPasswordRequest).then(response => response.data)
  }

  changePassword(token, data) {
    const url = `${API_URL}/auth/updatePassword`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  activateAccountRequest (userProfileRequest) {
    const url = `${API_URL}/auth/activateAccountRequest`
    return axios.post(url, userProfileRequest).then(response => response.data)
  }

  updateMobileUser (token, data) {
    const url = `${API_URL}/user/update`

    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  getConfig (key) {
    const url = `${API_URL}/data/getConfig/?key=${key}`
    return axios.get(url).then(response => response.data)
  }

  whoAmI(token, data) {
    const url = `${API_URL}/user/verify`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  listMultimedia(token, data) {
    const url = `${API_URL}/user/multimedia/list`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  viewMultimedia(token, data) {
    const url = `${API_URL}/user/multimedia/view`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  saveMultimediaResponse(token, data) {
    const url = `${API_URL}/user/multimedia/save`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  rewardCashoutBank(token, data) {
    const url = `${API_URL}/user/reward/cashout/bank`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  rewardCashoutPaypal(token, data) {
    const url = `${API_URL}/user/reward/cashout/paypal`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  plantTreeReward(token, data) {
    const url = `${API_URL}/user/reward/plantTree`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  checkRewardInfo(token, data) {
    const url = `${API_URL}/user/reward/info`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  addProject(token, data) {
    const url = `${API_URL}/user/addProject`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  passiveDataProcessor(token, data) {
    const url = `${API_URL}/user/passive/process`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  getOTPLoginUser(data) {
    const url = `${API_URL}/dashboard/otp/connect`
    return axios.post(url, data).then(response => response.data)
  }

  getUserProfile(token, data) {
    const url = `${API_URL}/user/profiling/list`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  getUserProfileData(token, data) {
    const url = `${API_URL}/user/profiling/data`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  upload(token, data) {
    const url = `${API_URL}/dashboard/upload`
    return axios.post(url, data, Util.appendMobileUserUploadHeaders(token)).then(response => response.data)
  }

  listBrowserHistory(token, data) {
    const url = `${API_URL}/dashboard/browserHistory/list`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  deleteBrowserHistory(token, data) {
    const url = `${API_URL}/dashboard/browserHistory/delete`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  getRegions(country) {
    const url = `${API_URL}/auth/getRegions`
    return axios.post(url, { country }).then(response => response.data)
  }

  getUserProfileQuestions(token, data){
    const url = `${API_URL}/user/profiling/questions`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }

  submitUserProfileData(token, data){
    const url = `${API_URL}/user/profiling/submit`
    return axios.post(url, data, Util.appendMobileUserHeaders(token)).then(response => response.data)
  }
}
