export default {
  demographic_info: "Demographic Information",
  provide_demographic:
    "Please provide your basic demographic information here.",
  gender: "Gender",
  date_of_birth: "Date of Birth",
  marital_status: "Marital Status",
  country: "Country",
  by_clicking: "By clicking ",
  continue: "Continue",
  read_and_agree: "you have read and agree to InsightzClub",
  privacy_policy: "Privacy Policy",
  login_information: "Login Information",
  provide_login_info: "Please provide your login information here.",
  name: "Name",
  email: "Email",
  country_code: "Country Code",
  mobile_number: "Mobile Number",
  password: "Password",
  confirm_password: "Confirm Password",
  referral_code: "Referral Code",
  previous: "Previous",
  verification: "Verification",
  provide_otp: "Please provide the OTP that has been sent to your registered mobile number and email.",
  otp: "OTP",
  confirmation: "Confirmation",
  successful_registration: "You have successfully registered. Please click the BACK button at the top.",
  email_valid: "E-mail must be valid",
  confirmation_password: "Confirmation password required.",
  confirmation_password_mismatch: "Confirmation password does not match password.",
  six_characters: "6 Characters Code",
  male: "Male",
  female: "Female",
  single: "Single",
  married: "Married",
  divorced: "Divorced",
  min: "Min ",
  max: "Max ",
  characters: " characters",
  mobile_number_cannot: "Mobile number cannot start with ",
  otp_not_match: "OTP does not match.",
  enter_valid_email: "Please enter a valid E-mail",
  account_details: "Account Details",
  provide_account_details: "Please provide your account details here.",
  new_password: "New Password",
  provide_password: "Please provide your new password along with the generated OTP.",
  confirm_new_password: "Confirm New Password",
  successful_password_change: "You have successfully changed your password. Please click the BACK button to login with the new credentials.",
  validate_details_failed: "We are unable to validate your details.",
  full_name: "Full Name",
  full_name_bank_account_hint: "Please insert the exact full name for the bank account holder",
  bank_name: "Bank Name",
  account_number: "Account Number",
  update: "Update",
  frequently_asked_questions: "Frequently Asked Questions (FAQ) ",
  terms_conditions: "Terms & Conditions",
  min_req_redemption: "What is the minimum that is required for redemption?",
  min_req_redemption_ans: "The minimum points required to perform a redemption is ",
  points_currency_ratio: "What is the points to currency conversion ratio?",
  points_currency_ratio_ans: " points = ",
  reward_expect: "When shall I expect for the rewards?",
  reward_expect_ans: "The cut off time is the end of the month every month. Your payout on file will be processed on the next upcoming 15th of the month. If the 15th of the month is a weekend or public holiday, the reward will be processed on the following business day.",
  contact_payout: "How shall I contact the Payout Team for any enquiries?",
  contact_payout_ans: "You may reach out to InsightzClub Payout Team via the following E-mail address: askus@insightzclub.com.",
  close: "Close",
  contact_payout_no_country: "We do not support cash out for your country. You may reach out to InsightzClub Payout Team via the following E-mail address: askus@insightzclub.com.",
  bank_account_details: "Bank Account Details",
  before_processing: "Before processing, please checkout InsightzClub's Cashout",
  no_cashout_info: "Sorry, there is no cashout information for your registered country. Please check back later",
  points_to_redeem_min: "Sorry, you need a minimum of {0} points to redeem. You will be able to redeem your points once you have met the minimum.",
  provide_bank_account_details: "Please provide your bank account details that you would like to receive your payout.",
  choose_cashout_amount: "Choose Cashout Amount",
  select_amount: "Please select the amount that you would want to cashout. Use the + or - button to increase or decrease the amount.",
  total_available_points: "Your total available points are ",
  points: "Points",
  equivalent_to: "Equivalent to ",
  back: "Back",
  confirm_selections: "Confirm Your Selections",
  confirm_details: "Please confirm the details.",
  points_to_redeem: "Points to Redeem",
  cashout_amount: "Cashout Amount",
  you_have_read: " you have read and agree to InsightzClub's Cashout",
  confirm: "Confirm",
  thank_you: "Thank You!",
  points_confirmation_email: "You have successfully redeemed {0} points. A confirmation E-mail will be sent out shortly",
  min_points_not_met: "Minimum points not met",
  exceeded_points: "Exceeded available points or maximum points redemption reached",
  earn_points_by_watching_videos: "You can earn points by watching videos and answer some questions correctly pertaining to the videos.",
  provide_task_id: "Provide Task ID",
  add_task: "To add a task, please insert a Task ID provided. A Task ID is a combination of alphabetical and numerical characters.",
  successfully_added_task: "You have successfully added a Task. If the task has not started, the task may not be visible at the moment.",
  no_video_available: "Sorry, there is no video available for you to watch currently. Please check later.",
  watch: "Watch",
  for_video_watched: "for the video you just watched.",
  you_have_earned: "You have earned",
  account_already_activated: "It looks like your account is already activated",
  successful_activated: "You have successfully activated your account. Please click the BACK button at the top.",
  trying_to: "Trying to",
  here: "HERE",
  activate_account: "Activate Account",
  forgot_password: "Forgot Password",
  click: "Click",
  successfully_updated: "Successfully updated",
  additional_demographic: "Additional Demographic",
  no_info: "No information is available at this time.",
  loading: "Loading...",
  topArtists: "Top Artists",
  topTracks: "Top Tracks",
  take_again: "Take Again",
  start: "Start",
  completed: "Completed",
  required: "Required",
  have_referral_code: "I have a Referral Code or Project Code",
  deactivated_account: "Account has been deactivated. Please reach out to us for support",
  inactive_account: "Account has not been activated. Please activate your account",
  user_not_found: "User not found. Please sign up",
  user_details_mismatch: "The details you passed in do not match. Please verify!",
  account_pending_reset_password: "Account pending reset password. Please reset your password",
  brands_purchased_not_completed: "We are unable to retrieve your brands purchased categories as you have not completed the screener task.<br><br>Please complete the screener now.",
  submit_brands_purchased: "Submit Brands Purchased",
  what_is_data_collected: "What is the data collected?",
  what_is_data_collected_answer: "The information collected passively is your location data, the technographic data (your device, device name, OS, etc) and apps installed on your device. Any other info collected is as provided by you through a survey or other active means.",
  purpose_of_data: "What is the purpose for collecting this data?",
  purpose_of_data_answer: "We collect data for market research purposes only. The research helps brands and services that you used to understand your experience and usage patterns and to improve their product and/or service offerings to you. Your data will be reported on an aggregated level only. This means that your data and the data of the other participants will be combined together. Therefore, data will be reported on a high level basis and not at the individual level.",
  what_are_rewards: "What are the rewards?",
  what_are_rewards_answer: "You will receive rewards (cash and/or non-cash) as long as you keep the app installed on your device. You can earn extra rewards by participating in tasks or surveys. Please visit the rewards section to see more details on the rewards.",
  can_see_own_data: "Can I see my own data?",
  can_see_own_data_answer: "Yes, from the user dashboard you can see all the data parameters about you that are currently being known or accessed.",
  privacy_taken_care: "How is my privacy online taken care of?",
  privacy_taken_care_answer: "All information collected is annonymised. This means that that any personal information or means of identifying an individual panel member is removed. The data is combined with the other panellist and only reported in aggregate. Emails and phone numbers are not linked to the reported data and are only used for the purposes of the initial app installation and for delivering rewards.",
  data_protection_taken_care: "How is the data protection and security taken care of?",
  data_protection_taken_care_answer: "All the data collected is in compliance with personal Data Protection Act (PDPA) and also in line with GDPR.",
  who_to_contact: "Who to contact, if I have any questions?",
  who_to_contact_answer: "You can email to <a href='mailto: askus@insightzclub.com'>askus@insightzclub.com</a> or chat with us via <a href='https://wa.me/60320359220'>WhatsApp (+603-2035-9220)</a> if you have any questions regarding the project.",
  refer_and_earn: "Refer and Earn",
  refer_and_earn_point_1: "1. Share the text under refer now to your network (through WhatsApp, SMS, PM, Facebook, etc).",
  refer_and_earn_point_2: "2. When any of your network install based on your referral, you will earn 50 points for every person who installs.",
  share_and_earn: "Share and Earn",
  share_and_earn_point_1: "1. Share the text under Share Now to your social media or professional network (Facebook, Instagram, LinkedIn, Twitter).",
  share_and_earn_point_2: "2. Make the post public.",
  share_and_earn_point_3: "3. Provide proof of the share by uploading in Submit Proof and you will earn reward.",
  share_and_earn_point_4: "4. You will earn 25 points for each share on each platform.",
  engage_and_earn: "Engage and Earn",
  engage_and_earn_point_1: "1. Share the text under Share Now to your social media or professional network (Facebook, Instagram, LinkedIn, Twitter).",
  engage_and_earn_point_2: "2. Make the post public.",
  engage_and_earn_point_3: "3. The more engagement you have on your post (likes, shares, comments) the more you will earn.",
  engage_and_earn_point_4: "4. You will earn 1 point for each engagement on your post other than 25 points for the share on each platform.",
  engage_and_earn_point_5: "5. Provide proof of the share by uploading in Submit Proof and earn reward.",
  user_profile: "User Profile",
  logout: "Logout",
  task_id: "Task ID",
  min_characters: "Min {1} characters",
  max_characters: "Min {1} characters",
  available_points: "Available Points",
  trees_planted: "Trees Planted",
  plant_tree: "Plant Tree",
  cashout_to_bank: "Cashout To Bank",
  evertreen_intro: "You can redeem your points to plant a tree via our partner Evertreen.",
  select_a_tree: "Select A Tree",
  read_more: "Read More",
  plant_this: "Plant This",
  language: "Language",
  login: "Login",
  login_with_qr: "Login with QR",
  login_with_otp: "Login with OTP",
  scan_to_login_insightzclub: "Scan to log in to InsightzClub",
  scan_to_login_insightzclub_description: "Please scan the QR Code from your InsightzClub Mobile App.",
  unable_to_scan_qr: "Unable to scan the QR Code? ",
  upload_file: "Upload File",
  uploaded_and_task_completed: "You have uploaded and completed the task successfully.",
  file_exceeded_limit: "Uploaded file exceeds the maximum file size of {0} MB. Your file size is {1} MB.",
  browser_history: "Browser History",
  url: "URL",
  visited_at: "Visited At",
  actions: "Actions",
  title: "Title",
  delete: "Delete",
  whatsApp: "WhatsApp",
  top_5_most_recent_ads_and_show_watched: "Top 5 Most Recent Ads and Show Watched",
  top_5_most_recent_ads_seen: "Top 5 Most Recent Ads Seen",
  top_5_most_recent_ads_interacted: "Top 5 Most Recent Ads Interacted",
  top_5_most_recent_page_visits: "Top 5 Most Recent Page Visits",
  password_policy: "Password Policy",
  password_policy_description: "Your password must be greater or equal to 8 characters.",
  current_password: "Current Password",
  new_password_not_match: "New Password and Confirm Password fields do not match",
  save_successfully: "Changes saved successfully.",
  code_invalid: "Code is not valid",
  before_we_begin: "Before We Begin",
  before_we_begin_description: "My account may be banned by InsightzClub permanently if I violate any of the rules below:<br><ul><li>Create more than one InsightzClub account</li><li>Submit data that is not belong to me</li><li>Submit fake data</li><li>Share an account with someone else</li></ul>",
  rules_mentioned: "and the rules mentioned above",
  cashout_bank_not_available_reasons: "Cashout to bank is not available to you at the moment. It may be due to any of the following reasons below.<br><ul><li>You are a new user</li><li>You are not a returning user</li><li>Too many users are cashing out at the moment</li></ul><br>Please check back sometime later.",
  available_on_mobile_app_only: "Available on InsightzClub Mobile App only",
  app_only: "App Only",
  view_data: "View Data",
  link_data: "Link Data",
  timestamp: "Timestamp",
  cashout_to_paypal: "Cashout To PayPal",
  paypal_account_details: "PayPal Account Details",
  provide_paypal_account_details: "Please provide your PayPal account details that you would like to receive your payout.",
  full_name_paypal_hint: "Please provide your name on your PayPal account",
  paypal_email: "PayPal E-mail",
  paypal_email_hint: "Please provide your email address for your PayPal account",
  paypal_payout_charges: "Is there any charges occurred?",
  paypal_payout_charges_ans: "No. InsightzClub does not charge user any cashout charges. You are receiving the exact amount you cashout.",
  paypal_payout_currency: "What is the currency I am receiving from PayPal?",
  paypal_payout_currency_ans: "USD (US Dollar). This is the only currency we support at the moment.",
  paypal_countries_availability: "Is PayPal available in my country or region?",
  paypal_countries_availability_ans: "To ensure that your country or region is supported, please find out more about <a href='https://www.paypal.com/webapps/mpp/country-worldwide?locale.x=en_US' target='_blank'>PayPal Countries/Region Availability</a>",
  cashout_paypal_not_available_reasons: "Cashout to PayPal is not available to you at the moment. It may be due to any of the following reasons below.<br><ul><li>You are a new user</li><li>You are not a returning user</li><li>Too many users are cashing out at the moment</li><li>Cashout may not be available in your country.</li></ul><br>Please check back sometime later.",
  cashout_everteen_not_available_reasons: "This feature is not available in your country yet. <br>Please check back sometime later.",
  confirm_account_details: "Confirm Account Details",
  confirm_account_details_info: "Please confirm the information that you have entered. If there is a mistake, navigate back to the previous step to correct the information.",
  cancel: "Cancel",
  receipt_invalid_reasons: "Sorry the receipt you upload cannot be verified by the system. It may be due to any of the following reasons below.<br><ul><li>The receipt is not a valid one.</li><li>The image uploaded is blurry.</li></ul><br>Please contact our customer support for more information.",
  ireview_no_data: "Sorry there is no data available for the category choosen",
  next: "Next",
  survey_submitted: "Thank you for completing this survey! Your response has been saved. You may now leave the page",
};
