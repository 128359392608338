export default {
    'demographic_info': 'জনসংখ্যার তথ্য',
    'provide_demographic': 'দয়া করে এখানে আপনার প্রাথমিক জনসংখ্যার তথ্য সরবরাহ করুন',
    'gender': 'লিঙ্গ',
    'date_of_birth': 'জন্ম তারিখ',
    'marital_status': 'বৈবাহিক অবস্থা',
    'country': 'দেশ',
    'by_clicking': 'ক্লিক করে',
    'continue': 'চালিয়ে যান',
    'read_and_agree': 'আপনি ইনসাইটগক্লাবটি পড়েছেন এবং তাতে সম্মত হয়েছেন',
    'privacy_policy': 'গোপনীয়তা নীতি',
    'login_information': 'লগইন তথ্য',
    'provide_login_info': 'দয়া করে এখানে আপনার লগইন তথ্য সরবরাহ করুন।',
    'name': 'নাম',
    'email': 'ই-মেইল',
    'country_code': 'কান্ট্রি কোড',
    'mobile_number': 'মোবাইল নম্বর',
    'password': 'পাসওয়ার্ড',
    'confirm_password': 'পাসওয়ার্ড নিশ্চিত করুন ',
    'referral_code': 'রেফারেল কোড',
    'previous': 'পূর্ববর্ত',
    'verification': 'প্রতিপাদন',
    'provide_otp': 'দয়া করে আপনার নিবন্ধিত মোবাইল নম্বর এবং ইমেলটিতে প্রেরিত ওটিপি সরবরাহ করুন',
    'otp': 'ওটিপি',
    'confirmation': 'নিশ্চিতকরণ',
    'successful_registration': 'আপনি সফলভভাবে নিবন্ধন করেছেন. উপরের পিছনে ফিরে বোতামটি ক্লিক করুন। ',
    'email_valid': 'ই-মেল বৈধ হতে হবে',
    'confirmation_password': 'নিশ্চিতকরণে পাসওয়ার্ড প্রয়োজন',
    'confirmation_password_mismatch': 'নিশ্চিতকরণের পাসওয়ার্ড পাসওয়ার্ডের সাথে মেলে না',
    'six_characters': '৬ চরিত্রের কোড',
    'male': 'পুরুষ',
    'female': 'মহিলা',
    'single': 'একক',
    'married': 'বিবাহিত',
    'divorced': 'তালাকপ্রাপ্ত',
    'min': 'সর্বনিম্ন',
    'max': 'সর্বাধিক',
    'characters': 'চরিত্র',
    'mobile_number_cannot': 'মোবাইল নম্বর দিয়ে শুরু করা যায় না',
    'otp_not_match': 'ওটিপি মিলছে না।',
    'enter_valid_email': 'একটি বৈধ ইমেইল প্রবেশ করুন',
    'account_details': 'বিস্তারিত হিসাব',
    'provide_account_details': 'দয়া করে এখানে আপনার অ্যাকাউন্টের বিশদ সরবরাহ করুন',
    'new_password': 'নতুন পাসওয়ার্ড',
    'provide_password': 'দয়া করে উৎপন্ন ওটিপি সহ আপনার নতুন পাসওয়ার্ড সরবরাহ করুন',
    'confirm_new_password': "নতুন পাসওয়ার্ড' নিশ্চিত করুন",
    'successful_password_change': 'আপনি সফলভাবে আপনার পাসওয়ার্ড পরিবর্তন করেছেন. নতুন শংসাপত্রগুলির সাথে লগইন করতে দয়া করে ফিরে বোতামটি ক্লিক করুন , বৈধতা বিশদ ব্যর্থ হয়েছে, আমরা আপনার বিশদটি যাচাই করতে অক্ষম',
    'validate_details_failed': 'আমরা আপনার তথ্য নিশ্চিত করতে ব্যর্থ ',
    'full_name': 'পুরা নাম',
    'full_name_bank_account_hint': 'দয়া করে ব্যাংক হিসাবধারীর পুরা নামটি প্রবেশ করান',
    'bank_name': 'ব্যাংকের নাম',
    'account_number': 'হিসাব নাম্বার',
    'update': 'হালনাগাদ',
    'frequently_asked_questions': 'প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী (ফ্যাক)',
    'terms_conditions': 'শর্তাবলী',
    'min_req_redemption': 'মুক্তির জন্য প্রয়োজনীয় ন্যূনতম কী?',
    'min_req_redemption_ans': "মুক্তিপণ সম্পাদনের জন্য প্রয়োজনীয় সর্বনিম্ন পয়েন্টগুলি হ'ল",
    'points_currency_ratio': 'মুদ্রা রূপান্তর অনুপাতের পয়েন্টগুলি কী?',
    'points_currency_ratio_ans': 'পয়েন্ট =',
    'reward_expect': 'আমি কখন পুরষ্কারের আশা করব?',
    'reward_expect_ans': 'কাট অফ সময় প্রতি মাসের শেষ হয়। আপনার ফাইলের অর্থ প্রদানের আগামি মাসের 15 তারিখে প্রক্রিয়া করা হবে। যদি মাসের 15 তম সপ্তাহান্তে বা সার্বজনীন ছুটি হয় তবে নিম্নলিখিত ব্যবসায়িক দিনে পুরষ্কারটি প্রক্রিয়া করা হবে',
    'contact_payout': 'আমি কীভাবে কোনও অনুসন্ধানের জন্য অর্থ প্রদানের টিমের সাথে যোগাযোগ করব?',
    'contact_payout_ans': 'আপনি নিম্নলিখিত ই-মেইল ঠিকানার মাধ্যমে ইনসাইটসাইটক্লাব পেআউট টিমের কাছে পৌঁছাতে পারেন: askus@insightzclub.com',
    'contact_payout_no_country': 'আমরা আপনার দেশের জন্য নগদ আউট সমর্থন করি না।.আপনি নিম্নলিখিত ই-মেইল ঠিকানার মাধ্যমে ইনসাইটসাইটক্লাব পেআউট টিমের কাছে পৌঁছাতে পারেন: askus@insightzclub.com ',
    'close': 'বন্ধ',
    'bank_account_details': 'ব্যাংক হিসাবের বিবরণী',
    'before_processing': "প্রক্রিয়া করার আগে, দয়া করে ইনসাইটসাইটক্লাবের ক্যাশআউট' দেখুন",
    'no_cashout_info': 'দুঃখিত, আপনার নিবন্ধিত দেশের জন্য কোনও নগদ অর্থ নেই। দয়া করে পরে আবার পরীক্ষা করুন',
    'points_to_redeem_min': 'দুঃখিত, খালাস করার জন্য আপনার সর্বনিম্ন {0} পয়েন্ট দরকার। আপনি সর্বনিম্ন পূরণের পরে আপনার পয়েন্টগুলি খালাস করতে সক্ষম হবেন।',
    'provide_bank_account_details': "দয়া করে আপনার ব্যাংক হিসাবের বিশদ সরবরাহ করুন যা আপনি আপনার প্রদানের অর্থ গ্রহণ করতে চান'",
    'choose_cashout_amount': 'নগদ অর্থ নির্বাচন করুন',
    'select_amount': 'আপনি যে পরিমাণ অর্থ নগদ করতে চান তা দয়া করে নির্বাচন করুন। পরিমাণ বাড়াতে বা হ্রাস করতে + বা - বোতামটি ব্যবহার করুন।',
    'total_available_points': 'আপনার মোট উপলব্ধ পয়েন্টগুলি হল',
    'points': 'পয়েন্ট',
    'equivalent_to': 'সমতুল্য',
    'back': "পেছনে",
    'confirm_selections': 'আপনার নির্বাচন নিশ্চিত করুন',
    'confirm_details': 'দয়া করে বিশদটি নিশ্চিত করুন.',
    'points_to_redeem': 'মুক্ত করার পয়েন্টস',
    'cashout_amount': 'নগদ অর্থের পরিমাণ',
    'you_have_read': 'আপনি ইনসাইটসাক্লাবের ক্যাশআউটটি পড়েছেন এবং তাতে সম্মত হয়েছেন',
    'confirm': 'নিশ্চিত',
    'thank_you': 'ধন্যবাদ!',
    'points_confirmation_email': 'আপনি সাফল্যের সাথে {0} পয়েন্ট খালাস করেছেন। একটি নিশ্চয়তা ইমেল শীঘ্রই প্রেরণ করা হবে',
    'min_points_not_met': 'সর্বনিম্ন পয়েন্টগুলি পূরণ করা হয়নি',
    'exceeded_points': 'উপলব্ধ পয়েন্টগুলি ছাড়িয়ে গেছে',
    'earn_points_by_watching_videos': 'আপনি ভিডিও দেখে পয়েন্ট অর্জন করতে পারেন এবং ভিডিও সম্পর্কিত কিছু প্রশ্নের সঠিক উত্তর দিতে পারেন।',
    'provide_task_id': 'কার্য আইডি সরবরাহ করুন',
    'add_task': "কোনও কার্য যুক্ত করতে দয়া করে প্রদত্ত একটি 'কার্য আইডি প্রদান করুন। একটি টাস্ক আইডি বর্ণমালা এবং সংখ্যাসূচক অক্ষরের সংমিশ্রণ।",
    'successfully_added_task': "আপনি সফলভাবে একটি 'কার্য যুক্ত করেছেন। যদি কাজটি শুরু না হয় তবে এই মুহুর্তে 'কার্য দৃশ্যমান নাও হতে পারে।",
    'no_video_available': 'দুঃখিত, আপনার বর্তমানে দেখার জন্য কোনও ভিডিও উপলব্ধ নেই। পরে চেক করুন।',
    'watch': 'দেখা',
    'for_video_watched': "আপনি সবেমাত্র যে ভিডিওটি দেখেছেন তার জন্য 'দেখুন",
    'you_have_earned': 'তুমি অর্জন করেছ',
    'account_already_activated': 'দেখে মনে হচ্ছে আপনার অ্যাকাউন্টটি ইতিমধ্যে সক্রিয় হয়েছে',
    'successful_activated': 'আপনি সফলভাবে আপনার অ্যাকাউন্ট সক্রিয় করেছেন। উপরের পিছনে ফিরে বোতামটি ক্লিক করুন।',
    'trying_to': 'করার চেষ্টা',
    'here': 'এখানে',
    'activate_account': 'অ্যাকাউন্ট সক্রিয়',
    'forgot_password': 'পাসওয়ার্ড ভুলে গেছেন',
    'click': 'ক্লিক',
    'successfully_updated': 'সফলভাবে আপডেট',
    'additional_demographic': 'অতিরিক্ত ডেমোগ্রাফিক',
    'no_info': 'এই মুহুর্তে কোনও তথ্য পাওয়া যায় না।',
    'loading': 'লোড হচ্ছে...',
    'topArtists': 'শীর্ষ শিল্পী',
    'topTracks': 'শীর্ষস্থানীয় ট্র্যাকস',
    'take_again': 'আবার নিন',
    'start': 'শুরু',
    'completed': 'সমাপ্ত',
    'required': 'প্রয়োজনীয়',
    'have_referral_code': 'আমার একটি রেফারেল কোড বা প্রকল্প কোড রয়েছে',
    "deactivated_account": "অ্যাকাউন্ট নিষ্ক্রিয় করা হয়েছে। সহায়তার জন্য আমাদের সাথে যোগাযোগ করুন ",
    "inactive_account": "অ্যাকাউন্ট সক্রিয় করা হয়নি। আপনার অ্যাকাউন্ট সক্রিয় করুন",
    "user_not_found": "ব্যবহারকারী পাওয়া যায় নি। সাইন আপ করুন",
    "user_details_mismatch": "আপনি যে বিবরণীতে উত্তীর্ণ হয়েছেন তা মিলছে না। যাচাই করুন!",
    "account_pending_reset_password": "অ্যাকাউন্টের মুলতুবি পুনরায় সেট করা পাসওয়ার্ড দয়া করে আপনার পাসওয়ার্ডটি পুনরায় সেট করুন",
    "brands_purchased_not_completed": "আপনি স্ক্রিনারের কাজটি শেষ না করায় আমরা আপনার ব্র্যান্ডের কেনা বিভাগগুলি পুনরুদ্ধার করতে অক্ষম।<br><br>এখনই স্ক্রিনারটি সম্পূর্ণ করুন।",
    "submit_brands_purchased": "কেনা ব্র্যান্ড জমা দিন",
    "what_is_data_collected": "তথ্য সংগ্রহ করা কী?",
    "what_is_data_collected_answer": "নিষ্ক্রিয়ভাবে সংগ্রহ করা তথ্য হল আপনার অবস্থানের ডেটা, প্রযুক্তিগত ডেটা (আপনার ডিভাইস, ডিভাইসের নাম, ওএস, ইত্যাদি) এবং আপনার ডিভাইসে ইনস্টল থাকা অ্যাপ্লিকেশনগুলি। সংগৃহীত অন্য যে কোনও তথ্য আপনার দ্বারা জরিপ বা অন্যান্য সক্রিয় মাধ্যমে সরবরাহ করা হয়েছে।",
    "purpose_of_data": "এই তথ্য সংগ্রহ করার উদ্দেশ্য কী?",
    "purpose_of_data_answer": "আমরা কেবল বাজার গবেষণার উদ্দেশ্যে ডেটা সংগ্রহ করি। গবেষণাটি আপনার ব্র্যান্ড এবং পরিষেবাদিগুলিকে সহায়তা করে যা আপনি নিজের অভিজ্ঞতা এবং ব্যবহারের ধরণগুলি বুঝতে এবং তাদের পণ্য এবং / অথবা আপনাকে পরিষেবা অফারগুলি উন্নত করতে ব্যবহার করেছেন। আপনার ডেটা কেবলমাত্র একত্রিত স্তরে রিপোর্ট করা হবে। এর অর্থ হল আপনার ডেটা এবং অন্যান্য অংশগ্রহণকারীদের ডেটা একত্রিত হবে। সুতরাং, ডেটা পৃথক স্তরে নয়, উচ্চ স্তরের ভিত্তিতে রিপোর্ট করা হবে।",
    "what_are_rewards": "পুরষ্কার গুলো কি?",
    "what_are_rewards_answer": "আপনি যতক্ষণ না আপনার ডিভাইসে অ্যাপটি ইনস্টল করেন ততক্ষণ আপনি পুরষ্কার (নগদ এবং / অথবা অ-নগদ) পাবেন। আপনি কার্য বা জরিপে অংশ নিয়ে অতিরিক্ত পুরষ্কার অর্জন করতে পারেন। পুরষ্কারগুলি সম্পর্কে আরও বিশদ দেখতে দয়া করে পুরষ্কার বিভাগে যান।",
    "can_see_own_data": "আমি কি আমার নিজস্ব তথ্য দেখতে পারি?",
    "can_see_own_data_answer": "হ্যাঁ, ব্যবহারকারীর ড্যাশবোর্ড থেকে আপনি আপনার সম্পর্কে সমস্ত তথ্য পরামিতি দেখতে পাবেন যা বর্তমানে জানা বা প্রবেশ করা হচ্ছে।",
    "privacy_taken_care": "অনলাইন কীভাবে আমার গোপনীয়তার যত্ন নেওয়া হয়?",
    "privacy_taken_care_answer": "সংগৃহীত সমস্ত তথ্য বেনামে রয়েছে। এর অর্থ হ'ল কোনও পৃথক প্যানেল সদস্য সনাক্ত করার কোনও ব্যক্তিগত তথ্য বা উপায়গুলি সরানো হয়েছে। অন্যান্য প্যানেলস্টের সাথে ডেটা একত্রিত হয় এবং কেবল সামগ্রিকভাবে রিপোর্ট করা হয়। ইমেল এবং ফোন নম্বরগুলি প্রতিবেদন করা ডেটার সাথে লিঙ্কযুক্ত নয় এবং কেবলমাত্র প্রাথমিক অ্যাপ্লিকেশন ইনস্টলেশন করার উদ্দেশ্যে এবং পুরষ্কার সরবরাহের জন্য ব্যবহৃত হয়।",
    "data_protection_taken_care": "কীভাবে তথ্য সুরক্ষা এবং সুরক্ষার যত্ন নেওয়া হচ্ছে?",
    "data_protection_taken_care_answer": "সংগৃহীত সমস্ত তথ্য ব্যক্তিগত তথ্য সুরক্ষা আইনের (পিডিপিএ) এবং জিডিপিআরের সাথে সঙ্গতিপূর্ণ ",
    "who_to_contact": "কার সাথে যোগাযোগ করতে হবে, যদি আমার কোন প্রশ্ন থাকে?",
    "who_to_contact_answer": "প্রকল্পটি সম্পর্কে আপনার যদি কোনও প্রশ্ন থাকে তবে আপনি <a href='mailto: askus@insightzclub.com'>askus@insightzclub.com</a> এ ইমেল করতে পারেন। বা <a href='https://wa.me/60320359220'> হোয়াটসঅ্যাপ (+603-2035-9220) </a> এর মাধ্যমে আমাদের সাথে চ্যাট করুন",
    "refer_and_earn": 'উল্লেখ করুন এবং উপার্জন করুন',
    "refer_and_earn_point_1": "১. আপনার নেটওয়ার্কে এখনই রেফারেন্সযুক্ত পাঠ্যগুলি ভাগ করুন (হোয়াটসঅ্যাপ, এসএমএস, পি এম, ফেসবুক ইত্যাদির মাধ্যমে)।",
    "refer_and_earn_point_2": "২. আপনার রেফারেলের উপর ভিত্তি করে আপনার কোনও নেটওয়ার্ক ইনস্টল করলে আপনি ইনস্টল করা প্রত্যেক ব্যক্তির জন্য 50 পয়েন্ট অর্জন করতে পারবেন।",
    "share_and_earn": "ভাগ করুন এবং উপার্জন করুন",
    "share_and_earn_point_1": "১. আপনার সামাজিক মিডিয়া বা পেশাদার নেটওয়ার্কে (ফেসবুক, ইনস্টাগ্রাম, লিংকডইন, টুইটার) এখনই ভাগ করুন নীচের পাঠ্যটি ভাগ করুন।",
    "share_and_earn_point_2": "২. পোস্টটি সর্বজনীন করুন।",
    "share_and_earn_point_3": "৩. সাবমিট প্রুফ এ আপলোড করে শেয়ারের প্রমাণ সরবরাহ করুন এবং আপনি পুরষ্কার অর্জন করবেন।",
    "share_and_earn_point_4": "৪. আপনি প্রতিটি প্ল্যাটফর্মের প্রতিটি ভাগের জন্য 25 পয়েন্ট উপার্জন করবেন।",
    "engage_and_earn": "চুক্তিবদ্ধ করান এবং উপার্জন করুন",
    "engage_and_earn_point_1": "১. আপনার সামাজিক মিডিয়া বা পেশাদার নেটওয়ার্কে (ফেসবুক, ইনস্টাগ্রাম, লিংকডইন, টুইটার) এখনই ভাগ করুন নীচের পাঠ্যটি ভাগ করুন।",
    "engage_and_earn_point_2": "২. পোস্টটি সর্বজনীন করুন।",
    "engage_and_earn_point_3": "৩. আপনার পোস্টে যত বেশি ব্যস্ততা থাকবে (পছন্দ, ভাগ, মন্তব্য) আপনি তত বেশি উপার্জন করবেন।",
    "engage_and_earn_point_4": "৪.আপনি প্রতিটি প্ল্যাটফর্মের শেয়ারের জন্য 25 পয়েন্ট ব্যতীত আপনার পোস্টে প্রতিটি ব্যস্ততার জন্য 1 পয়েন্ট উপার্জন করবেন।",
    "engage_and_earn_point_5": "৫. সাবমিট প্রুফ এ আপলোড করে পুরষ্কার অর্জনের মাধ্যমে শেয়ারটির প্রমাণ সরবরাহ করুন।",
    "user_profile": "ব্যবহারকারীর প্রোফাইল",
    "logout": "প্রস্থান",
    "task_id": "টাস্ক আইডি",
    "min_characters": "ন্যূনতম {1} অক্ষর",
    "max_characters": "সর্বোচ্চ {1} অক্ষর",
    "available_points": "প্রাপ্য পয়েন্ট",
    "trees_planted": "গাছ লাগানো",
    "plant_tree": "গাছ লাগান",
    "cashout_to_bank": "নগদ থেকে ব্যাংক",
    "evertreen_intro": "আমাদের অংশীদার এভারট্রিনের মাধ্যমে গাছ লাগানোর জন্য আপনি আপনার পয়েন্টগুলি খালাস করতে পারেন।",
    "select_a_tree": "একটি গাছ নির্বাচন করুন",
    "read_more": "আরও পড়ুন",
    "plant_this": "এটি উদ্ভিদ",
    "language": "ভাষা",
    "login": "প্রবেশ করুন",
    "login_with_qr": "কিউআর দিয়ে লগইন করুন",
    "login_with_otp": "ওটিপি দিয়ে লগইন করুন",
    "scan_to_login_insightzclub": "ইনসাইটসাইটক্লাবটিতে লগ ইন করতে স্ক্যান করুন",
    "scan_to_login_insightzclub_description": "আপনার ইনসাইটসাইটক্লাব মোবাইল অ্যাপ্লিকেশন থেকে কিউআর কোডটি স্ক্যান করুন।",
    "unable_to_scan_qr": "কিউআর কোডটি স্ক্যান করতে অক্ষম?",
    "upload_file": "ফাইল আপলোড করুন",
    "uploaded_and_task_completed": "আপনি সফলভাবে আপলোড এবং কাজটি শেষ করেছেন।",
    "file_exceeded_limit": "আপলোড করা ফাইলটি সর্বোচ্চ ফাইলের আকার {0} এমবি ছাড়িয়ে যায়। আপনার ফাইলের আকার {1} এমবি।",
    "browser_history": "ব্রাউজারের ইতিহাস",
    "url": "ইউআরএল",
    "visited_at": "দেখা হয়েছে",
    "actions": "কার্যকলাপ",
    "title": "শিরোনাম",
    "delete": "মুছে ফেলা",
    "whatsApp": "WhatsApp",
    "top_5_most_recent_ads_and_show_watched": "সাম্প্রতিক শীর্ষ ৫ টি বিজ্ঞাপন শো দেখেছনে ",
    "top_5_most_recent_ads_seen": "সাম্প্রতিক শীর্ষ ৫ টি বিজ্ঞাপন দেখা ",
    "top_5_most_recent_ads_interacted": "সাম্প্রতিক শীর্ষ ৫ টি বিজ্ঞাপনের সাথে যোগাযোগ ",
    "top_5_most_recent_page_visits": "সাম্প্রতিক শীর্ষ ৫ টি বিজ্ঞাপনের পেজ ভিসিট ",
    "password_policy": "পাসওয়ার্ড নীতি",
    "password_policy_description": "আপনার পাসওয়ার্ডটি ৮ টি অক্ষরের চেয়ে বড় বা সমান হতে হবে।",
    "current_password": "বর্তমান পাসওয়ার্ড",
    "new_password_not_match": "নতুন পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড ক্ষেত্রগুলি মেলে না",
    "save_successfully": "পরিবর্তনগুলি সফলভাবে সংরক্ষণ করা হয়েছে.",
    "code_invalid": "কোড অবৈধ",
    "before_we_begin": "আমরা শুরু করার আগে",
    "before_we_begin_description": "আমি নীচের কোনও বিধি লঙ্ঘন করলে ইনসাইটসাইটক্লাব স্থায়ীভাবে আমার অ্যাকাউন্ট নিষিদ্ধ হতে পারে:<br><ul><li>একাধিক ইনসাইটসাইটক্লাব অ্যাকাউন্ট তৈরি করুন</li><li>আমার সাথে সম্পর্কিত নয় এমন ডেটা জমা দিন</li><li>জাল তথ্য জমা দিন</li><li>অন্য কারও সাথে অ্যাকাউন্ট ভাগ করুন</li></ul>",
    "rules_mentioned": "এবং উপরে উল্লিখিত বিধিগুলি",
    "cashout_bank_not_available_reasons": "এই মুহুর্তে আপনার কাছে নগদে নগদ অর্থ উপলব্ধ নেই। এটি নীচের নীচের যে কোনও কারণে হতে পারে।<br><ul><li>আপনি একজন নতুন ব্যবহারকারী</li><li>আপনি কোনও রিটার্নিং ব্যবহারকারী নন</li><li>এই মুহুর্তে প্রচুর ব্যবহারকারী নগদ আদায় করছেন</li></ul><br>কিছুক্ষণ পরে আবার চেক করুন।",
    "available_on_mobile_app_only": "শুধুমাত্র ইনসাইটজ ক্লাব মোবাইল অ্যাপে উপলব্ধ",
    "app_only": "শুধুমাত্র অ্যাপ",
    "view_data": "ডেটা দেখুন",
    "link_data": "লিঙ্ক ডেটা",
    "timestamp": "টাইমস্ট্যাম্প",
    "cashout_to_paypal": "Cashout To PayPal",
    'paypal_account_details': 'PayPal Account Details',
    'provide_paypal_account_details': 'Please provide your PayPal account details that you would like to receive your payout.',
    'full_name_paypal_hint': 'Please provide your name on your PayPal account',
    'paypal_email': 'PayPal E-mail',
    'paypal_email_hint': 'Please provide your email address for your PayPal account',
    'paypal_payout_charges': 'Is there any charges occurred?',
    'paypal_payout_charges_ans': 'No. InsightzClub does not charge user any cashout charges. You are receiving the exact amount you cashout.',
    'paypal_payout_currency': 'What is the currency I am receiving from PayPal?',
    'paypal_payout_currency_ans': 'USD (US Dollar). This is the only currency we support at the moment.',
    'paypal_countries_availability': 'Is PayPal available in my country or region?',
    'paypal_countries_availability_ans': "To ensure that your country or region is supported, please find out more about <a href='https://www.paypal.com/webapps/mpp/country-worldwide?locale.x=en_US' target='_blank'>PayPal Countries/Region Availability</a>",
    'cashout_paypal_not_available_reasons': "Cashout to PayPal is not available to you at the moment. It may be due to any of the following reasons below.<br><ul><li>You are a new user</li><li>You are not a returning user</li><li>Too many users are cashing out at the moment</li><li>Cashout may not be available in your country.</li></ul><br>Please check back sometime later.",
    'cashout_everteen_not_available_reasons': "এই বৈশিষ্ট্যটি এখনও আপনার দেশে উপলব্ধ নয়৷ <br>অনুগ্রহ করে কিছুক্ষণ পরে আবার চেক করুন।",
    'confirm_account_details': "Confirm Account Details",
    'confirm_account_details_info': "Please confirm the information that you have entered. If there is a mistake, navigate back to the previous step to correct the information.",
    'cancel': "Cancel",
    'receipt_invalid_reasons': "দুঃখিত আপনার আপলোড করা রসিদ সিস্টেম দ্বারা যাচাই করা যাবে না। এটি নীচের যে কোনও কারণে হতে পারে।<br><ul><li>রসিদটি বৈধ নয়</li><li>আপলোড করা ছবিটি ঝাপসা</li></ul><br > আরো তথ্যের জন্য আমাদের গ্রাহক সহায়তার সাথে যোগাযোগ করুন।",
    'ireview_no_data': 'দুঃখিত নির্বাচিত বিভাগ জন্য কোন তথ্য উপলব্ধ নেই',
    'next': 'পরবর্তী',
    'survey_submitted': 'এই সমীক্ষাটি সম্পূর্ণ করার জন্য আপনাকে ধন্যবাদ! আপনার প্রতিক্রিয়া সংরক্ষণ করা হয়েছে. আপনি এখন পৃষ্ঠা ছেড়ে যেতে পারেন'
}