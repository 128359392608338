<template>
  <v-overlay :value="this.$store.state.loader">
    <v-card-text class="text-center">
      <v-progress-circular indeterminate color="white" class="mb-3" />
      <p class="title">{{ $t('loading') }}</p>
    </v-card-text>
  </v-overlay>
</template>

<script>
export default {
  name: "loader"
};
</script>