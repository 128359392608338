<template>
  <v-app id="app">
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import VeeValidate from "vee-validate";
import Vue from "vue";
import sassStyles from '@/assets/styles/main.scss'
import AppEvents from  '@/config/event';
import VueYouTube from 'vue-youtube'
import languageProcessor from "./language/processor.js"
import commonProcessor from "./components/processor.js"
import _i18n from "./language/config.js"
import store from './app.store'
import moment from 'moment'

Vue.use(VueYouTube)
Vue.use(VeeValidate);

Vue.config.productionTip = false
Vue.prototype.$languageProcessor = languageProcessor;
Vue.prototype.$commonProcessor = commonProcessor;
Vue.prototype._i18n = _i18n;
Vue.prototype.$store = store
Vue.prototype.moment = moment

const default_layout = "default";

export default {
  components: {
    sassStyles,
    AppEvents,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
  created () {
    AppEvents.forEach(item => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;
  },
}
</script>