<template>
  <v-app id="app">
    <SnackBar />
    <Loader />
    <Dialog />
    <Navbar v-if="$route.meta.renderNavBar" />
    <Sidebar v-if="$route.meta.renderSideBar" />

    <v-container fluid>
      <v-main>
        <router-view/>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import Navbar from './../components/Navbar';
import Sidebar from './../components/Sidebar';
import SnackBar from "./../components/SnackBar.vue"
import Loader from "@/components/Loader";
import Dialog from './../components/Dialog'

export default {
  components:  {
    Navbar,
    Sidebar,
    Loader,
    SnackBar,
    Dialog
  }
}
</script>