module.exports = {
    appendMobileUserHeaders(token) {
        return {
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `${token}`
            }
        }
    },
    appendMobileUserUploadHeaders(token) {
        return {
            "headers": {
                "Content-type": "multipart/form-data",
                "Authorization": `${token}`
            }
        }
    },
    setupHeaders() {
        return {
            'headers': {
                'Authorization': `Api-Key${process.env.VUE_APP_AUTH_API_PASSKEY}`,
                'passcode': process.env.VUE_APP_AUTH_API_PASSCODE
            }
        }
    }
}