export default {
    'demographic_info': 'Maklumat Demografi',
    'allow_demographic': 'Sila berikan maklumat demografi asas anda di sini.',
    'gender': 'Jantina',
    'date_of_birth': 'Tarikh Lahir',
    'marital_status': 'Status Perkahwinan',
    'country': 'Negara',
    'by_clicking': 'Dengan mengklik ',
    'continue': 'Teruskan',
    'read_and_agree': 'anda telah membaca dan bersetuju dengan InsightzClub',
    'privacy_policy': 'Dasar Privasi',
    'login_information': 'Maklumat Masuk',
    'allow_login_info': 'Sila berikan maklumat log masuk anda di sini.',
    'name': 'Nama',
    'email': 'E-mel',
    'country_code': 'Country Code',
    'mobile_number': 'Nombor Mudah Alih',
    'password': 'Kata Laluan',
    'confirm_password': 'Sahkan Kata Laluan',
    'referral_code': 'Rujukan Code',
    'previous': 'Sebelumnya',
    'verification': 'Pengesahan (Bahagian 1)',
    'provide_otp': 'Sila berikan OTP yang telah dihantar ke nombor dan e-mel mudah alih anda yang telah didaftarkan.',
    'otp': 'OTP',
    'confirmation': 'Pengesahan (Bahagian 2)',
    'successful_registration': 'Anda telah berjaya mendaftar. Sila klik butang KEMBALI di bahagian atas.',
    'email_valid': 'E-mel mesti sah',
    'confirmation_password': 'Kata laluan pengesahan diperlukan.',
    'confirmation_password_mismatch': 'Kata laluan pengesahan tidak sepadan dengan kata laluan.',
    'six_characters': 'Kod 6 Digit',
    'male': 'Lelaki',
    'female': 'Perempuan',
    'single': 'Single',
    'married': 'Berkahwin',
    'divorced': 'Bercerai',
    'min': 'Min',
    'max': 'Max',
    'characters': 'watak',
    'mobile_number_cannot': 'Nombor telefon bimbit tidak boleh bermula dengan',
    'otp_not_match': 'OTP tidak sepadan.',
    'enter_valid_email': 'Sila masukkan E-mel yang sah',
    'account_details': 'Butiran Akaun',
    'allow_account_details': 'Sila berikan detial akaun anda di sini.',
    'new_password': 'Kata Laluan Baru',
    'allow_password': 'Sila berikan kata laluan baru anda bersama dengan OTP yang dihasilkan.',
    'confirm_new_password': 'Sahkan Kata Laluan Baru',
    'berjayal_password_change': 'Anda berjaya menukar kata laluan anda. Sila klik butang BACK untuk log masuk dengan kelayakan baru. ',
    'validate_details_failed': 'Kami tidak dapat mengesahkan butiran anda.',
    'full_name': 'Nama Penuh',
    'full_name_bank_account_hint': 'Sila masukkan nama lengkap yang tepat untuk pemegang akaun bank',
    'bank_name': 'Nama Bank',
    'account_number': 'Nombor Akaun',
    'update': 'Kemas kini',
    'frequently_asked_questions': 'Soalan Lazim (FAQ)',
    'terms_conditions': 'Terma & Syarat',
    'min_req_redemption': 'Berapa minimum yang diperlukan untuk penebusan?',
    'min_req_redemption_ans': 'Poin minimum yang diperlukan untuk melakukan penebusan adalah',
    'points_currency_ratio': 'Berapakah nisbah mata penukaran mata wang?',
    'points_currency_ratio_ans': 'poin =',
    'reward_expect': 'Bilakah saya akan mengharapkan ganjaran?',
    'reward_expect_ans': 'Waktu pemotongan adalah akhir bulan setiap bulan. Pembayaran anda dalam fail akan diproses pada 15 bulan berikutnya. Sekiranya tarikh 15 bulan itu adalah hujung minggu atau cuti umum, ganjaran akan diproses pada hari perniagaan berikutnya. ',
    'contact_payout': 'Bagaimana saya boleh menghubungi Pasukan Pembayaran untuk sebarang pertanyaan?',
    'contact_payout_ans': 'Anda boleh menghubungi Pasukan Pembayaran InsightzClub melalui alamat E-mel berikut: askus@insightzclub.com.',
    'contact_payout_no_country': 'Kami tidak menyokong pengeluaran wang untuk negara anda. Anda boleh menghubungi Pasukan Pembayaran InsightzClub melalui alamat E-mel berikut: askus@insightzclub.com.',
    'close': 'Tutup',
    'bank_account_details': 'Butiran Akaun Bank',
    'before_processing': 'Sebelum memproses, sila periksa InsightzClub\'s Cashout',
    'no_cashout_info': 'Maaf, tidak ada maklumat pembayaran tunai untuk negara berdaftar anda. Sila periksa lagi nanti ',
    'points_to_redeem_min': 'Maaf, anda memerlukan minimum {0} mata untuk ditebus. Anda akan dapat menebus mata anda setelah anda mencapai minimum. ',
    'allow_bank_account_details': 'Sila berikan butiran akaun bank anda bahawa anda ingin menerima pembayaran anda.',
    'select_cashout_amount': 'Pilih Jumlah Tunai',
    'select_amount': 'Sila pilih jumlah yang anda mahu tunai. Gunakan butang + atau - untuk menambah atau menurunkan jumlahnya. ',
    'total_available_points': 'Jumlah mata anda yang ada adalah',
    'points': 'Mata',
    'equal_to': 'Setaraf dengan',
    'back': 'Kembali',
    'confirm_selections': 'Sahkan Pilihan Anda',
    'confirm_details': 'Sila sahkan perinciannya',
    'points_to_redeem': 'Mata untuk Menebus',
    'cashout_amount': 'Jumlah Tunai',
    'you_have_read': 'anda telah membaca dan bersetuju dengan InsightzClub\'s Cashout',
    'confirm': 'Sahkan',
    'thank_you': 'Terima Kasih!',
    'points_confirmation_email': 'Anda telah berjaya menebus {0} mata. E-mel pengesahan akan dihantar tidak lama lagi.',
    'min_points_not_met': 'Mata minimum tidak dipenuhi',
    'exceeded_points': 'Melebihi mata yang ada',
    'earn_points_by_watching_videos': 'Anda dapat memperoleh mata dengan menonton video dan menjawab beberapa soalan yang berkaitan dengan video dengan betul.',
    'allow_task_id': 'Sediakan ID Tugas',
    'add_task': 'Untuk menambahkan tugas, sila masukkan ID Tugas yang disediakan. Task ID adalah gabungan aksara abjad dan angka. ',
    'successfully_added_task': 'Anda berjaya menambahkan Tugasan. Sekiranya tugas belum dimulakan, tugas itu mungkin tidak dapat dilihat pada mada itu.',
    'no_video_available': 'Maaf, tidak ada video yang tersedia untuk anda tonton saat ini. Sila semak kemudian.',
    'watch': 'Tonton',
    'for_video_watched': 'untuk video yang baru anda tonton.',
    'you_have_earned': 'Anda telah memperoleh',
    'account_already_activated': 'Nampaknya akaun anda sudah diaktifkan',
    'successful_activated': 'Anda berjaya mengaktifkan akaun anda. Sila klik butang KEMBALI di bahagian atas. ',
    'try_to': 'Cuba',
    'here': 'HERE',
    'activate_account': 'Aktifkan Akaun',
    'forgot_password': 'Lupa Kata Laluan',
    'click': 'Klik',
    'success_updated': 'Berjaya dikemas kini',
    'additional_demographic': 'Demografik Selanjutnya',
    'no_info': 'Tiada maklumat tersedia pada masa ini.',
    'loading': 'Memuatkan...',
    'topArtists': 'Artis Teratas',
    'topTracks': 'Trek Teratas',
    'take_again': 'Ambil Lagi',
    'start': 'Mulakan',
    'completed': 'Selesai',
    'required': 'Dikehendaki',
    'have_referral_code': 'Saya mempunyai Kod Rujukan atau Kod Projek',
    "deactivated_account": "Akaun telah dinyahaktifkan. Sila hubungi kami",
    "inactive_account": "Akaun belum diaktifkan. Sila aktifkan akaun anda",
    "user_not_found": "Pengguna tidak dijumpai. Sila daftar",
    "user_details_mismatch": "Butiran yang anda sampaikan tidak sepadan. Sila sahkan!",
    "account_pending_reset_password": "Akaun belum selesai kata laluan. Sila tetapkan semula kata laluan anda",
    "brands_purchased_not_completed": "Kami tidak dapat mengambil kategori jenama yang anda beli kerana anda belum menyelesaikan tugas screener.<br><br>Sila selesaikan screener sekarang.",
    "submit_brands_purchased": "Hantar Jenama yang Dibeli",
    "what_is_data_collected" : "Apakah data yang dikumpul?",
    "what_is_data_collected_answer": "Maklumat yang dikumpulkan secara pasif ialah data lokasi anda, data teknologi (peranti anda, nama peranti, OS, dll) dan aplikasi yang dipasang pada peranti anda. Maklumat lain yang dikumpul adalah seperti yang anda berikan melalui tinjauan atau cara aktif yang lain.",
    "purpose_of_data": "Apakah tujuan untuk mengumpul data ini?",
    "purpose_of_data_answer": "Kami mengumpul data untuk tujuan penyelidikan pasaran sahaja. Penyelidikan ini membantu pelbagai jenama yang sering anda gunakan untuk memahami pengalaman dan corak penggunaan anda dan untuk meningkatkan kualiti produk dan/atau tawaran perkhidmatan kepada anda. Kami melaporkan data anda hanya secara agregat dan tanpa nama. Ini bermakna data anda dan data peserta lain akan digabungkan bersama. Oleh itu, kesimpulan tentang tingkah laku peribadi individu tidak mungkin dilaporkan.",
    "what_are_rewards": "Apakah ganjaran?",
    "what_are_rewards_answer": "Anda akan menerima ganjaran (wang tunai dan/atau bukan tunai) selagi anda menyimpan aplikasi yang dipasang pada peranti anda. Anda boleh mendapat ganjaran tambahan dengan menyertai tugas atau tinjauan. Sila lawati bahagian ganjaran untuk melihat lebih banyak butiran mengenai ganjaran.",
    "can_see_own_data": "Bolehkah saya melihat data saya sendiri?",
    "can_see_own_data_answer": "Ya, dari papan pemuka pengguna anda boleh melihat semua parameter data tentang anda yang sedang diketahui atau diakses.",
    "privacy_taken_care": "Bagaimanakah privasi saya dalam talian dijaga?",
    "privacy_taken_care_answer": "Semua maklumat yang dikumpulkan tidak dikenali. Ini bermakna bahawa apa-apa maklumat peribadi atau cara untuk mengenal pasti ahli panel individu dikeluarkan. Data digabungkan dengan panelist lain dan hanya dilaporkan secara agregat. E-mel dan nombor telefon tidak dikaitkan dengan data yang dilaporkan dan hanya digunakan untuk tujuan pemasangan app awal dan untuk memberikan ganjaran.",
    "data_protection_taken_care": "Bagaimanakah perlindungan data dan keselamatan dijaga?",
    "data_protection_taken_care_answer": "Semua data yang dikumpulkan adalah mematuhi Akta Perlindungan Data Peribadi (PDPA) dan juga selari dengan GDPR.",
    "who_to_contact": "Siapa yang boleh menghubungi, jika saya mempunyai sebarang soalan?",
    "who_to_contact_answer": "Anda boleh menghantar e-mel ke <a href='mailto: askus@insightzclub.com'>askus@insightzclub.com</a> berbual dengan kami melalui <a href='https://wa.me/60320359220'>WhatsApp (+603-2035-9220)</a> jika anda mempunyai sebarang soalan mengenai projek ini.",
    "refer_and_earn": 'Kongsi & Menang',
    "refer_and_earn_point_1": "1. Bagikan teks di bawah merujuk sekarang ke rangkaian anda (melalui WhatsApp, SMS, PM, Facebook, dll).",
    "refer_and_earn_point_2": "2. Apabila mana-mana rangkaian anda dipasang berdasarkan rujukan anda, anda akan memperoleh 50 mata untuk setiap orang yang memasang.",
    "share_and_earn": "Rujuk kepada kenalan & Menang",
    "share_and_earn_point_1": "1. Kongsi teks di bawah Kongsi Sekarang ke media sosial atau rangkaian profesional anda (Facebook, Instagram, LinkedIn, Twitter).",
    "share_and_earn_point_2": "2. Buat siaran kepada umum",
    "share_and_earn_point_3": "3. Berikan bukti bahagian dengan memuat naik dalam Submit Proof dan anda akan mendapat ganjaran.",
    "share_and_earn_point_4": "4. Anda akan memperoleh 25 mata untuk setiap bahagian di setiap platform.",
    "engage_and_earn": "Sertai & Menang",
    "engage_and_earn_point_1": "1. Kongsi teks di bawah Kongsi Sekarang ke media sosial atau rangkaian profesional anda (Facebook, Instagram, LinkedIn, Twitter).",
    "engage_and_earn_point_2": "2. Buat siaran kepada umum",
    "engage_and_earn_point_3": "3. Semakin banyak penglibatan anda pada siaran anda (suka, berkongsi, komen), semakin banyak yang akan anda perolehi.",
    "engage_and_earn_point_4": "4. Anda akan memperoleh 1 mata untuk setiap pertunangan pada posting anda berbanding 25 mata untuk bahagian di setiap platform.",
    "engage_and_earn_point_5": "5. Berikan bukti bahagian dengan memuat naik dalam Kirim Bukti dan dapatkan ganjaran.",
    "user_profile": "Profil Pengguna",
    "logout": "Daftar Keluar",
    "task_id": "Task ID",
    "min_characters": "Min {1} aksara",
    "max_characters": "Maksimum {1} aksara",
    "available_points": "Mata Anda",
    "trees_planted": "Pokok Ditanam",
    "plant_tree": "Tanam Pokok",
    "cashout_to_bank": "Tunai Ke Bank",
    "evertreen_intro": "Anda boleh menebus mata anda untuk menanam pokok melalui rakan kami Evertreen.",
    "select_a_tree": "Pilih Pokok",
    "read_more": "Baca Lagi",
    "plant_this": "Tanam Ini",
    "language": "Bahasa",
    "login": "Log Masuk",
    "login_with_qr": "Log masuk dengan QR",
    "login_with_otp": "Log masuk dengan OTP",
    "scan_to_login_insightzclub": "Imbas untuk log masuk ke InsightzClub",
    "scan_to_login_insightzclub_description": "Sila imbas Kod QR dari Aplikasi Mudah Alih InsightzClub anda.",
    "unable_to_scan_qr": "Tidak dapat mengimbas Kod QR? ",
    "upload_file": "Muat Naik Fail",
    "uploaded_and_task_completed": "Anda telah memuat naik dan menyelesaikan tugas dengan jayanya.",
    "file_exceeded_limit": "Fail yang dimuat naik melebihi saiz fail maksimum {0} MB. Ukuran fail anda ialah {1} MB.",
    "browser_history": "Sejarah Penyemak Imbas",
    "url": "URL",
    "visited_at": "Dikunjungi pada",
    "actions": "Tindakan",
    "title": "Tajuk",
    "delete": "Padam",
    "whatsApp": "WhatsApp",
    "top_5_most_recent_ads_and_show_watched": "5 Iklan Terkini dan Tayangan Paling Banyak Ditonton",
    "top_5_most_recent_ads_seen": "5 Iklan Terkini Sudah Dilihat",
    "top_5_most_recent_ads_interacted": "5 Iklan Terkini Paling Banyak Berinteraksi",
    "top_5_most_recent_page_visits": "5 Lawatan Halaman Terkini Teratas",
    "password_policy": "Dasar Kata Laluan",
    "password_policy_description": "Kata laluan anda mestilah lebih besar atau sama dengan 8 aksara.",
    "current_password": "Kata Laluan Semasa",
    "new_password_not_match": "Kata Laluan Baru dan Sahkan Kata Laluan tidak sepadan",
    "save_successfully": "Perubahan berjaya disimpan.",
    "code_invalid": "Kod tidak sah",
    "before_we_begin": "Sebelum Kita Bermula",
    "before_we_begin_description": "Akaun saya mungkin dilarang oleh InsightzClub secara kekal jika saya melanggar salah satu peraturan di bawah ini: <br><ul><li>Buat lebih dari satu akaun InsightzClub</li><li>Kirim data yang bukan milik saya</li><li>Berikan data palsu</li><li>Kongsi akaun dengan orang lain</li></ul>",
    "rules_mentioned": "dan peraturan yang disebutkan di atas",
    "cashout_bank_not_available_reasons": "Wang tunai ke bank tidak tersedia untuk anda buat masa ini. Ini mungkin disebabkan oleh salah satu alasan berikut di bawah ini.<br><ul><li>Anda adalah pengguna baru</li><li>Anda bukan pengguna yang kembali</li><li>Terlalu banyak pengguna menunaikan tunai pada masa ini</li></ul><br>Sila periksa kembali suatu ketika nanti.",
    "available_on_mobile_app_only": "Tersedia hanya pada Aplikasi Mudah Alih InsightzClub",
    "app_only": "Apl Sahaja",
    "view_data": "Lihat Data",
    "link_data": "Pautan Data",
    "timestamp": "Cap waktu",
    "cashout_to_paypal": "Cashout To PayPal",
    'paypal_account_details': 'Butiran Akaun PayPal',
    'provide_paypal_account_details': 'Sila berikan butiran akaun PayPal anda bahawa anda ingin menerima pembayaran anda.',
    'full_name_paypal_hint': 'Sila berikan nama anda di akaun PayPal anda',
    'paypal_email': 'E-mel PayPal',
    'paypal_email_hint': 'Sila berikan alamat e-mel anda untuk akaun PayPal anda',
    'paypal_payout_charges': 'Adakah terdapat sebarang tuduhan?',
    'paypal_payout_charges_ans': 'Tidak. InsightzClub tidak mengenakan sebarang bayaran tunai kepada pengguna. Anda menerima jumlah pembayaran tunai yang tepat.',
    'paypal_payout_currency': 'Apakah mata wang yang saya terima dari PayPal?',
    'paypal_payout_currency_ans': 'USD (Dolar AS). Inilah satu-satunya mata wang yang kami sokong pada masa ini.',
    'paypal_countries_availability': 'Adakah PayPal tersedia di negara atau wilayah saya?',
    'paypal_countries_availability_ans': "Untuk memastikan bahawa negara atau wilayah anda disokong, sila ketahui lebih lanjut mengenai <a href='https://www.paypal.com/webapps/mpp/country-worldwide?locale.x=en_US' target='_blank'>Ketersediaan Negara / Wilayah PayPal</a>",
    'cashout_paypal_not_available_reasons': "Cashout ke PayPal tidak tersedia untuk anda buat masa ini. Ini mungkin disebabkan oleh salah satu alasan berikut di bawah ini.<br><ul><li>Anda adalah pengguna baru</li><li>Anda bukan pengguna yang kembali</li><li>Terlalu banyak pengguna menunaikan tunai pada masa ini</li><li>Pengeluaran tunai mungkin tidak tersedia di negara anda.</li></ul><br>Sila periksa kembali suatu ketika nanti.",
    'cashout_everteen_not_available_reasons': "Ciri ini belum tersedia di negara anda lagi. <br>Sila semak semula nanti.",
    'confirm_account_details': "Sahkan Butiran Akaun",
    'confirm_account_details_info': "Sila sahkan maklumat yang anda masukkan. Sekiranya terdapat kesilapan, navigasi kembali ke langkah sebelumnya untuk membetulkan maklumat.",
    'cancel': "Batal",
    'receipt_invalid_reasons': "Maaf resit yang anda muat naik tidak dapat disahkan oleh sistem. Ia mungkin disebabkan oleh mana-mana sebab berikut di bawah.<br><ul><li>Resit itu bukan resit yang sah</li><li>Imej yang dimuat naik adalah kabur</li></ul><br >Sila hubungi sokongan pelanggan kami untuk mendapatkan maklumat lanjut.",
    'ireview_no_data': 'Maaf tiada data tersedia untuk kategori yang dipilih',
    'next': 'Seterusnya',
    'survey_submitted': 'Terima kasih kerana melengkapkan tinjauan ini! Jawapan anda telah disimpan. Anda kini boleh meninggalkan halaman'
}