<template>
  <div class="text-center" v-if="$store.state.showSnackBar">
    <v-snackbar :value="$store.state.showSnackBar" @input="closeSnackbar">
      {{ $store.state.snackBarText }}
      <template v-slot:action="{ }">
        <v-btn color="white" text @click="closeSnackbar">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  methods: {
    closeSnackbar() {
      this.$store.state.showSnackBar = false
    }
  }
}
</script>