import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  showSideBar: true,
  language: {
    selected: "en"
  },
  userProfile: {},
  token: null,
  loader: false,
  loggedIn: false,

  showSnackBar: false,
  snackBarText: null,

  showDialogMessage: false,
  dialogMessageTitle: null,
  dialogMessageText: null,
};
const store = new Vuex.Store({ state });

export default store;