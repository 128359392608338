export default [
  {
    name: 'APP_LOGOUT',
    callback: function () {
      window.$cookies.remove('token')
      window.$cookies.remove('userId')
      window.$cookies.remove('email')

      this.$store.state.userProfile = {}
      this.$router.replace({ name: 'Login' });
    }
  },
];
