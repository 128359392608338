export default {
    'demographic_info': '인구정보',
    'provide_demographic': '여기에 귀하의 기본 인구정보를 넣어주십시오.',
    'gender': '성별',
    'date_of_birth': '생년월일',
    'marital_status': '결혼 여부',
    'country': '국가',
    'by_clicking': '클릭을 통해',
    'continue': '계속',
    'read_and_agree': '당신은 InsightzClub을 읽고 동의합니다.',
    'privacy_policy': '개인 정보 보호 정책',
    'login_information': '로그인 정보',
    'provide_login_info': '여기에 로그인 정보를 제공하십시오',
    'name': '이름',
    'email': '이메일',
    'country_code': '국가 코드',
    'mobile_number': '휴대폰 번호',
    'password': '비밀번호',
    'confirm_password': '비밀번호 확인',
    'referral_code': '추천 코드',
    'previous': '이전',
    'verification': '검증',
    'provide_otp': '등록된 모바일 번호 및 이메일로 전송된 OTP를 입력하십시오',
    'otp': 'OTP',
    'confirmation': '확인',
    'successful_registration': '등록이 완료되었습니다. 상단의 뒤로 버튼을 클릭하십시오',
    'email_valid': '이메일이 유효해야 합니다.',
    'confirmation_password': '암호 확인 필요',
    'confirmation_password_mismatch': '확인 암호가 암호와 일치하지 않습니다.',
    'six_characters': '6자 코드',
    'male': '남성',
    'female': '여성',
    'single': '미혼',
    'married': '기혼',
    'divorced': '이혼',
    'min': '최소',
    'max': '최대',
    'characters': '문자',
    'mobile_number_cannot': '모바일 번호는 ``로 시작할 수 없습니다.',
    'otp_not_match': 'OTP가 일치하지 않습니다.',
    'enter_valid_email' : '유효한 이메일을 입력하십시오',
    'account_details': '계정 정보',
    'provide_account_details': '계정 정보를 여기에 제공하십시오',
    'new_password': '새 비밀번호',
    'provide_password': '생성된 OTP와 새 비밀번호를 입력하십시오',
    'confirm_new_password': '새 비밀번호 확인',
    'successful_password_change': '비밀번호를 성공적으로 변경했습니다. 새 자격 증명으로 로그인 하기려면 뒤로 단추를 클립하십시오',
    'validate_details_failed': '귀하의 정보를 확인할 수 없습니다.',
    'full_name': '전체 이름',
    'full_name_bank_account_hint': '은행 계좌 소유자의 정확한 이름을 입력해주십시오.',
    'bank_name': '은행 이름',
    'account_number': '계좌 번호',
    'update': '업데이트',
    'frequently_asked_questions': '자주 묻는 질문(FAQ)',
    'terms_conditions': '약관 및 조건,',
    'min_req_redemption': '상환을 위해 필요한 최소 금액을 얼마입니까?',
    'min_req_redemption_ans': '상환을 위해 필요한 최소 포인트는 다음과 같습니다.',
    'points_currency_ratio': '통화 대비 포인트 환산율은 얼마입니까?',
    'points_currency_ratio_ans': '포인트 = ',
    'reward_expect': '보상은 언제쯤 받을수 있습니까?',
    'reward_expect_ans': '결산일은 매월 말입니다. 다음 달 15일에 파일에 대한 지불이 처리될 것입니다. 만약 15일이 주말 또는 공휴일인 경우, 보상은 다음 영업일에 처리됩니다.',
    'contact_payout': '어떤 문의가 있을 경우 지불 팀에 어떻게 연락해야 합니까?',
    'contact_payout_ans': '다음 이메일 주소로 InsightzClub 지불팀에 연락하실 수 있습니다 :  askus@insightzclub.com',
    'close': '닫기' ,
    'contact_payout_no_country': '우리는 귀하의 나라에서 현금 인출을 지원하지 않습니다. 다음 이메일 주소로 InsightzClub 지불 팀에 연락하실 수 있습니다: askus@insightzclub.com',
    'bank_account_details': '은행 계좌 정보',
    'before_processing': '진행하기 전에 InsightzClub의 현금화를 확인하십시오.',
    'no_cashout_info': '죄송합니다. 등록된 국가에 대한 현금 인출 정보가 없습니다. 나중에 다시 확인해 주십시오',
    'points_to_redeem_min': '죄송합니다. 포인트를 사용하시려면 최소 {0} 점이 필요합니다. 최소 포인트를 충족하면 포인트를 사용할 수 있습니다.',
    'provide_bank_account_details': '지급금을 받고자 하는 은행 계좌의 정보를 입력해주십시오',
    'choose_cashout_amount': '현금화 금액 선택',
    'select_amount': '현금화할 금액을 선택하십시오. 금액을 늘리거나 줄이려면 + 또는 - 버튼을 사용하십시오.',
    'total_available_points': '사용 가능한 총 포인트는',
    'points': '포인트' ,
    'equivalent_to': '같다',
    'back': '뒤로',
    'confirm_selections': '선택 사항 확인',
    'confirm_details': '자세한 사항을 확인해 주십시오',
    'points_to_redeem': '포인트 상환하기',
    'cashout_amount': '현금화 금액 선택',
    'you_have_read': '당신은 InsightzClub의 현금화를 읽고 동의합니다.',
    'confirm': '확인',
    'thank_you': '감사합니다!',
    'points_confirmation_email': '{0}포인트를 성공적으로 사용하셨습니다. 곧 확인 이메일이 발송됩니다.',
    'min_points_not_met': '최소 포인트가 충족되지 않음',
    'exceeded_points': '사용 가능한 포인트 초과',
    'earn_points_by_watching_videos': '동영상 시청 및 동영상과 관련된 몇 가지 질문에 올바른 대답을 통해 포인트를 획득할 수 있습니다.',
    'provide_task_id': '작업 ID 제공',
    'add_task': '작업을 추가하려면 제공된 작업 ID 를 입력하십시오. 작업ID 는 알파벳 문자와 숫자 문자의 조합입니다.',
    'successfully_added_task': '작업을 추가하였습니다. 작업이 시작되지 않은 경우 작업이 표시되지 않을 수 있습니다.',
    'no_video_available': '죄송합니다. 현재 시청가능한 비디오가 없습니다. 나중에 확인하십시오',
    'watch': '시청하기',
    'for_video_watched': '방금 본 영상 때문에',
    'you_have_earned': '적립되었습니다.' ,
    'account_already_activated': '계정은 이미 활성화된 것 같습니다.',
    'successful_activated': '계정을 활성화했습니다. 상단의 뒤로 버튼을 클릭해주십시오',
    'trying_to': '노력 중' ,
    'here': '여기',
    'activate_account': '계정 활성화',
    'forgot_password': '암호 잃어버림',
    'click': '클릭',
    'successfully_updated': '업데이트 성공',
    'additional_demographic': '추가적인 인구통계',
    'no_info': '현재 이용 가능한 정보가 없습니다.',
    'loading': '로딩 중…',
    'topArtists': '상위 아티스트',
    'topTracks': '상위 음원',
    'take_again': '다시 시도',
    'start': '시작',
    'completed': '완료됨',
    'required': '필수',
    'have_referral_code': '추천 코드나 프로젝트 코드가 있습니다.',
    "deactivated_account": '계정이 비활성화되었습니다. 고객센터를 방문해주십시오',
    "inactive_account": '계정이 활성화되지 않았습니다. 계정을 활성화하십시오',
    "user_not_found": '사용자를 찾을 수 없습니다. 가입해주세요.',
    "user_details_mismatch": '당신이 전달한 세부 정보가 일치하지 않습니다. 확인해주십시오!',
    "account_pending_reset_password": '계정이 비밀번호 재설정 시까지 보류됩니다. 비밀번호를 재설정하십시오',
    "brands_purchased_not_completed": '보안검색 작업을 완료하지 않았기 때문에 귀사의 브랜드 구매 카테고리를 검색할 수 없습니다. 지금 보안검색을 완료하십시오',
    "submit_brands_purchased": '구매된 브랜드 제출',
    "what_is_data_collected" : '수집된 데이터는 무엇입니까?',
    "what_is_data_collected_answer": '수동적으로 수집된 정보는 사용자의 위치 데이터, 기술 데이터(장치, 장치이름, OS 등) 및 장치에 설치된 앱입니다. 기타 수집된 모든 정보는 설문조사 또는 기타 활성 수단을 통해 수집됩니다.',
    "purpose_of_data": '이 데이터를 수집하는 목적은 무엇입니까?',
    "purpose_of_data_answer": '우리는 시장 조사 목적으로만 데이터를 수집합니다. 이 조사는 경험과 사용패턴을 이해하고 제품 및/또는 서비스 제공을 개선하기 위해 사용한 브랜드 및 서비스에 도움이 됩니다. 즉, 사용자의 데이터와 다른 참가자의 데이터가 함께 결합됩니다. 따라서, 데이터는 개인 수준이 아닌 상위 수준에서 보고될 것 입니다.',
    "what_are_rewards": '보상은 무엇입니까?',
    "what_are_rewards_answer": '앱을 기기에 설치하기만 하면 보상 (현금 및/또는 비현금)을 받게 됩니다. 과제나 설문조사에 참여하시면 추가 보상을 받을 수 있습니다. 보상에 대한 자세한 내용을 보려면 보상 섹션을 확인하십시오.',
    "can_see_own_data": '내 데이터를 볼 수 있습니까?',
    "can_see_own_data_answer": '예, 사용자의 대시보드에서 현재 알려지거나 엑세스 중인 사용자의 대한 모든 데이터  매개 변수를 볼 수 있습니다.',
    "privacy_taken_care": '나의 온라인 프라이버시는 어떻게 관리됩니까?',
    "privacy_taken_care_answer": '수집된 모든 정보는 익명으로 처리됩니다. 즉, 개인 정보나 패널 구성원을 식별할 수 있는 수단이 제거됩니다. 데이터는 다른 패널 목록과 결합되어 집계로만 보고됩니다. 이메일과 전화번호는 보고된 데이터에 연동되지 않으며, 최초 앱 설치 및 보상 제공 목적으로만 사용됩니다.',
    "data_protection_taken_care": '데이터 보호 및 보안은 어떻게 처리됩니까?',
    "data_protection_taken_care_answer": '수집된 모든 데이터는 개인 데이터 보호법(PDPA) 및 GDPR을 준수합니다.',
    "who_to_contact": '문의 사항이 있으면 누구에게 연락해야 합니까?',
    "who_to_contact_answer": "프로젝트에 대해 질문이 있으시면, askus@insightzclub.com으로 이메일을 보내거나 'https://wa.me/60320359220' 및 WhatsApp(+603-2035-9220) 을 통해 우리와 채팅할 수 있습니다.",
    "refer_and_earn": '추천하여 적립',
    "refer_and_earn_point_1": '1. 아래의 텍스트를 (WhatsApp, SMS, PM, Facebook 등을 통해) 네트워크에 공유하십시오.',
    "refer_and_earn_point_2": '2. 귀하의 네트워크에서 추천으로 설치할 경우, 설치하는 사람마다 50점을 얻게 됩니다.',
    "share_and_earn": '공유하여 적립',
    "share_and_earn_point_1": '1. 지금 공유하기 아래의 텍스트를 소셜 미디어 또는 전문 네트워크(Facebook, Instagram, LinkedIn, Twitter)에 공유하십시오.',
    "share_and_earn_point_2": '2. 게시물을 공개합니다.',
    "share_and_earn_point_3": '3. Submit Proof(증명제출)에 업로드 하여 공유에 대한 증명을 제공하면 보상을 받게 됩니다.',
    "share_and_earn_point_4": '4. 각 플랫폼의 공유에 대해 25점을 획득합니다.',
    "engage_and_earn": '참여하여 적립',
    "engage_and_earn_point_1": "1. '지금 공유하기' 아래의 텍스트를 소셜 미디어 또는 전문 네트워크(Facebook, Instagram, LinkedIn, Twitter)에 공유하십시오.",
    "engage_and_earn_point_2": '2. 게시물을 공개합니다.',
    "engage_and_earn_point_3": '3. 게시물에 더 많은 참여(좋아요, 공유, 댓글)가 될수록 더 많은 수익을 얻게 됩니다.',
    "engage_and_earn_point_4": '4. 각 플랫폼의 공유로 획득한 25점와 별도로 게시물에서 참여횟수당 1점을 획득합니다.',
    "engage_and_earn_point_5": '5. Submit Proof(증명제출)에 업로드 하여 공유에 대한 증명을 제공하면 보상을 받게 됩니다.',
    "user_profile": '사용자 프로필',
    "logout": '로그아웃',
    "task_id": '작업 ID',
    "min_characters": '최소 {1} 자',
    "max_characters": '최소 {1} 자',
    "available_points": '사용 가능한 포인트',
    "trees_planted": '심어진 나무',
    "plant_tree": '나무 심기',
    "cashout_to_bank": '은행으로 현금화',
    "evertreen_intro": '당사의 파트너 Evertreen 을 통해 포인트를 상환하여 나무를 심을 수 있습니다.',
    "select_a_tree": '나무 선택하기',
    "read_more": '더 읽어보기',
    "plant_this": '이 나무 심기',
    "language": '언어',
    "login": '로그인',
    "login_with_qr": 'QR로 로그인하기',
    "login_with_otp": 'OTP로 로그인하기',
    "scan_to_login_insightzclub": 'InsightzClub에 로그인하기 위해 스캔하기',
    "scan_to_login_insightzclub_description": 'Insightz Club 모바일 앱에서 QR 코드를 스캔하십시오.',
    "unable_to_scan_qr": 'QR코드를 스캔할 수 없습니까?',
    "upload_file": '파일 업로드하기',
    "uploaded_and_task_completed": '작업을 성공적으로 업로드하고 완료했습니다.',
    "file_exceeded_limit": '업로드된 파일이 최대 파일 크기인 {0}MB를 초과합니다. 파일 크기는 {1}MB입니다.',
    "browser_history": '브라우저 기록',
    "url": 'URL',
    "visited_at": '방문 날짜',
    "actions": '행동',
    "title": '제목',
    "delete": '삭제' ,
    "whatsApp": 'WhatsApp',
    "top_5_most_recent_ads_and_show_watched": '가장 최근에 본 상위5개 광고와 쇼',
    "top_5_most_recent_ads_seen": '가장 최근에 노출된 상위 5개 광고',
    "top_5_most_recent_ads_interacted": '최근에 가장 많이 접한 광고 상위 5개',
    "top_5_most_recent_page_visits": '최근 패이지 방문 횟수 상위 5개',
    "password_policy": '비밀번호 정책',
    "password_policy_description": '비밀번호는 8자 이상이어야 합니다.',
    "current_password": '현재 비밀번호',
    "new_password_not_match": '새 암호 및 암호 확인 필드가 일치하지 않습니다',
    "save_successfully":'변경 내용이 저장되었습니다.',
    "code_invalid": '코드가 올바르지 않습니다.',
    "before_we_begin": '시작하기 앞서',
    "before_we_begin_description": '다음 규칙 중 하나를 위반할 경우 InsightzClub에 의해 계정이 영구적으로 금지될 수 있습니다., 두 개 이상의 InsightzClub 계정 만들기. 내 소유가 아닌 데이터 제출. 가짜 데이터 제출. 다른 사람과 계정 공유.',
    "rules_mentioned": '그리고 위에 언급된 규칙들',
    "cashout_bank_not_available_reasons": '현재 은행 계좌로의 현금 출금이 불가능합니다. 아래 중 하나 때문일 수 있습니다. 새 사용자입니다. 복귀 사용자가 아닙니다. 현재 너무 많은 사용자가 현금을 인출하고 있습니다. 나중에 다시 확인하십시오.',
    "available_on_mobile_app_only": 'InsightzClub 모바일 앱에서만 사용 가능',
    "app_only": '앱 전용',
    "view_data": '데이터 보기',
    "link_data": '데이터 연결하기',
    "timestamp": '타임 스탬프',
    "cashout_to_paypal": "PayPal로 현금 인출하기",
    'paypal_account_details': 'PayPal 계정 정보',
    'provide_paypal_account_details': '현금을 인출받으려는 PayPal 계정 정보를 입력하십시오',
    'full_name_paypal_hint': 'PayPal 계정의 이름을 입력해 주십시오',
    'paypal_email': 'PayPal 이메일',
    'paypal_email_hint': 'PayPal 계정의 이메일 주소를 입력해주십시오',
    'paypal_payout_charges': '별도의 수수료가 있나요?',
    'paypal_payout_charges_ans': '아니요. InsightzClub은 현금인출 수수료를 청구하지 않습니다. 현재 현금화하신 금액을 정확히 수령할 수 있습니다.',
    'paypal_payout_currency': 'PayPal에서 받는 통화는 무엇인가요?',
    'paypal_payout_currency_ans': 'USD (미국 달러). 현재 지원되는 통화는 USD뿐입니다.',
    'paypal_countries_availability': '내 국가 또는 지역에서 PayPal을 사용할 수 있습니까?',
    'paypal_countries_availability_ans': "귀하의 국가 또는 지역이 지원되는지 확인하려면 <a href='https://www.paypal.com/webapps/mpp/country-worldwide?locale.x=en_US' target='_blank'>에 대해 자세히 알아보십시오. PayPal 국가/지역 가용성</a>",
    'cashout_paypal_not_available_reasons': "PayPal로의 현금 인출은 현재 귀하에게 제공되지 않습니다. 다음과 같은 이유 때문일 수 있습니다.<br><ul><li>신규 사용자입니다</li><li>재사용자가 아닙니다</li><li>너무 많은 사용자가 현재 현금화 중입니다.</li><li>귀하의 국가에서는 현금 인출이 불가능할 수 있습니다.</li></ul><br>나중에 다시 확인해 주세요",
    'cashout_everteen_not_available_reasons': "이 기능은 아직 귀하의 국가에서 사용할 수 없습니다. <br>나중에 다시 확인해 주세요.",
    'confirm_account_details': "계정 세부 정보 확인",
    'confirm_account_details_info': "입력하신 정보를 확인해주세요. 오류가 있는 경우 이전 단계로 돌아가 정보를 수정합니다.",
    'cancel': "취소",
    'receipt_invalid_reasons': "업로드한 영수증은 시스템에서 확인할 수 없습니다. 다음과 같은 이유 때문일 수 있습니다.<br><ul><li>영수증이 유효하지 않습니다.</li><li>업로드한 이미지가 흐릿합니다.</li></ul><br >자세한 내용은 고객 지원에 문의하십시오.",
    'ireview_no_data': '죄송합니다. 선택한 카테고리에 사용할 수 있는 데이터가 없습니다',
    'next': '다음',
    'survey_submitted': '이 설문 조사를 완료해 주셔서 감사합니다! 응답이 저장되었습니다. 이제 페이지를 떠날 수 있습니다.'
}