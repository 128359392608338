export default {
  colorLuminance(hex, lum) {
    hex = String(hex).replace(/[^0-9a-f]/gi, '');

    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }

    lum = lum || 0;
  
    var rgb = "#", c, i;
    
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }
  
    return rgb;
  },
  styleMapping(type) {
    let data = {
      insight: {
        icon: "mdi-lightbulb-outline",
        backgroundColor: "#0fa2a9"
      },
      fun: { 
        icon: "mdi-emoticon-happy-outline",
        backgroundColor: "#7d3865"
      },
      more: {
        icon: "mdi-shape-polygon-plus",
        backgroundColor: "#949217"
      },
      other: {
        icon: "mdi-help-circle-outline",
        backgroundColor: "#f8b703"
      },
      placeholder: {
        icon: "mdi-help-circle-outline",
        backgroundColor: "#c1a7b0"
      },
      T: {
        icon: "mdi-format-list-bulleted-type",
        backgroundColor: "#0fa2a9"
      },
      S: { 
        icon: "mdi-form-select",
        backgroundColor: "#7d3865"
      },
    }

    return data[type] ? data[type] : data["other"]
  },
  userCountry(userProfile) {
    let country = userProfile.demographics.filter(d => d.title == "Country")
    return country.length == 1 ? country[0].answer : "DNE"
  },
}