export default {
    'demographic_info': 'المعلومات الديموغرافية',
    'provide_demographic': 'يرجى تقديم المعلومات الديموغرافية الأساسية الخاصة بك هنا.',
    'gender': 'النوع',
    'date_of_birth': 'تاريخ الميلاد',
    'marital_status': 'الحالة الاجتماعية',
    'country': 'البلد',
    'by_clicking': 'بالضغط ',
    'continue': 'استمر',
    'read_and_agree': 'لقد قرأت ووافقت على InsightzClub',
    'privacy_policy': 'سياسة الخصوصية',
    'login_information': 'معلومات تسجيل الدخول',
    'provide_login_info': 'يرجى تقديم معلومات تسجيل الدخول الخاصة بك هنا.',
    'name': 'الاسم',
    'email': 'البريد الإليكتروني',
    'country_code': 'كود الدولة',
    'mobile_number': 'رقم الجوال',
    'password': 'كلمة المرور',
    'confirm_password': 'تأكيد كلمة المرور',
    'referral_code': 'كود التحويل',
    'previous': 'السابق',
    'verification': 'التحقق',
    'provide_otp': 'يرجى تقديم كلمة المرور ذات الاستخدام الواحد الذي تم إرساله إلى رقم هاتفك المحمول المسجل والبريد الإلكتروني.',
    'otp': 'كلمة المرور ذات الاستخدام الواحد',
    'confirmation': 'التأكيد',
    'successful_registration': 'لقد سجلت بنجاح. الرجاءالضغط على زر "رجوع" في الأعلى.',
    'email_valid': 'يجب ان يكون البريد الاكتروني صحيح',
    'confirmation_password': 'مطلوب تأكيد كلمة المرور.',
    'confirmation_password_mismatch': 'تأكيد كلمة المرور لا يتطابق مع كلمة المرور.',
    'six_characters': 'رمز من 6 أحرف',
    'male': 'ذكر',
    'female': 'انثى',
    'single': 'أعزب',
    'married': 'متزوج',
    'divorced': 'مطلق',
    'min': 'الحد الأدنى ',
    'max': 'الحد الأقصى',
    'characters': 'الأحرف',
    'mobile_number_cannot': 'لا يمكن أن يبدأ رقم الجوال بـ',
    'otp_not_match': 'كلمة المرور ذات الاستخدام الواحد غير مطابقة',
    'enter_valid_email': 'يرجى إدخال البريد الإلكتروني الصحيح',
    'account_details': 'تفاصيل الحساب',
    'provide_account_details': 'يرجى وضع تفاصيل حسابك هنا.',
    'new_password': 'كلمة المرور الجديدة',
    'provide_password': 'يرجى تقديم كلمة المرور الجديدة الخاصة بك مع كلمة المرور ذات الاستخدام الواحد الذي تم إنشاؤه.',
    'confirm_new_password': 'تأكيد كلمة المرور الجديدة',
    'successful_password_change': 'لقد غيرت كلمة سرك بنجاح. الرجاء الضغط على الزر "رجوع" لتسجيل الدخول باستخدام بيانات الاعتماد الجديدة.',
    'validate_details_failed': 'نحن غير قادرين على التحقق من صحة التفاصيل الخاصة بك.',
    'full_name': 'الاسم بالكامل',
    'full_name_bank_account_hint': 'الرجاء إدخال الاسم صاحب الحساب المصرفي بالكامل',
    'bank_name': 'اسم البنك',
    'account_number': 'رقم الحساب',
    'update': 'تحديث',
    'frequently_asked_questions': 'الأسئلة المتكررة كثيرا',
    'terms_conditions': 'الشروط والأحكام',
    'min_req_redemption': 'ما هو الحد الأدنى المطلوب الاستبدال',
    'min_req_redemption_ans': 'الحد الأدنى من النقاط المطلوبة للقيام الاسترداد هو',
    'points_currency_ratio': 'كم تساوي النقاط مقابل الأموال؟',
    'points_currency_ratio_ans': ' النقاط = ',
    'reward_expect': 'متى أتوقع الحصول على المكافآت؟',
    'reward_expect_ans': 'آخر موعد للاستراحة هو نهاية كل شهر. ستتم معالجة عائدك المسجل في اليوم الخامس عشر القادم من الشهر. إذا كان الخامس عشر من الشهر عطلة نهاية الأسبوع أو عطلة رسمية، فستتم معالجة المكافأة في يوم العمل التالي.',
    'contact_payout': 'كيف يمكنني الاتصال بفريق الدفع لأية استفسارات؟',
    'contact_payout_ans': 'يمكنك التواصل مع فريق مدفوعات InsightzClub عبر عنوان البريد الإلكتروني التالي: askus@insightzclub.com.',
    'close': 'اغلاق',
    'contact_payout_no_country': 'نحن لا ندعم السحب النقدي في بلدك. يمكنك التواصل مع فريق مدفوعات InsightzClub عبر عنوان البريد الإلكتروني التالي: askus@insightzclub.com.',
    'bank_account_details': 'تفاصيل الحساب المصرفي',
    'before_processing': 'قبل المعالجة، يرجى الخروج من InsightzClub Cashout',
    'no_cashout_info': 'عذرًا ، لا توجد معلومات سحب أموال لبلدك المسجل. يرجى التحقق مرة أخرى في وقت لاحق',
    'points_to_redeem_min': 'عذرًا ، أنت بحاجة إلى حد أدنى من النقاط يبلغ {0} للاستبدال. ستتمكن من استبدال نقاطك بمجرد أن تصل إلى الحد الأدنى.',
    'provide_bank_account_details': 'الرجاء تقديم تفاصيل حسابك المصرفي التي تريد تلقي عائدك عليها.',
    'choose_cashout_amount': 'اختر مبلغ السحب',
    'select_amount': 'الرجاء تحديد المبلغ الذي تريد صرفه. استخدم الزر + أو - لزيادة المبلغ أو إنقاصه.',
    'total_available_points': 'مجموع النقاط المتاحة الخاصة بك هي ',
    'points': 'النقاط',
    'equivalent_to': 'مساوية إلى ',
    'back': "عودة",
    'confirm_selections': 'قم بتأكيد اختياراتك',
    'confirm_details': 'يرجى تأكيد التفاصيل.',
    'points_to_redeem': 'النقاط التي يجب استبدالها',
    'cashout_amount': 'مبلغ السحب',
    'you_have_read': ' لقد قرأت ووافقت على صرف InsightzClub',
    'confirm': 'تأكيد',
    'thank_you': 'شكرا لك!',
    'points_confirmation_email': 'لقد نجحت في استبدال {0} نقطة. سيتم إرسال بريد إلكتروني للتأكيد قريبًا',
    'min_points_not_met': 'الحد الأدنى من النقاط لم يتحقق',
    'exceeded_points': 'تم تجاوز النقاط المتاحة',
    'earn_points_by_watching_videos': 'يمكنك كسب نقاط من خلال مشاهدة مقاطع الفيديو والإجابة على بعض الأسئلة المتعلقة بمقاطع الفيديو بشكل صحيح.',
    'provide_task_id': 'ادخل معرف المهمة',
    'add_task': 'لإضافة مهمة، يرجى إدخال معرف المهمة المقدم. معرف المهمة هو مزيج من الأحرف الأبجدية والرقمية.',
    'successfully_added_task': 'لقد نجحت في إضافة مهمة. إذا لم تبدأ المهمة، فقد لا تكون مرئية في الوقت الحالي.',
    'no_video_available': 'عذرا ، لا يوجد فيديو متاح لك لمشاهدته حاليا. يرجى التحقق لاحقًا.',
    'watch': 'شاهد',
    'for_video_watched': 'للفيديو الذي شاهدته للتو.',
    'you_have_earned': 'قد كسبت',
    'account_already_activated': 'يبدو أنه تم تنشيط حسابك بالفعل',
    'successful_activated': 'لقد قمت بتنشيط حسابك بنجاح. الرجاء النقر فوق الزر "رجوع" في الأعلى.',
    'trying_to': 'أحاول أن',
    'here': 'هنا',
    'activate_account': 'تفعيل حساب',
    'forgot_password': 'نسيت كلمة السر',
    'click': 'اضغط',
    'successfully_updated': 'تم التحديث بنجاح',
    'additional_demographic': 'ديموغرافية إضافية',
    'no_info': 'لا توجد معلومات متاحة في هذا الوقت.',
    'loading': 'تحميل',
    'topArtists': 'أهم الفنانين',
    'topTracks': 'أهم المقاطع الصوتية',
    'take_again': 'خذ مرة أخرى',
    'start': 'إبدأ',
    'completed': 'مكتمل',
    'required': 'مطلوب',
    'have_referral_code': 'لدي رمز إحالة أو رمز مشروع',
    "deactivated_account": "تم إلغاء تنشيط الحساب. يرجى التواصل معنا من أجل الدعم",
    "inactive_account": "لم يتم تفعيل الحساب. يرجى تفعيل حسابك",
    "user_not_found": "المستخدم ليس موجود. يرجى التسجيل",
    "user_details_mismatch": "التفاصيل التي أدخلتها غير متطابقة. برجاء التحقق!",
    "account_pending_reset_password": "الحساب معلق لإعادة تعيين كلمة المرور. يرجى إعادة ضبط كلمة المرور الخاصة بك",
    "brands_purchased_not_completed": "يتعذر علينا استعادة فئات الماركات التي تم شراؤها لأنك لم تكمل الفحص. <br> <br> يرجى إكمال الفحص الآن.",
    "submit_brands_purchased": "إرسال الماركات التي تم شراءها",
    "what_is_data_collected": "ما هي البيانات التي تم جمعها?",
    "what_is_data_collected_answer": "المعلومات التي يتم جمعها بدون اجابة مباشرة منك هي بيانات موقعك والبيانات التقنية (جهازك واسم جهازك ونظام التشغيل وما إلى ذلك) والتطبيقات المثبتة على جهازك. أي معلومات أخرى يتم جمعها هي على النحو الذي قدمته من خلال الاستطلاع أو أي وسيلة نشطة أخرى.",
    "purpose_of_data": "ما هو الغرض من جمع هذه البيانات؟",
    "purpose_of_data_answer": "نقوم بجمع البيانات لأغراض أبحاث السوق فقط. يساعد البحث الماركات والخدمات التي استخدمتها على فهم تجربتك وأنماط الاستخدام وتحسين المعروض من منتجاتها و  خدماتها لك. سيتم اتقديم تقرير عن بياناتك بشكل مجمع فقط. هذا يعني أنه سيتم دمج بياناتك وبيانات المشاركين الآخرين معًا. لذلك سيتم الافصاح عن البيانات على مستوى مجمع وليس على المستوى الفردي.",
    "what_are_rewards": "ما هي المكافآت؟",
    "what_are_rewards_answer": "سوف تتلقى مكافآت (نقدية و / أو عينية) طالما أنك تحتفظ بالتطبيق مثبتًا على جهازك. يمكنك كسب مكافآت إضافية من خلال المشاركة في المهام أو الاستطلاعات. يرجى زيارة قسم المكافآت لمعرفة المزيد من التفاصيل حول المكافآت.",
    "can_see_own_data": "هل يمكنني رؤية بياناتي الخاصة؟",
    "can_see_own_data_answer": "نعم، من لوحة معلومات المستخدم، يمكنك رؤية جميع معلمات البيانات الخاصة بك والتي يتم التعرف عليها أو الوصول إليها حاليًا.",
    "privacy_taken_care": "كيف يتم الاعتناء بخصوصيتي على الإنترنت؟",
    "privacy_taken_care_answer": "جميع المعلومات التي تم جمعها ستظل مجهولة المصدر. هذا يعني أنه يتم إزالة أي معلومات شخصية أو أي وسيلة تدل على هوية أي عضو في الفريق. يتم دمج البيانات مع أعضاء الفريق الآخر ويتم الإبلاغ عنها بشكل مجمع فقط. لا يتم ربط رسائل البريد الإلكتروني وأرقام الهواتف بالبيانات المبلغ عنها ويتم استخدامها فقط لأغراض التثبيت الأولي للتطبيق ولتقديم المكافآت.",
    "data_protection_taken_care": "كيف يتم الاعتناء بحماية البيانات والأمان؟",
    "data_protection_taken_care_answer": "جميع البيانات التي تم جمعها متوافقة مع قانون حماية البيانات الشخصية (PDPA) وأيضًا بما يتماشى مع القانون العام لحماية البيانات (GDPR).",
    "who_to_contact": "بمن أتصل إذا كان لدي أي أسئلة؟",
    "who_to_contact_answer": "يمكنك إرسال بريد إلكتروني إلى <a href='mailto: askus@insightzclub.com'> askus@insightzclub.com </a> أو الدردشة معنا عبر <a href='https://wa.me/60320359220'> واتس اب (+ 603-2035-9220) </a> إذا كان لديك أي أسئلة بخصوص المشروع.",
    "refer_and_earn": 'حول واربح',
    "refer_and_earn_point_1": "1. شارك النص الموجود تحت التحويل الآن إلى كل من تعرفهم (من خلال فيسبوك، رسائل نصية، واتس اب، بي أم، إلخ).",
    "refer_and_earn_point_2": "2. ستربح 50 نقطة مقابل كل شخص يقوم بالتثبيت بناءا على تحويلك .",
    "share_and_earn": "شارك واربح",
    "share_and_earn_point_1": "1. شارك النص الموجود أسفل شارك الآن على وسائل التواصل الاجتماعي أومن تعرفهم بالعمل (فيسبوك و انتجرام و لينكد ان وتويتر).",
    "share_and_earn_point_2": "2. اجعل المشاركة عامة.",
    "share_and_earn_point_3": "3. قدِّم إثباتًا للمشاركة عن طريق التحميل في إرسال إثبات وستكسب مكافأة.",
    "share_and_earn_point_4": "4. سوف تربح 25 نقطة لكل مشاركة على كل منصة.",
    "engage_and_earn": "تفاعل واربح",
    "engage_and_earn_point_1": "1. شارك النص الموجود أسفل شارك الآن على وسائل التواصل الاجتماعي أومن تعرفهم بالعمل (فيسبوك و انتجرام و لينكد ان وتويتر).",
    "engage_and_earn_point_2": "2. اجعل المشاركة عام.",
    "engage_and_earn_point_3": "3. كلما زاد التفاعل على منشورك (الإعجابات ، المشاركات ، التعليقات) زادت ربحك.",
    "engage_and_earn_point_4": "4. سوف تربح نقطة واحدة عن كل تفاعل على منشورك بخلاف 25 نقطة للمشاركة على كل منصة.",
    "engage_and_earn_point_5": "5. قدِّم إثباتًا للمشاركة عن طريق التحميل في إرسال إثبات واكسب مكافأة.",
    "user_profile": "الملف التعريفي للمستخدم",
    "logout": "تسجيل الخروج",
    "task_id": "رقم تعريف المهمة",
    "min_characters": "الحد الأدنى لعدد الأحرف {1}",
    "max_characters": "الحد الأقصى لعدد الأحرف {1}",
    "available_points": "النقاط المتاحة",
    "trees_planted": "الأشجار التي تم زراعتها",
    "plant_tree": "ازرع شجرة",
    "cashout_to_bank": "حول للبنك",
    "evertreen_intro": "يمكنك استبدال نقاطك بزراعة شجرة عبر شريكنا Evertreen.",
    "select_a_tree": "اختر شجرة",
    "read_more": "اقرأ اكثر",
    "plant_this": "ازرع هذه",
    "language": "اللغة",
    "login": "تسجيل الدخول",
    "login_with_qr": "تسجيل الدخول باستخدام رمز الاستجابة السريعة",
    "login_with_otp": "تسجيل الدخول باستخدام كلمة المرور ذات الاستخدام الواحد",
    "scan_to_login_insightzclub": "امسح لتسجيل الدخول إلى InsightzClub",
    "scan_to_login_insightzclub_description": "يرجى مسح رمز الاستجابة السريعة بالكاميرا من تطبيق InsightzClub للهاتف المحمول الخاص بك.",
    "unable_to_scan_qr": "غير قادر على مسح رمز الاستجابة السريعة؟ ",
    "upload_file": "ارفع ملف",
    "uploaded_and_task_completed": "لقد قمت بتحميل وإكمال المهمة بنجاح.",
    "file_exceeded_limit": "يتجاوز الملف الذي تم تحميله الحد الأقصى لحجم الملف وهو {0} ميغابايت. حجم ملفك هو {1} ميغابايت.",
    "browser_history": "تاريخ المتصفح",
    "url": "الرابط",
    "visited_at": "تم الزيارة في",
    "actions": "الأعمال",
    "title": "العنوان",
    "delete": "امسح",
    "whatsApp": "واتس اب",
    "top_5_most_recent_ads_and_show_watched": "أحدث 5 إعلانات وبرامج قمت بمشاهدتها",
    "top_5_most_recent_ads_seen": " أحدث 5 إعلانات تمت رؤيتها",
    "top_5_most_recent_ads_interacted": "أحدث 5 إعلاناتتفاعلت معها",
    "top_5_most_recent_page_visits": "أحدث 5 زيارات للصفحة",
    "password_policy": "سياسة كلمة المرور",
    "password_policy_description": "يجب أن تكون كلمة المرور الخاصة بك أكبر من 8 أحرف أو مساوية لها.",
    "current_password": "كلمة المرور الحالية",
    "new_password_not_match": "حقول كل من كلمة المرور الجديدة و تأكيد كلمة المرور غير متطابقين",
    "save_successfully": "تم حفظ التغييرات بنجاح",
    "code_invalid": "الرمز غير صالح",
    "before_we_begin": "قبل أن نبدأ",
    "before_we_begin_description": "قد يتم حظر حسابي بواسطة InsightzClub بشكل دائم إذا انتهكت أيًا من القواعد أدناه: <br> <ul> <li> إنشاء أكثر من حساب واحد على InsightzClub </li> <li> إرسال البيانات التي لا تخصني </ li > <li> إرسال بيانات مزيفة </ li> <li> مشاركة حساب مع شخص آخر </ li> </ul>",
    "rules_mentioned": "والقواعد المذكورة أعلاه",
    "cashout_bank_not_available_reasons": "التحويل إلى البنك غير متاح لك في الوقت الحالي. قد يرجع ذلك إلى أي من الأسباب التالية. <br> <ul> <li> أنت مستخدم جديد </ li> <li> أنت لست مستخدمًا عائدًا </ li> <li> هناك عدد كبير جدًا من المستخدمين يحولون الأموال في الوقت الحالي </ li> </ul> <br> يرجى إعادة التحقق لاحقًا.",
    "available_on_mobile_app_only": "متوفر على تطبيق InsightzClub Mobile فقط",
    "app_only": "التطبيق فقط",
    "view_data": "عرض البيانات",
    "link_data": "ربط البيانات",
    "timestamp": "التوقيت",
    "cashout_to_paypal": "تحويل الأموال إلأى باي بال",
    'paypal_account_details': 'تفاصيل حساب باي بال',
    'provide_paypal_account_details': 'يرجى تقديم تفاصيل حساب باي بال الخاص بك الذي ترغب في تلقي عائدك عليه.',
    'full_name_paypal_hint': 'يرجى تقديم اسمك الموجود على حساب باي بال الخاص بك',
    'paypal_email': 'بريد اليكتروني باي بال',
    'paypal_email_hint': 'يرجى تقديم عنوان بريدك الإلكتروني لحساب باي بال الخاص بك',
    'paypal_payout_charges': 'هل ستخصم أي رسوم؟',
    'paypal_payout_charges_ans': 'لا. InsightzClub لا يتقاضى أي رسوم سحب مالي من المستخدم. أنت تتلقى المبلغ المحدد الذي سحبه.',
    'paypal_payout_currency': 'ما هي العملة التي تتلقاها من باي بال؟',
    'paypal_payout_currency_ans': 'دولار أمريكي. هذه هي العملة الوحيدة التي ندعمها في الوقت الحالي.',
    'paypal_countries_availability': 'هل باي بال متاح في بلدي أو منطقتي؟',
    'paypal_countries_availability_ans': "لضمان دعم بلدك أو منطقتك، يرجى معرفة المزيد حول <a href='https://www.paypal.com/webapps/mpp/country-worldwide؟locale.x=en_US' target='_blank'> توفر بلدان / مناطق PayPal </a>",
    'cashout_paypal_not_available_reasons': "السحب النقدي إلى باي بال غير متاح لك في الوقت الحالي. قد يرجع ذلك إلى أي من الأسباب التالية أدناه. <br> <ul> <li> أنت مستخدم جديد </ li> <li> أنت لست مستخدمًا عائدًا </ li> <li> هناك عدد كبير جدًا من المستخدمين يسحبوا الأموال في الوقت الحالي </ li> <li>قد لا يكون السحب متاحًا في بلدك.</li></ul> <br> يرجى إعادة التحقق لاحقًا.",
    'cashout_everteen_not_available_reasons': "هذه الميزة غير متوفرة في بلدك حتى الآن. <br> يرجى التحقق مرة أخرى في وقت لاحق.",
    'confirm_account_details': "تأكيد_تفاصيل_الحساب",
    'confirm_account_details_info': "يرجى تأكيد المعلومات التي أدخلتها. إذا كان هناك خطأ ، فارجع إلى الخطوة السابقة لتصحيح المعلومات.",
    'cancel': "يلغي",
    'receipt_invalid_reasons': 'نأسف لعدم تمكن النظام من التحقق من الإيصال الذي قمت بتحميله. قد يرجع ذلك إلى أي من الأسباب التالية أدناه. <br> <ul> <li> الإيصال ليس صالحًا </ li> <li> الصورة التي تم تحميلها ضبابية </ li> </ul> <br > يرجى الاتصال بدعم العملاء لمزيد من المعلومات',
    'ireview_no_data': 'عذرا لا توجد بيانات متاحة للفئة المختارة',
    'next': 'التالى',
    'survey_submitted': 'شكرا لك لاستكمال الاستبيان. تم حفظ الردود الخاصة بك. يمكنك الان مغادرة الصفحة'
    }