<template>
  <v-navigation-drawer color="#555" app dark fixed clipped
    v-model="$store.state.showSideBar"
  >
    <v-list dense nav class="py-0">
      <v-list-item two-line>
        <v-list-item-avatar>
          <v-avatar size="48" tile>
            <v-btn fab small dark>
              <span class="white--text headline">{{ nameToInitials }}</span>
            </v-btn>
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ $store.state.userProfile.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ $store.state.userProfile.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item v-for="item in items" :key="item.title" link :to="{ name: item.page }">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn block v-bind="attrs" v-on="on">
              <v-icon>mdi-translate</v-icon>
              {{ $t("language") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(l, i) in languages" :key="i" @click="setLanguage(l)">
              <v-list-item-title>{{ l.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>    

      <div class="pa-2">
        <v-btn block @click="logout">{{ $t('logout') }}</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { DataService } from './../services/DataService'

const dataService = new DataService()

export default {
  data () {
    return {
      showLanguage: true,
      languages: [],
      items: [
        { 
          title: this.$t('link_data'), 
          icon: 'mdi-view-dashboard',
          page: 'PassiveData',
        },
        { 
          title: this.$t('user_profile'), 
          icon: 'mdi-view-dashboard',
          page: 'UserProfile',
        },
      ],
      logout: () => window.getApp.$emit('APP_LOGOUT')
    }
  },
  mounted() {
    dataService.getConfig("languageConfig").then(data => {
      this.languages = data
    })
  },
  methods: {
    setLanguage(selected) {
      this._i18n.locale = selected.key
      this.$store.state.language.selected = selected.key
    },
  },
  computed: {
    nameToInitials() {
      let name = this.$store.state.userProfile.name ? this.$store.state.userProfile.name : "";
      return name.split(" ").map(x => x.charAt(0)).join("").substr(0, 2).toUpperCase();
    },
  }
}
</script>