export default {
    'demographic_info': '個人資料',
    'provide_demographic': '請在此處提供您的個人資料。 ',
    'gender': '性別',
    'date_of_birth': '出生日期',
    'marital_status': '婚姻狀況',
    'country': '國家',
    'by_clicking': '通過單擊',
    'continue': '繼續',
    'read_and_agree': '您已經閱讀並同意 InsightzClub ',
    'privacy_policy': '隱私政策',
    'login_information': '登錄信息',
    'provide_login_info': '請在此處提供您的登錄信息。 ',
    'name': '名稱',
    'email': '電子郵件',
    'country_code': '國家/地區代碼',
    'mobile_number': '手機號碼',
    'password': 'Password',
    'confirm_password': '確認密碼',
    'referral_code': '推薦代碼',
    'previous': '上一個',
    'verification': '驗證',
    'provide_otp': '請提供已經發送到您註冊的手機號碼和電子郵件的OTP。',
    'otp': 'OTP',
    'confirmation': '確認',
    'successful_registration': '您已經成功註冊。請單擊頂部的“返回”按鈕。',
    'email_valid': '電子郵件必須有效',
    'confirmation_password': '需要確認密碼。',
    'confirmation_password_mismatch': '確認密碼與密碼不匹配。',
    'six_characters': '六個字符的代碼',
    'male': '男',
    'female': '女',
    'single': '單身',
    'married': '已婚',
    'divorced': '離婚',
    'min': '最低',
    'max': '最多',
    'characters': '個字符',
    'mobile_number_cannot': '手機號碼不能以',
    'otp_not_match': 'OTP不匹配。 ',
    'enter_valid_email': '請輸入一個有效的電子郵件',
    'account_details': '帳戶資料',
    'provide_account_details': '請在此處提供您的帳戶資料。 ',
    'new_password': '新密碼',
    'provide_password': '請提供您的新密碼以及生成的OTP。 ',
    'confirm_new_password': '確認新密碼',
    'successful_password_change': '您已成功更改密碼。請單擊“返回”按鈕以使用新憑據登錄。 ',
    'validate_details_failed': '我們無法驗證您的帳戶資料。 ',
    'full_name': '全名',
    'full_name_bank_account_hint': '請輸入銀行帳戶持有人的確切全名',
    'bank_name': '銀行名稱',
    'account_number': '帳號',
    'update': '更新',
    'frequently_asked_questions': '常見問題(FAQ)',
    'terms_conditions': '條款和條件',
    'min_req_redemption': '兌換要求的最低限額是多少？ ',
    'min_req_redemption_ans': '執行兌換所需的最低積分是',
    'points_currency_ratio': '貨幣兌換率是多少點？ ',
    'points_currency_ratio_ans': '點數 =',
    'reward_expect': '我什麼時候可以期望得到回報？ ',
    'reward_expect_ans': '截止時間是每個月的月底。您的歸檔付款將在每月的下一個15日進行處理。如果每月的15號是周末或公共假日,則獎勵將在下一個工作日處理。 ',
    'contact_payout': '如果有任何疑問,我應如何联系付款團隊？ ',
    'contact_payout_ans': '您可以通過以下電子郵件地址與 InsightzClub 支出團隊聯繫: ask@insightzclub.com。 ',
    'contact_payout_no_country': '我們不支持在您的國家/地區兌現。.您可以通過以下電子郵件地址與 InsightzClub 支出團隊聯繫: ask@insightzclub.com。 ',
    'close': '關閉',
    'bank_account_details': '銀行帳戶資料',
    'before_processing': '在進行處理之前, 請先檢查 InsightzClub 的 Cashout',
    'no_cashout_info': '抱歉, 您註冊的國家/地區沒有提款信息。請稍後再檢查',
    'points_to_redeem_min': '對不起，您至少需要 {0} 兌換的積分。達到最低要求後,您就可以兌換積分。 ',
    'provide_bank_account_details': '請提供您希望接收付款的銀行帳戶資料。 ',
    'choose_cashout_amount': '選擇提款金額',
    'select_amount': '請選擇您要兌現的金額。使用 + 或 - 按鈕增加或減少金額。 ',
    'total_available_points': '您的總可用積分為',
    'points': '點數',
    'equivalent_to': '相等於',
    'back': '返回',
    'confirm_selections': '確認您的選擇',
    'confirm_details': '請確認資料',
    'points_to_redeem': '積分兌換',
    'cashout_amount': '現金餘額',
    'you_have_read': '您已閱讀並同意 InsightzClub 的 Cashout',
    'confirm': '確認',
    'thank_you': '謝謝！',
    'points_confirmation_email': '您已成功兌換 {0} 點數。 確認電子郵件將很快發送出去',
    'min_points_not_met': '未達到最低分數',
    'exceeded_points': '超出可用積分',
    'earn_points_by_watching_videos': '您可以通過觀看視頻來賺取積分，並回答一些與視頻有關的正確問題。',
    'provide_task_id': '提供任務 ID',
    'add_task': '要添加任務，請插入提供的任務 ID。 任務 ID 是字母和數字字符的組合。',
    'successfully_added_task': '您已成功添加任務。如果任務尚未開始，則此任務不可見。',
    'no_video_available': '抱歉，當前沒有可供您觀看的視頻。請稍後檢查。',
    'watch': '觀看',
    'for_video_watched': '對於您剛剛觀看的視頻。',
    'you_have_earned': '您已經獲得了',
    'account_already_activated': '看來您的帳戶已被激活',
    'successful_activated': '您已成功激活您的帳戶。請點擊頂部的“返回”按鈕。 ',
    'trying_to': '正在嘗試',
    'here': '這兒',
    'activate_account': '激活帳戶',
    'forgot_password': '忘記密碼',
    'click': '請點擊',
    'successfully_updated': '成功更新',
    'additional_demographic': '詳細個人資料',
    'no_info': '目前沒有資料或信息可提供。',
    'loading': '正在加載...',
    'topArtists': '聽得最多的藝術家',
    'topTracks': '聽得最多的單曲',
    'take_again': '再來一次',
    'start': '開始',
    'completed': '已完成',
    'required': '必要',
    'have_referral_code': '我有推薦代碼或項目代碼',
    "deactivated_account": "帳戶已被停用。 請與我們聯繫",
    "inactive_account": "帳戶尚未激活。 請激活您的帳戶",
    "user_not_found": "找不到用戶。 請註冊",
    "user_details_mismatch": "您傳遞的信息不匹配。 請驗證！",
    "account_pending_reset_password": "帳戶待處理的重設密碼。請重設密碼",
    "brands_purchased_not_completed": "您尚未完成任務，因此無法檢索您購買的品牌的商品。<br> <br>請立即完成任務。",
    "submit_brands_purchased": "提交購買的品牌",
    "what_is_data_collected" :  "什麼是數據收集?",
    "what_is_data_collected_answer": "被動收集是您的位置數據，技術數據 (如：您的設備，設備名稱，操作系統等) 和設備上安裝的應用程序。 任何其他數據都是您通過填寫調查表格或其他的參加活動所得來的數據。",
    "purpose_of_data": "收集這些數據的目的是什麼?",
    "purpose_of_data_answer": "我們收集這些數據僅為了用於市場研究目的。那些您經常使用的各種品牌通過該研究將會更了解您使用該產品後的體驗和使用模式，並將改進他們的產品和/或服務。我們僅匯報您的數據，這意味著你的數據將和其他參與者的數據將被合併在一起。因此，單個人的結論是不可能發生的或匯報的。",
    "what_are_rewards": "有什麼獎勵?",
    "what_are_rewards_answer": "只要您將這應用程序安裝在您的設備上，您就會收到獎勵 (現金和/或非現金)。您將可以通過參與任務或填調查表格獲得額外獎勵。更多有關獎勵的詳細信息可以到首頁按'獎勵歷史記錄'。",
    "can_see_own_data": "我能看到自己的數據嗎?",
    "can_see_own_data_answer": "是的，從用戶儀表板中，您可以看到所有關於您的數據。",
    "privacy_taken_care": "我的在線隱私如何得到照顧?",
    "privacy_taken_care_answer": "所有收集到的信息都是匿名的。這意味著任何個人信息或識別單個小組成員的方式都將被刪除。這些數據與其他小組成員合併，只匯總報告。電子郵件和電話號碼沒有關聯報告的數據，僅用於初始應用程序安裝和提供獎勵。",
    "data_protection_taken_care": "如何處理數據保護和安全問題?",
    "data_protection_taken_care_answer": "收集的所有數據均符合個人數據保護法 (PDPA)，並符合GDPR。",
    "who_to_contact": "如果我有任何疑問，可以聯繫誰?",
    "who_to_contact_answer": "如果您對該任務有任何疑問，可以發送電子郵件至 <a href='mailto: askus@insightzclub.com'>askus@insightzclub.com</a>或通過<a href='https://wa.me/60320359220'> WhatsApp（+603-2035-9220）</a>與我們聊天。",
    "refer_and_earn": '推薦並赚取點數',
    "refer_and_earn_point_1": "1. 立即共享下引用文本到您的网络（通过WhatsApp，SMS，PM，Facebook等）。",
    "refer_and_earn_point_2": "2. 根据您的推荐，如果您安装了任何网络，则每安装一个人，您将获得50点。",
    "share_and_earn": "分享和赚取點數",
    "share_and_earn_point_1": "1. 将'立即共享'下的文本共享到您的社交媒体或专业网络（Facebook，Instagram，LinkedIn，Twitter）。",
    "share_and_earn_point_2": "2. 将帖子的公开程度设置为公开。",
    "share_and_earn_point_3": "3. 通过在“提交证明”中上传来提供分享的证明，您将获得點數。",
    "share_and_earn_point_4": "4.  您将在每个平台上的每次共享赚取25点",
    "engage_and_earn": "参与并赚取點數",
    "engage_and_earn_point_1": "1. 将'立即共享'下的文本共享到您的社交媒体或专业网络（Facebook，Instagram，LinkedIn，Twitter）。",
    "engage_and_earn_point_2": "2. 将帖子公开。",
    "engage_and_earn_point_3": "3. 您对帖子的参与度（喜欢，分享，评论）越多，您将获得越多的點數。",
    "engage_and_earn_point_4": "4. 您在每个帖子上的参与度将获得1点，而在每个平台上的分享则将获得25点。",
    "engage_and_earn_point_5": "5. 通过上传'提交证明来提供分享的证明，并获得點數。",
    "user_profile": "用戶個人資料",
    "logout": "退出",
    "task_id": "Task ID",
    "min_characters": "至少 {1} 字符",
    "max_characters": "最多 {1} 字符",
    "available_points": "可用積分",
    "trees_planted": "你種的樹",
    "plant_tree": "種樹",
    "cashout_to_bank": "兌現到銀行",
    "evertreen_intro": "您可以通過我們的合作夥伴Evertreen兌換積分以種植樹木。",
    "select_a_tree": "選擇一棵樹",
    "read_more": "閱讀更多",
    "plant_this": "種植這個",
    "language": "多語言",
    "login": "登录",
    "login_with_qr": "使用QR登錄",
    "login_with_otp": "使用OTP登錄",
    "scan_to_login_insightzclub": "掃描以登錄到InsightzClub",
    "scan_to_login_insightzclub_description": "請從您的InsightzClub移動應用中掃描QR碼。",
    "unable_to_scan_qr": "無法掃描QR碼？",
    "upload_file": "上传文件",
    "uploaded_and_task_completed": "您已成功上傳並完成任務。",
    "file_exceeded_limit": "上載的文件超過了最大文件大小{0} MB。您的文件大小為{1} MB。",
    "browser_history": "瀏覽歷史記錄",
    "url": "网址",
    "visited_at": "訪問",
    "actions": "行動",
    "title": "標題",
    "delete": "刪除",
    "whatsApp": "WhatsApp",
    "top_5_most_recent_ads_and_show_watched": "最近觀看的5個廣告和節目",
    "top_5_most_recent_ads_seen": "最近觀看的5個廣告",
    "top_5_most_recent_ads_interacted": "最近有互動的5個廣告",
    "top_5_most_recent_page_visits": "最近訪問的5個頁面",
    "password_policy": "密碼策略",
    "password_policy_description": "您的密碼必須大於或等於8個字符。",
    "current_password": "當前密碼",
    "new_password_not_match": "新密碼和確認密碼字段不匹配",
    "save_successfully": "已成功保存",
    "code_invalid": "代碼無效",
    "before_we_begin": "開始之前",
    "before_we_begin_description": "如果我違反以下任何規則，我的账户可能會被 InsightzClub 永久封禁：<br><ul><li>創建多個 InsightzClub 帳戶</li><li>提交不屬於我的數據</li><li>提交虛假數據</li><li>與他人共享帳戶</li></ul>",
    "rules_mentioned": "以及上面提到的規則",
    "cashout_bank_not_available_reasons": "您目前無法向銀行提現。可能是由於以下任何原因。<br><ul><li>您是新用戶</li><li>您不是回訪用戶</li><li>目前提現的用戶太多</li></ul><br>請稍後再回來查看。",
    "available_on_mobile_app_only": "僅適用於 InsightzClub 移動應用程序",
    "app_only": "僅限應用程序",
    "view_data": "查看數據",
    "link_data": "鏈接數據",
    "timestamp": "時間戳",
    "cashout_to_paypal": "提現到貝寶",
    "paypal_account_details": "貝寶詳情",
    "provide_paypal_account_details": "請提供您希望收到付款的 PayPal 帳戶詳細信息。",
    "full_name_paypal_hint": "請在您的PayPal賬戶中提供您的姓名",
    "paypal_email": "貝寶電子郵件",
    "paypal_email_hint": "請提供您的PayPal賬戶的電子郵件地址",
    "paypal_payout_charges": "是否有任何收費？",
    "paypal_payout_charges_ans": "不。InsightzClub 不會向用戶收取任何提現費用。您收到的金額是您提現的金額。",
    "paypal_payout_currency": "我從 PayPal 收到的貨幣是什麼？",
    "paypal_payout_currency_ans": "USD（美元）。這是我們目前唯一支持的貨幣。",
    "paypal_countries_availability": "PayPal 在我的國家或地區可用嗎？",
    "paypal_countries_availability_ans": "為了確保您所在的國家或地區得到支持，請了解更多關於<a href='https://www.paypal.com/webapps/mpp/country-worldwide?locale.x=en_US' target='_blank'>PayPal 國家/地區可用性</a>",
    "cashout_paypal_not_available_reasons": "您目前無法使用PayPal提現。這可能是由於以下原因造成的。<br><ul><li>您是新的</li><li>您不是回訪用戶</li ><li>用戶太多了，還沒有兌現</li><li>您所在的國家/地區可能不支持提現。</li></ul><br>請稍後再回來查看。",
    'cashout_everteen_not_available_reasons': "您所在的國家/地區尚不提供此功能。 <br>請稍後再回來查看。",
    "confirm_account_details": "確認賬戶詳情",
    "confirm_account_details_info": "請確認您輸入的信息。如果有錯誤，請返回上一步以更正信息。",
    "cancel": "取消",
    'receipt_invalid_reasons': "抱歉，系統無法驗證您上傳的收據。可能是以下任一原因造成的。<br><ul><li>收據無效</li><li>上傳的圖片模糊</li></ul><br >請聯繫我們的客戶支持以獲取更多信息。",
    'ireview_no_data': '抱歉，沒有可用於所選類別的數據',
    'next': '下一步',
    'survey_submitted': '感謝您完成本次調查！您的回复已保存。您現在可以離開頁面'
}