import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  // theme: {
  //   themes: {
  //     light: {
  //       primary: "#2ca8a0"
  //     },
  //     dark: {
  //       primary: "#4ecac2"
  //     }
  //   }
  // }
});