import VueI18n from 'vue-i18n'
import Vue from 'vue'
import en from './en.js'
import ms_my from './ms_my'
import zh_cn from './zh_cn.js'
import zh_tw from './zh_tw.js'
import bn from './bn.js'
import th from './th.js'
import vi from './vi.js'
import ar from './ar'
import ko from './ko'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        'en': en,
        'ms-MY': ms_my,
        'zh-CN': zh_cn,
        'zh-TW': zh_tw,
        'bn': bn,
        'th': th,
        'vi': vi,
        'ar': ar,
        'ko': ko
    }
})

export default i18n