import store from "./app.store.js";

const mixin = {
  methods: {
    $setLoader() {
      store.state.loader = true;
    },
    $clearLoader() {
      store.state.loader = false;
    },
    $renderError(error) {
      store.state.loader = false;

      if (error.response && error.response.data && error.response.data.message) {
        store.state.snackBarText = error.response.data.message
        store.state.showSnackBar = true
      } else if (error.response && error.response.message) {
        store.state.snackBarText = error.response.message
        store.state.showSnackBar = true
      }
    },
    $showSuccessMessage(data) {
      if (data && data.message) {
        store.state.loader = false
        store.state.dialogMessageText = data.message
        store.state.showDialogMessage = true
      }
    }
  }
}

export default mixin 